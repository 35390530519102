import {
  FC,
  FocusEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useId,
  useRef,
  useState,
} from "react"
import { Popover } from "@headlessui/react"
import classNames from "classnames"

import { CheckIcon, FilterIcon, SearchIcon } from "./Icons"

interface Ids {
  baseId: string
  buttonId: string
  inputId: string
  labelId: string
  optionsId: string
}

const useIds = (): Ids => {
  const baseId = useId()

  return {
    baseId,
    buttonId: `${baseId}-button`,
    inputId: `${baseId}-input`,
    labelId: `${baseId}-label`,
    optionsId: `${baseId}-options`,
  }
}

const mkOptionId = (baseId: string, optionId: string | number): string =>
  `${baseId}-option-${optionId}`

interface InputBarProps {
  activeOptionId: ID | null
  disabled: boolean
  filters?: (f: () => void) => ReactNode
  ids: Ids
  inputRef: RefObject<HTMLInputElement>
  onBlur: (e: FocusEvent<HTMLElement, Element>) => void
  onInteract: () => void
  onKeyDown: (k: string) => void
  onSearch: (query: string) => void
  open: boolean
  placeholder: string
  searchText: string
}

const InputBar: FC<InputBarProps> = ({
  activeOptionId,
  disabled,
  filters,
  ids,
  inputRef,
  onBlur,
  onInteract,
  onKeyDown,
  onSearch,
  open,
  placeholder,
  searchText,
}) => {
  const { baseId, buttonId, labelId, inputId, optionsId } = ids

  return (
    <div className="otto-focus flex h-10 w-full items-center justify-between gap-4 rounded-[2rem] bg-otto-mid px-4 forced-colors:border forced-colors:border-transparent">
      <button
        aria-controls={open ? optionsId : undefined}
        aria-expanded={open}
        aria-haspopup="listbox"
        aria-labelledby={`${labelId} ${buttonId}`}
        className="flex items-center rounded-r-md focus:outline-none"
        disabled={disabled}
        id={buttonId}
        onClick={onInteract}
        tabIndex={-1}
        type="button"
      >
        <SearchIcon className="w-5 text-otto-pop" aria-hidden />
      </button>

      <input
        aria-activedescendant={
          open && activeOptionId
            ? mkOptionId(baseId, activeOptionId)
            : undefined
        }
        aria-controls={open ? optionsId : undefined}
        aria-expanded={open}
        aria-haspopup="listbox"
        aria-labelledby={labelId}
        className="w-full bg-otto-mid text-base text-otto-pop placeholder:text-otto-pop-800 focus:outline-none"
        disabled={disabled}
        id={inputId}
        onBlur={onBlur}
        onChange={e => onSearch(e.target.value)}
        onFocus={onInteract}
        onKeyDown={e => onKeyDown(e.code)}
        placeholder={placeholder}
        ref={inputRef}
        role="combobox"
        spellCheck={false}
        type="text"
        value={searchText}
      />

      {filters && (
        <Popover className="flex items-center">
          <Popover.Button
            className="otto-focus h-5 rounded-sm text-otto-pop"
            disabled={disabled}
            title="Search filters"
          >
            <span className="sr-only">Search filters</span>
            <FilterIcon className="w-5" />
          </Popover.Button>

          <Popover.Panel className="otto-focus absolute top-full left-0 z-20 mt-2 max-h-[30rem] w-full overflow-auto rounded-md border border-otto-grey-400 bg-white text-base shadow-xl">
            {filters(onInteract)}
          </Popover.Panel>
        </Popover>
      )}
    </div>
  )
}

type ID = string | number

export interface SearchBarProps<T> {
  beforeResults?: ReactNode
  disabled?: boolean
  filters?: (f: () => void) => ReactNode
  getOptionId: (t: T) => ID
  label: string
  onSearch: (query: string) => void
  onSelect: (id: ID | null, e?: MouseEvent) => void
  options: T[]
  placeholder: string
  renderOption: (opt: T) => ReactNode
  searchText?: string
  selectedValue: T | null
  visuallyHideLabel?: boolean
  setOpen: (open: boolean) => void
  open: boolean
  setHasConfirmedQuoteDetailsModal?: (
    hasConfirmedQuoteDetailsModal: boolean,
  ) => void
  hasConfirmedQuoteDetailsModal?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const SearchBar = <T extends unknown>({
  beforeResults,
  disabled = false,
  filters,
  getOptionId,
  label,
  onSearch,
  onSelect,
  options,
  placeholder,
  renderOption,
  searchText = "",
  selectedValue,
  visuallyHideLabel = false,
  setOpen,
  open,
  setHasConfirmedQuoteDetailsModal,
  hasConfirmedQuoteDetailsModal,
}: SearchBarProps<T>): ReactElement => {
  const ids = useIds()
  const { baseId, optionsId, labelId } = ids

  const selectedOptionId: ID | null =
    selectedValue === null ? null : getOptionId(selectedValue)

  const firstOptionId: ID | null =
    options.length > 0 ? getOptionId(options[0]) : null

  const [activeOptionId, setActiveOptionId] = useState<ID | null>(
    selectedOptionId || firstOptionId,
  )
  // Reset the activeOptionId whenever the selectedOptionId changes or the
  // options dropdown opens/closes
  useEffect(() => {
    setActiveOptionId(selectedOptionId || firstOptionId)
  }, [selectedOptionId, firstOptionId, open])

  const activeOptionIdx = Math.max(
    options.findIndex(opt => getOptionId(opt) === activeOptionId),
    0,
  )

  const inputRef = useRef<HTMLInputElement>(null)
  const optionsRef = useRef<HTMLUListElement>(null)

  return (
    <div>
      <label
        className={classNames(
          "mb-2 block text-base font-bold text-otto-grey-800",
          { "sr-only": visuallyHideLabel },
        )}
        id={labelId}
      >
        {label}
      </label>

      <div className="relative">
        <InputBar
          activeOptionId={activeOptionId}
          disabled={disabled}
          filters={filters}
          ids={ids}
          inputRef={inputRef}
          onBlur={e => {
            const userClickedOnOptions =
              optionsRef.current && optionsRef.current.contains(e.relatedTarget)

            // Ensure this doesn't clash with selecting an item
            if (e?.relatedTarget?.id == "search-result-view-details-button") {
              inputRef?.current?.focus()
            }

            if (
              !userClickedOnOptions &&
              !(e?.relatedTarget?.textContent === "Cancel")
            ) {
              setOpen(false)
            } else if (!userClickedOnOptions) {
              setOpen(true)
            }
          }}
          onInteract={() => {
            if (
              hasConfirmedQuoteDetailsModal &&
              setHasConfirmedQuoteDetailsModal
            ) {
              setOpen(false)
              setHasConfirmedQuoteDetailsModal(false)
              inputRef?.current?.blur()
            } else {
              setOpen(true)
            }

            if (inputRef.current) {
              inputRef.current.focus()
            }
          }}
          onKeyDown={key => {
            const firstId = options[0] ? getOptionId(options[0]) : null

            const prevIdx = Math.max(activeOptionIdx - 1, 0)
            const prevId = options[prevIdx]
              ? getOptionId(options[prevIdx])
              : null

            const nextIdx = Math.min(activeOptionIdx + 1, options.length - 1)
            const nextId = options[nextIdx]
              ? getOptionId(options[nextIdx])
              : null

            const lastId = options[options.length - 1]
              ? getOptionId(options[options.length - 1])
              : null

            switch (key) {
              case "ArrowDown":
                if (!open) {
                  setOpen(true)
                } else {
                  setActiveOptionId(nextId)
                }
                break
              case "ArrowUp":
                if (!open) {
                  setOpen(true)
                } else {
                  setActiveOptionId(prevId)
                }
                break
              case "Enter":
                onSelect(activeOptionId)
                setOpen(false)
                break
              case "Escape":
                setOpen(false)
                break
              case "End":
              case "PageDown":
                if (open) {
                  setActiveOptionId(lastId)
                }
                break
              case "Home":
              case "PageUp":
                if (open) {
                  setActiveOptionId(firstId)
                }
                break
              default:
                break
            }
          }}
          onSearch={s => {
            onSearch(s)
            setOpen(true)
          }}
          open={open}
          placeholder={placeholder}
          searchText={searchText}
        />

        {open && (
          <div className="absolute z-20 mt-2 max-h-[30rem] w-full overflow-auto rounded-md border border-otto-grey-400 bg-white text-base shadow-xl">
            {beforeResults}

            <ul
              aria-activedescendant={
                activeOptionId ? mkOptionId(baseId, activeOptionId) : undefined
              }
              aria-labelledby={labelId}
              aria-orientation="vertical"
              className="otto-focus-inset rounded-md"
              id={optionsId}
              ref={optionsRef}
              role="listbox"
              tabIndex={-1}
            >
              {options.map(opt => {
                const optionId = getOptionId(opt)

                return (
                  /* We can ignore these linting rules because there are keyboard
                   * listeners elsewhere in the form of the `onKeyDown` handler
                   * and `aria-activeOptionId` management.
                   *
                   * We don't need an accompanying `onFocus` for the `onMouseOver`
                   * because the elements aren't focusable and the user can use the
                   * arrow keys to access them; and we don't need an accompanying
                   * key event for the `onClick` because the user can select an item
                   * with the enter key.
                   */
                  /* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/click-events-have-key-events */
                  <li
                    aria-selected={optionId === selectedOptionId}
                    data-testid={optionId}
                    id={mkOptionId(baseId, optionId)}
                    key={optionId}
                    role="option"
                    tabIndex={-1}
                    onClick={event => {
                      event.stopPropagation()
                      onSelect(optionId, event)
                      setOpen(false)
                    }}
                    onMouseOver={() => setActiveOptionId(optionId)}
                  >
                    <div
                      className={classNames(
                        "relative cursor-pointer select-none py-2 pl-3 pr-9 text-base text-otto-grey-900",
                        {
                          "bg-otto-pop-100 forced-colors:bg-SystemHighlight":
                            optionId === activeOptionId,
                        },
                      )}
                    >
                      {renderOption(opt)}

                      {optionId === selectedOptionId && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                          <div className="h-5 w-5">
                            <CheckIcon />
                          </div>
                        </span>
                      )}
                    </div>
                  </li>
                )
              })}

              <div role="alert">
                {options.length === 0 && (
                  <p className="py-2 px-3 text-otto-grey-700">
                    No results found
                  </p>
                )}
              </div>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchBar
