import { useEffect, useMemo, useState } from "react"
import HeaderSection from "./HeaderSection"
import SectionFooter from "./SectionFooter"
import { REVIEW_CONTRACT_GENERAL_PATH } from ".."
import { useNavigate, useParams } from "react-router-dom"
import {
  ContractResponse,
  completeContract,
  updateContractSections,
} from "@appia/api"
import LayerDisclosure from "./LayerDisclosure"
import * as Sentry from "@sentry/react"

import useApiClient from "src/contexts/ApiClientContext"
import {
  useGetContract,
  useGetCurrentUser,
  useGetKiQuote,
  useGetPBQADocuments,
} from "src/swr"
import * as RD from "@appia/remote-data"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"
import { Toast } from "@appia/ui-components"
import { useToastHandler } from "src/hooks/useToastHandler"
import ToastViewport from "src/components/ToastViewport"
import { DocumentContent } from "src/screens/ReviewEndorsement/Documents/TabLayout"
import { calculateTotalAllocatedPremium } from "./premiumUtils"
import ContractSectionLayer from "./ContractSectionLayer"
import { LayerFormField, SectionFormField } from "./utils/types"
import { isLayerValid, validateSectionFormField } from "./utils/validationUtils"
import {
  initializeLayerFormFields,
  updateContractDataWithFormFields,
} from "./utils/formUtils"
import RarcDefaultWarningBanner from "../RarcDefaultWarningBanner"

const COMPARISON_TOLERANCE = 0.1

const SectionScreen = (): JSX.Element => {
  const navigate = useNavigate()
  const { contractId, version } = useParams<{
    contractId: string
    version: string
  }>()
  const apiClient = useApiClient()
  const { request: userRequest } = useGetCurrentUser()

  const user = RD.isSuccess(userRequest) ? userRequest.data.user : undefined

  if (!user) {
    throw new Error("Could not get user details")
  }

  if (!contractId) {
    throw new Error("Contract ID is not provided in the URL")
  }

  if (!version) {
    throw new Error("Version ID is not provided in the URL")
  }

  const { toastType, toastMessage, toastState, setAndTriggerToast } =
    useToastHandler()

  const { request: contractDataReq } = useGetContract(contractId)
  const isValidContract = RD.isSuccess(contractDataReq)
  const [contractData, setContractData] = useState<ContractResponse | null>(
    null,
  )

  const pbqaId = useMemo(
    () => contractData?.sections[0]?.pbqaId || null,
    [contractData],
  )

  const { request: kiQuoteRequest } = useGetKiQuote(pbqaId)
  const kiQuote = RD.isSuccess(kiQuoteRequest) ? kiQuoteRequest.data : null
  const [openLayerId, setOpenLayerId] = useState<number | null>(null)

  const { request: documentReq } = useGetPBQADocuments(pbqaId)

  useEffect(() => {
    if (
      RD.isSuccess(contractDataReq) &&
      contractDataReq.data.sections.length > 0
    ) {
      setOpenLayerId(contractDataReq.data.sections[0].sectionId)
      setContractData(contractDataReq.data)
    } else {
      setOpenLayerId(null)
    }
  }, [contractDataReq])

  const initialLayerFormFields = useMemo(() => {
    if (!RD.isSuccess(contractDataReq)) return {}

    return initializeLayerFormFields(contractDataReq.data.sections)
  }, [contractDataReq])

  const processLayerFormFields = (): { hasErrors: boolean } => {
    const newLayerFormFields: Record<string, LayerFormField> = {}
    let hasErrors = false

    for (const [layerId, layerFormField] of Object.entries(layerFormFields)) {
      const {
        total: totalAllocatedPremium,
        totalArray: totalAllocatedPremiumArray,
      } = calculateTotalAllocatedPremium(layerFormField.sections)

      const sectionCount = Object.keys(layerFormField.sections).length
      const newSectionsFormFields: Record<string, SectionFormField> = {}
      const sectionLayerWrittenPremium =
        layerFormField.sections[0].layerWrittenPremium ?? 0

      const isTotalAllocatedPremiumValid =
        Math.abs(totalAllocatedPremium - sectionLayerWrittenPremium) <
        COMPARISON_TOLERANCE
      const sectionCountCheck =
        sectionCount === totalAllocatedPremiumArray.length

      for (const [sectionId, sectionFormField] of Object.entries(
        layerFormField.sections,
      )) {
        newSectionsFormFields[sectionId] = validateSectionFormField(
          sectionFormField,
          totalAllocatedPremium,
          sectionCountCheck,
          isTotalAllocatedPremiumValid,
        )
      }

      newLayerFormFields[layerId] = {
        ...layerFormField,
        minorClass: {
          ...layerFormField.minorClass,
          showError: !layerFormField.minorClass.validated.valid,
        },
        subClass: {
          ...layerFormField.subClass,
          showError: !layerFormField.subClass.validated.valid,
        },
        sections: newSectionsFormFields,
        sumTotalAllocatedPremiumCheck: totalAllocatedPremium,
      }
    }

    const newLayerIdsWithError = new Set(layerIdsWithError)
    for (const layer of Object.values(newLayerFormFields)) {
      if (isLayerValid(layer)) {
        newLayerIdsWithError.delete(layer.layerId)
      } else {
        newLayerIdsWithError.add(layer.layerId)
        hasErrors = true
      }
    }
    setLayerIdsWithError(newLayerIdsWithError)
    setLayerFormFields({ ...newLayerFormFields })

    return { hasErrors }
  }

  const [layerFormFields, setLayerFormFields] = useState<
    Record<string, LayerFormField>
  >(initialLayerFormFields)

  useEffect(() => {
    setLayerFormFields(initialLayerFormFields)
  }, [initialLayerFormFields])

  const [layerIdsWithError, setLayerIdsWithError] = useState<Set<number>>(
    new Set(),
  )

  const onSave = (): void => {
    const { hasErrors } = processLayerFormFields()
    if (!hasErrors) {
      updateSections()
    }
  }

  const onBack = (): void => {
    navigate(`../${REVIEW_CONTRACT_GENERAL_PATH}`)
  }

  const onSubmit = async (): Promise<void> => {
    const { hasErrors } = processLayerFormFields()
    if (!hasErrors) {
      try {
        await updateSections()

        const contractData = await finalizeContract()

        navigate(`/contract/view/${contractId}/`, {
          state: { fromSubmit: true, contractData },
        })
      } catch (error) {
        if (error instanceof Error) {
          Sentry.captureException(error)
        }
      }
    } else {
      setAndTriggerToast(
        "error",
        "Please correct the errors in the form before submitting.",
      )
    }
  }

  const finalizeContract = async (): Promise<ContractResponse> => {
    try {
      const versionNumber = parseInt(version, 10)
      const response = await completeContract(
        apiClient,
        contractId,
        versionNumber,
        user.id,
      )
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        Sentry.captureException(error)
        throw error
      } else {
        throw new Error("An error occurred while finalizing contract")
      }
    }
  }

  const updateSections = async (): Promise<void> => {
    if (contractData == null) {
      throw new Error("Cannot update contract sections without contract data.")
    }

    const updatedRequest = updateContractDataWithFormFields(
      contractData,
      layerFormFields,
    )
    try {
      const versionNumber = parseInt(version, 10)
      await updateContractSections(
        apiClient,
        updatedRequest,
        contractId,
        versionNumber,
      )
      setAndTriggerToast("success", "Contract sections updated successfully")
    } catch (error) {
      setAndTriggerToast("error", "Failed to save contract sections")
      if (error instanceof Error) {
        Sentry.captureException(error)
      }
    }
  }

  const openLayer =
    (openLayerId !== null && layerFormFields[openLayerId]) || null

  return RD.match(
    contractDataReq,
    <Loading />,
    <Loading />,
    (contractData: ContractResponse) => {
      const header = {
        insured: contractData.header.insured,
        umr: contractData.contract.uniqueMarketReference,
        programmeRef:
          contractData.sections[0].lines[0].syndicateData.syndicateData
            .britProgrammeReference || "N/A",
        aggWrittenPremium: contractData.header.aggregateWrittenPremium,
      }
      const layers = contractData.sections
      const contractSection = layers.find(
        section => section.sectionId === openLayerId,
      )

      if (!contractSection) {
        return <div>No contract section available.</div>
      }

      return (
        <>
          <RarcDefaultWarningBanner
            createdAt={kiQuote?.createdAt}
            journeyConfigId={
              isValidContract
                ? contractDataReq.data.sections[0].journeyConfigId
                : null
            }
          />
          <div className="flex h-full w-full">
            <div className="w-7/12 pl-6">
              <div className="flex flex-col items-start justify-center gap-6 p-6">
                <HeaderSection
                  insured={header.insured}
                  umr={header.umr}
                  programmeRef={header.programmeRef}
                />
                <div className="grid w-full grid-cols-12 gap-6">
                  <div className="col-span-5 flex flex-col gap-6">
                    {layers.map(layer => (
                      <LayerDisclosure
                        key={layer.sectionId}
                        layer={layer}
                        showError={layerIdsWithError.has(layer.sectionId)}
                        open={openLayerId === layer.sectionId}
                        onClick={() => setOpenLayerId(layer.sectionId)}
                      />
                    ))}
                  </div>

                  {openLayer && (
                    <div className="col-span-7 flex flex-col gap-y-6">
                      <ContractSectionLayer
                        openLayer={openLayer}
                        setLayerFormFields={setLayerFormFields}
                      />
                      <div className="col-span-7">
                        <SectionFooter
                          onSave={onSave}
                          onBack={onBack}
                          onSubmit={onSubmit}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex h-full w-5/12 flex-grow pt-6 pr-12">
              {RD.match(
                documentReq,
                <Loading />,
                <Loading />,
                documents => (
                  <>
                    {documents.length > 0 && (
                      <DocumentContent document={documents[0]} />
                    )}
                  </>
                ),
                error => (
                  <ErrorMessage
                    message="Failed to load Documents"
                    error={error}
                  />
                ),
              )}
            </div>
          </div>
          <Toast.Toast
            type={toastType}
            message={toastMessage}
            open={toastState.open}
            onOpenChange={toastState.onOpenChange}
          />

          <ToastViewport />
        </>
      )
    },
    contractError => (
      <ErrorMessage message="Failed to load Contract" error={contractError} />
    ),
  )
}

export default SectionScreen
