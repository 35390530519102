import { LayerFormField, SectionFormField } from "./types"
import {
  parseSectionPercentage,
  parseSectionValueAndCurrency,
} from "../parsers"

export const isLayerValid = (layer: LayerFormField): boolean => {
  return (
    layer.minorClass.validated.valid &&
    layer.subClass.validated.valid &&
    Object.values(layer.sections).every(
      section =>
        section.policyRef.validated.valid &&
        section.eea.validated.valid &&
        section.premiumAllocationValueAndCurrency.validated.valid &&
        section.premiumAllocationPercent.validated.valid,
    )
  )
}

export const validateSectionFormField = (
  sectionFormField: SectionFormField,
  totalAllocatedPremium: number,
  sectionCountCheck: boolean,
  isTotalAllocatedPremiumValid: boolean,
): SectionFormField => {
  const premiumAllocationValueAndCurrency = {
    ...sectionFormField.premiumAllocationValueAndCurrency,
    validated: parseSectionValueAndCurrency(
      sectionFormField.premiumAllocationValueAndCurrency.raw,
      totalAllocatedPremium,
      sectionFormField.layerWrittenPremium ?? 0,
    ),
    showError:
      !sectionFormField.premiumAllocationValueAndCurrency.validated.valid ||
      (sectionCountCheck && !isTotalAllocatedPremiumValid),
  }

  const premiumAllocationPercent = {
    ...sectionFormField.premiumAllocationPercent,
    validated: parseSectionPercentage(
      sectionFormField.premiumAllocationPercent.raw,
      totalAllocatedPremium,
      sectionFormField.layerWrittenPremium ?? 0,
    ),
    showError:
      !sectionFormField.premiumAllocationPercent.validated.valid ||
      (sectionCountCheck && !isTotalAllocatedPremiumValid),
  }

  return {
    ...sectionFormField,
    policyRef: {
      ...sectionFormField.policyRef,
      showError: !sectionFormField.policyRef.validated.valid,
    },
    eea: {
      ...sectionFormField.eea,
      showError: !sectionFormField.eea.validated.valid,
    },
    premiumAllocationValueAndCurrency,
    premiumAllocationPercent,
  }
}
