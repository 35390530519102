import { FC, useId, useState } from "react"

import {
  Document,
  PolicyCreationUploadFileLimits,
  confirmUpload,
  failUpload,
  generatePolicyCreationUploadUrl,
} from "@appia/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import useUppy from "@appia/file-upload"
import { SuccessResponse } from "@uppy/core"
import { DragDrop } from "@uppy/react"

import useApiClient from "src/contexts/ApiClientContext"

import { ErrorMessage, FileUploadArea } from "@appia/ui-components"
import Loading from "src/components/Loading"

import type { UploadedDocument } from "./types"

const FILETYPE_MSG = "Otto accepts: PDF"

const AttachmentUpload: FC<{
  uploadLimits: PolicyCreationUploadFileLimits
  onUploadFile: (doc: UploadedDocument) => void
}> = ({ uploadLimits, onUploadFile }) => {
  const apiClient = useApiClient()

  const [uploadRequest, setUploadRequest] = useState<
    RD.RemoteData<Error, SuccessResponse>
  >(RD.NotAsked)

  const uppy = useUppy({
    maxMegabytes: uploadLimits.sizeLimitMb,
    acceptedDocumentTypes: uploadLimits.allowedMimetype,
    getUploadParameters: async (file, uppy) => {
      if (typeof file.type === "undefined") {
        throw new Error(`Unrecognised document type. ${FILETYPE_MSG}`)
      }

      const { status, data } = await generatePolicyCreationUploadUrl(
        apiClient,
        {
          fileName: file.name,
          mimetype: file.type,
          type: "final_slip",
        },
      )

      if (status !== 200) {
        throw new Error(`We were unable to accept your upload. ${FILETYPE_MSG}`)
      }

      const { id: documentId, url, fields } = data

      uppy.setFileMeta(file.id, {
        documentId,
      })

      return { url, fields }
    },
    onStartLoad: () => setUploadRequest(RD.Loading),
    onError: async uppyError => {
      Sentry.captureException(uppyError.error)

      switch (uppyError.type) {
        case "upload-error": {
          if (uppyError.file) {
            const docId = uppyError.file.meta["documentId"] as Document["id"]
            await failUpload(apiClient, docId)
            setUploadRequest(RD.Failure(new Error("Upload failed")))
          } else {
            setUploadRequest(RD.Failure(uppyError.error))
          }

          break
        }
        default: {
          setUploadRequest(RD.Failure(uppyError.error))
        }
      }
    },
    onSuccess: async (res, file) => {
      const docId = file?.meta["documentId"] as Document["id"]
      if (file && docId) {
        setUploadRequest(RD.Success(res))
        onUploadFile({
          id: docId,
          name: file.name,
        })
      }

      await confirmUpload(apiClient, docId)
    },
  })

  const loadingId = useId()
  const errorId = useId()

  return (
    <div className="relative">
      <FileUploadArea
        label={
          <div className="grid gap-2">
            <p className="text-xl font-bold">
              Drag and drop or choose files to upload
            </p>
            <p className="text-base">{FILETYPE_MSG}</p>
          </div>
        }
        input={
          <DragDrop
            uppy={uppy}
            className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-0"
            aria-describedby={
              RD.isLoading(uploadRequest)
                ? loadingId
                : RD.isFailure(uploadRequest)
                ? errorId
                : undefined
            }
          />
        }
      />

      {RD.isLoading(uploadRequest) && (
        <div className="absolute inset-0 flex items-center bg-white opacity-70">
          <Loading id={loadingId} />
        </div>
      )}

      {RD.isFailure(uploadRequest) && (
        <ErrorMessage
          id={errorId}
          className="mt-1"
          message={`Failed to upload. Please keep files under ${uploadLimits.sizeLimitMb}MB`}
        />
      )}
    </div>
  )
}

export default AttachmentUpload
