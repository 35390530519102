import { FC, ReactNode, useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"

import {
  ArchiveIcon,
  Card,
  ClipboardListIcon,
  Link,
  Tabs,
  Toast,
} from "@appia/ui-components"
import ToastViewport from "src/components/ToastViewport"

import ScreenTemplate from "src/templates/ScreenTemplate"
import * as ActiveTable from "./ActiveTable"
import * as ArchiveTable from "./ArchiveTable"

import * as RD from "@appia/remote-data"
import { useGetCurrentUser } from "src/swr"

import { PageNameContext } from "src/contexts/PageNameContext"

import { logButtonClick, useLogPageView } from "src/amplitude"
import useDocumentTitle from "src/hooks/useDocumentTitle"
import useLocalStorageState from "src/hooks/useLocalStorageState"
import { useTableFilters } from "src/components/DashboardTable"

const PAGE_NAME = "Endorsements"

type TabValue = "activeEndorsements" | "archiveEndorsements"

const activeEndorsementsValue: TabValue = "activeEndorsements"
const archiveEndorsementsValue: TabValue = "archiveEndorsements"

const tabsConfig: {
  value: TabValue
  text: string
  icon: typeof ArchiveIcon
}[] = [
  { value: activeEndorsementsValue, text: "Active", icon: ClipboardListIcon },
  { value: archiveEndorsementsValue, text: "Archive", icon: ArchiveIcon },
]

interface LocationState {
  toast?: { type: Toast.ToastType; message: string }
}

const EndorsementsScreen: FC = () => {
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const locationState = useLocation().state as LocationState | null

  const { request: userRequest } = useGetCurrentUser()
  const user = RD.isSuccess(userRequest) ? userRequest.data.user : undefined

  const [activeTableSettings, setActiveTableSettings] =
    useLocalStorageState<ActiveTable.TableSettings>(
      ActiveTable.localStorageSettingsKey,
      ActiveTable.defaultTableSettings,
    )

  const [activeTableFilters, setActiveTableFilters] =
    useTableFilters<ActiveTable.TableFilters>(
      ActiveTable.localStorageFiltersKey,
      ActiveTable.defaultTableFilters,
    )

  const [archiveTableSettings, setArchiveTableSettings] =
    useLocalStorageState<ArchiveTable.TableSettings>(
      ArchiveTable.localStorageSettingsKey,
      ArchiveTable.defaultTableSettings,
    )

  const [archiveTableFilters, setArchiveTableFilters] =
    useTableFilters<ArchiveTable.TableFilters>(
      ArchiveTable.localStorageFiltersKey,
      ArchiveTable.defaultTableFilters,
    )

  const [activeTab, setActiveTab] = useState<TabValue>("activeEndorsements")

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<ReactNode>(null)
  const toastState = Toast.useToastState()

  const setAndTriggerToast = (
    toastType: Toast.ToastType,
    toastMessage: ReactNode,
  ): void => {
    setToastType(toastType)
    setToastMessage(toastMessage)
    toastState.triggerToast()
  }

  // Other pages may redirect to this one with instructions to display a toast
  useEffect(() => {
    if (locationState?.toast) {
      setAndTriggerToast(locationState.toast.type, locationState.toast.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!user) {
    return <Navigate to="/" />
  }

  return (
    <ScreenTemplate layout={{ type: "regular" }} pageTitle={PAGE_NAME}>
      <PageNameContext.Provider value={PAGE_NAME}>
        <div className="flex min-h-full flex-col pb-4">
          {user.teamId ? (
            <div className="flex grow flex-col">
              {activeTab === "activeEndorsements" ? (
                <ActiveTable.TableControls
                  tableFilters={activeTableFilters}
                  setTableFilters={setActiveTableFilters}
                  setTableSettings={setActiveTableSettings}
                />
              ) : (
                <ArchiveTable.TableControls
                  tableFilters={archiveTableFilters}
                  setTableFilters={setArchiveTableFilters}
                  setTableSettings={setArchiveTableSettings}
                />
              )}

              <Tabs.Root
                asChild
                value={activeTab}
                onValueChange={s => {
                  if (
                    s === "activeEndorsements" ||
                    s === "archiveEndorsements"
                  ) {
                    setActiveTab(s)
                  }
                }}
              >
                <>
                  <div className="overflow-hidden rounded-lg rounded-b-none border border-b-0 border-otto-grey-300 bg-white">
                    <Tabs.List aria-label="Tables" className="max-w-min">
                      {tabsConfig.map(({ value, text, icon: Icon }) => (
                        <Tabs.Trigger
                          key={value}
                          value={value}
                          className="flex items-center justify-center gap-2 py-3 px-7"
                        >
                          <Icon className="w-5" />
                          <span>{text}</span>
                        </Tabs.Trigger>
                      ))}
                    </Tabs.List>
                  </div>

                  <Tabs.Content asChild value={activeEndorsementsValue}>
                    <ActiveTable.Table
                      tableFilters={activeTableFilters}
                      tableSettings={activeTableSettings}
                      setTableSettings={setActiveTableSettings}
                      triggerToast={setAndTriggerToast}
                    />
                  </Tabs.Content>

                  <Tabs.Content asChild value={archiveEndorsementsValue}>
                    <ArchiveTable.Table
                      tableFilters={archiveTableFilters}
                      tableSettings={archiveTableSettings}
                      setTableSettings={setArchiveTableSettings}
                      triggerToast={setAndTriggerToast}
                    />
                  </Tabs.Content>
                </>
              </Tabs.Root>
            </div>
          ) : (
            <Card className="m-auto w-max">
              <p className="mb-4 text-center">
                Looks like you haven&lsquo;t joined a team yet. You need to join
                a team to view endorsements.
              </p>

              <div className="mx-auto">
                <Link
                  href="/teams"
                  label="Join a team"
                  theme="night"
                  style="filled"
                  className="mx-auto"
                  onClick={() => {
                    logButtonClick({
                      buttonName: "Join a team",
                      linkHref: "/teams",
                      containerName: "Main",
                      pageName: PAGE_NAME,
                    })
                  }}
                />
              </div>
            </Card>
          )}
        </div>

        <Toast.Toast
          open={toastState.open}
          onOpenChange={toastState.onOpenChange}
          type={toastType}
          message={toastMessage}
        />

        <ToastViewport />
      </PageNameContext.Provider>
    </ScreenTemplate>
  )
}

export default EndorsementsScreen
