import { LayerFormField, SectionFormField } from "./types"
import { parseMinorClass, parseSubClass } from "../parsers"
import {
  ContractResponse,
  ContractSectionItem,
  EnergyReferenceSplit,
  KiSyndicateData,
  MinorClassCode,
  ReferenceSplit,
  SubClassCode,
  SyndicateLine,
} from "@appia/api"
import { CreateSectionFormField } from "./sectionFormUtils"

export const initializeLayerFormFields = (
  sections: ContractSectionItem[],
): Record<number, LayerFormField> => {
  return sections.reduce(
    (acc: Record<number, LayerFormField>, section: ContractSectionItem) => {
      const syndicateData = section.lines[0].syndicateData.syndicateData

      const layerFormField: LayerFormField = {
        layerId: section.sectionId,
        minorClass: {
          raw: syndicateData.minorClass,
          validated: parseMinorClass(syndicateData.minorClass),
          showError: false,
        },
        subClass: {
          raw: syndicateData.subClass,
          validated: parseSubClass(syndicateData.subClass),
          showError: false,
        },
        brokerSelectedIndustry: section.brokerSelectedIndustry,
        sumTotalAllocatedPremiumCheck: 0,
        yoa: section.yoa,
        groupClass: syndicateData.groupClass || "N/A",
        producingTeam: syndicateData.producingTeam || "N/A",
        classType: syndicateData.classType || "N/A",
        majorClass: syndicateData.majorClass || "N/A",
        sections: initializeSections(section.lines),
      }

      return { ...acc, [section.sectionId]: layerFormField }
    },
    {},
  )
}

export const initializeSections = (
  lines: SyndicateLine[],
): Record<number, SectionFormField> => {
  return lines.reduce((sectionsAcc, line) => {
    const referenceSplits =
      line.syndicateData.syndicateData.referenceSplit || []
    const isSingleReferenceSplit = referenceSplits.length === 1

    const lineSections = referenceSplits.reduce<{
      [key: number]: SectionFormField
    }>((refAcc, refSplit, refIndex) => {
      const isEnergyLayer =
        line.syndicateData.syndicateData.groupClass?.startsWith("energy")

      if (isEnergyLayer && "limitAllocationValueAndCurrency" in refSplit) {
        const energyRefSplit = refSplit as EnergyReferenceSplit

        const sectionFormField = CreateSectionFormField(
          line,
          energyRefSplit,
          isSingleReferenceSplit
            ? line.writtenPremium
            : refSplit.premiumAllocation,
          isSingleReferenceSplit
            ? line.writtenPremiumCurrency
            : refSplit.premiumAllocationCurrency,
          isSingleReferenceSplit ? 100 : refSplit.premiumAllocationPercent,
          true,
          {
            limitAllocation: energyRefSplit.limitAllocation || 0,
            limitPercent: energyRefSplit.limitAllocationPercent || 0,
            minorClass: {
              raw: energyRefSplit.minorClass,
              validated: parseMinorClass(energyRefSplit.minorClass),
              showError: false,
            },
            subClass: {
              raw: energyRefSplit.subClass,
              validated: parseSubClass(energyRefSplit.subClass),
              showError: false,
            },
            groupClass: energyRefSplit.groupClass,
          },
        )
        return { ...refAcc, [refIndex]: sectionFormField }
      } else {
        const sectionFormField = CreateSectionFormField(
          line,
          refSplit,
          isSingleReferenceSplit
            ? line.writtenPremium
            : refSplit.premiumAllocation,
          isSingleReferenceSplit
            ? line.writtenPremiumCurrency
            : refSplit.premiumAllocationCurrency,
          isSingleReferenceSplit ? 100 : refSplit.premiumAllocationPercent,
          false,
        )
        return { ...refAcc, [refIndex]: sectionFormField }
      }
    }, {})

    return { ...sectionsAcc, ...lineSections }
  }, {})
}

export const updateContractDataWithFormFields = (
  contractData: ContractResponse,
  layerFormFields: Record<number, LayerFormField>,
): ContractSectionItem[] => {
  const updatedContractData: ContractResponse = JSON.parse(
    JSON.stringify(contractData),
  )

  updatedContractData.sections.forEach(section => {
    const formFieldsForSection = layerFormFields[section.sectionId]

    if (formFieldsForSection) {
      section.lines.forEach(line => {
        updateSyndicateData(
          line.syndicateData.syndicateData,
          formFieldsForSection,
        )
      })
    }
  })

  return updatedContractData.sections
}

const updateSyndicateData = (
  syndicateData: KiSyndicateData,
  formFieldsForSection: LayerFormField,
): void => {
  syndicateData.minorClass = formFieldsForSection.minorClass
    .raw as MinorClassCode
  syndicateData.subClass = formFieldsForSection.subClass.raw as SubClassCode
  Object.entries(formFieldsForSection.sections).forEach(
    ([index, formSection]) => {
      const refSplit = syndicateData.referenceSplit?.[Number(index)]
      if (refSplit) {
        updateReferenceSplit(refSplit, formSection)
      }
    },
  )
}

const updateReferenceSplit = (
  refSplit: ReferenceSplit,
  formSection: SectionFormField,
): void => {
  refSplit.policyReference = formSection.policyRef.raw
  refSplit.eea = formSection.eea.raw
  refSplit.premiumAllocation =
    formSection.premiumAllocationValueAndCurrency.raw.amount
  refSplit.premiumAllocationCurrency =
    formSection.premiumAllocationValueAndCurrency.raw.unit
  refSplit.premiumAllocationPercent = formSection.premiumAllocationPercent.raw
}
