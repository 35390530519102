import { FC } from "react"
import { PolicyReferencesForSyndicate } from "@appia/api"
import StaticTable from "./StaticTable"

const PolicyReferencesTable: FC<{
  policyReferences?: PolicyReferencesForSyndicate[]
}> = ({ policyReferences = [] }) => {
  const maxNumReferences = policyReferences.reduce(
    (acc, pr) => Math.max(pr.policyReferences.length, acc),
    0,
  )

  const headers = [
    "Syndicate",
    ...Array.from(new Array(maxNumReferences)).map(
      (_, index) => `Section ${index + 1} Ref`,
    ),
  ]

  const data = policyReferences.map(({ syndicate, policyReferences: refs }) => {
    const cells = [
      `${syndicate.name} (${syndicate.code})`,
      ...refs.map(r => {
        return `${r.policyReference} ${
          r.eea === true ? "(EEA)" : r.eea === false ? "(non-EEA)" : ""
        }`
      }),
    ]
    return cells
  })

  return (
    <StaticTable
      caption="Policy References Table"
      headers={headers}
      data={data}
    />
  )
}

export default PolicyReferencesTable
