import { FC, ReactNode } from "react"

import { Pill } from "@appia/ui-components"
import { ID, SrEndOfSentence } from "src/components/A11y"
import EmptyData from "src/components/EmptyData"

import { KiQuote, PBQASurvey } from "@appia/api"

import {
  PBQAReviewState,
  countMatchingAnswersAmongComparisonQuestions,
} from "ReviewPBQA/state"
import { formatCurrency, formatDecimal2DP } from "src/utils/prettyPrinters"

const ASSURED_NAME_LABEL = "Assured name"
const QUOTE_LINE_ID_LABEL = (
  <>
    Quote line <ID />
  </>
)
const LIMIT_LABEL = "Limit"
const PREMIUM_LABEL = "Premium"
const MATCH_LABEL = "Match"

const MatchingFieldsPill: FC<{
  activeSurvey: PBQASurvey
  quote: KiQuote
  state: PBQAReviewState
}> = ({ activeSurvey, quote, state }) => {
  const [x, y] = countMatchingAnswersAmongComparisonQuestions(
    state,
    quote,
    activeSurvey,
  )
  const percentage = x / y
  const label = (
    <>
      {x} out of {y} match
      <SrEndOfSentence />
    </>
  )

  if (percentage >= 0.6) {
    return <Pill type="success" label={label} />
  } else if (percentage >= 0.3) {
    return <Pill type="warning" label={label} />
  } else {
    return <Pill type="neutral" label={label} />
  }
}

const SearchResultField: FC<{
  className?: string
  label: ReactNode
  value: ReactNode
}> = ({ className, label, value }) => (
  <div className={className}>
    <dt className="pb-1 text-sm text-otto-grey-700 lg:hidden">
      {label}
      <span className="sr-only">:&nbsp;</span>
    </dt>
    <dd className="text-base">
      {value}
      <SrEndOfSentence />
    </dd>
  </div>
)

export const SearchResult: FC<{
  activeSurvey: PBQASurvey | null
  quote: KiQuote
  state: PBQAReviewState
}> = ({ activeSurvey, quote, state }) => (
  <dl className="grid gap-2 sm:grid-cols-2 md:grid-cols-[3fr,2fr,auto] lg:grid-cols-[3fr,2fr,2fr,2fr,auto] lg:gap-4">
    <SearchResultField label={ASSURED_NAME_LABEL} value={quote.assuredName} />

    <SearchResultField label={QUOTE_LINE_ID_LABEL} value={quote.quoteLineId} />

    <SearchResultField
      label={LIMIT_LABEL}
      value={
        quote.limitCurrency === null
          ? formatDecimal2DP(quote.limit)
          : formatCurrency(quote.limit, quote.limitCurrency)
      }
    />

    <SearchResultField
      label={PREMIUM_LABEL}
      value={
        quote.premium === null ? (
          <EmptyData />
        ) : quote.premiumCurrency === null ? (
          formatDecimal2DP(quote.premium)
        ) : (
          formatCurrency(quote.premium, quote.premiumCurrency)
        )
      }
    />

    <SearchResultField
      className="w-fit sm:col-span-2 md:col-span-1 md:col-start-3 md:row-start-1 lg:col-start-5"
      label={MATCH_LABEL}
      value={
        activeSurvey ? (
          <MatchingFieldsPill
            activeSurvey={activeSurvey}
            quote={quote}
            state={state}
          />
        ) : null
      }
    />
  </dl>
)

export const ResultsHeader: FC = () => (
  <div className="sticky top-0 z-10 bg-white">
    <p className="border-b border-b-otto-grey-300 px-3 py-1 text-otto-grey-700">
      Search results
    </p>
    <ol
      aria-label="Search result fields"
      // 8rem is the approximate width of the `MatchingFieldsPill`
      className="list-style-none hidden w-full grid-cols-[3fr,2fr,2fr,2fr,8rem] gap-4 border-b border-b-otto-grey-300 py-2 pl-3 pr-9 lg:grid"
    >
      {[
        ASSURED_NAME_LABEL,
        QUOTE_LINE_ID_LABEL,
        LIMIT_LABEL,
        PREMIUM_LABEL,
        MATCH_LABEL,
      ].map((label, i) => (
        <li key={i} className="text-otto-grey-700">
          {label}
        </li>
      ))}
    </ol>
  </div>
)
