import { FC, useId, useState } from "react"
import { SuccessResponse } from "@uppy/core"

import ErrorMessage from "src/components/ErrorMessage"
import Modal from "./Modal"
import ProgressBar from "./ProgressBar"
import UploadArea from "./UploadArea"

import ScreenTemplate from "src/templates/ScreenTemplate"

import { useLogPageView } from "src/amplitude"
import { PageNameContext } from "src/contexts/PageNameContext"
import useDocumentTitle from "src/hooks/useDocumentTitle"

import * as RD from "@appia/remote-data"
import Loading from "src/components/Loading"
import { useGetPBQAUploadFileLimits } from "src/swr"

const PAGE_NAME = "Upload PBQA slip"

const PROGRESS_ID = "upload-progress"

const UploadPBQAScreen: FC = () => {
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const [uploadRequest, setUploadRequest] = useState<
    RD.RemoteData<Error, SuccessResponse>
  >(RD.NotAsked)

  const { request: limitsRequest } = useGetPBQAUploadFileLimits()

  const [uploadProgress, setUploadProgress] = useState<number>(0)

  const headingId = useId()

  return (
    <PageNameContext.Provider value={PAGE_NAME}>
      <ScreenTemplate
        pageTitle={PAGE_NAME}
        layout={{
          type: "regular",
          backPath: "/pbqa",
        }}
      >
        <div className="relative">
          <div role="alert" className="mx-auto w-max max-w-full bg-white">
            {RD.match(
              uploadRequest,

              null,

              // Loading state
              <div className="absolute top-0 left-0 z-[1] flex h-full w-full items-center justify-center bg-otto-grey-100 bg-opacity-75 transition-opacity">
                <ProgressBar id={PROGRESS_ID} progress={uploadProgress} />
              </div>,

              // Success state
              () => (
                <Modal
                  isOpen={true}
                  onClose={() => {
                    setUploadRequest(RD.NotAsked)
                    setUploadProgress(0)
                  }}
                />
              ),

              error => (
                <div className="mb-8">
                  <ErrorMessage error={error} message="Upload failed" />
                </div>
              ),
            )}
          </div>

          <p className="mb-4 text-center text-2xl font-bold">
            Please upload one slip at a time.
          </p>

          {RD.match(
            limitsRequest,
            <Loading />,
            <Loading />,

            limits => (
              <section
                aria-labelledby={headingId}
                className="mx-auto max-w-5xl"
              >
                <h2 className="sr-only" id={headingId}>
                  Upload PBQA
                </h2>

                <UploadArea
                  acceptedDocumentTypes={limits.acceptedDocumentTypes}
                  maxMegabytes={limits.sizeLimitMb}
                  progressId={PROGRESS_ID}
                  setUploadProgress={setUploadProgress}
                  setUploadRequest={setUploadRequest}
                  uploadRequest={uploadRequest}
                />
              </section>
            ),

            error => (
              <ErrorMessage
                error={error}
                message="Failed to load document limits"
              />
            ),
          )}
        </div>
      </ScreenTemplate>
    </PageNameContext.Provider>
  )
}

export default UploadPBQAScreen
