import { useCallback, useEffect, useId, useState } from "react"

import { SuccessResponse, Uppy, UppyFile } from "@uppy/core"
import AwsS3, { AwsS3UploadParameters } from "@uppy/aws-s3"

type UppyError =
  | { type: "error"; error: Error }
  | { type: "restriction-failed"; error: Error; file?: UppyFile }
  | { type: "upload-error"; error: Error; file?: UppyFile }

const initialiseUppy = (
  id: string,
  maxMegabytes: number,
  acceptedDocumentTypes: string[],
  getUploadParameters: (
    file: UppyFile,
    uppy: Uppy,
  ) => Promise<AwsS3UploadParameters>,

  onStartLoad: () => void,
  onProgress: (n: number) => void,
  onError: (e: UppyError) => void,
  onSuccess: (response: SuccessResponse, file?: UppyFile) => void,
): Uppy => {
  // Note that mega = 1000 and mebi = 1024, this is an SI base
  const maxBytes = maxMegabytes * 1_000_000

  const uppy = new Uppy({
    id,
    autoProceed: true,
    allowMultipleUploadBatches: false,
    restrictions: {
      maxFileSize: maxBytes,
      minFileSize: 1,
      maxTotalFileSize: maxBytes,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: acceptedDocumentTypes,
    },
    debug: true,
  })

  uppy
    .use(AwsS3, {
      limit: 1,
      getUploadParameters: async (
        file: UppyFile,
      ): Promise<AwsS3UploadParameters> => {
        onStartLoad()
        const params = await getUploadParameters(file, uppy)
        return { method: "POST", headers: {}, ...params }
      },
    })
    .on("error", error => onError({ type: "error", error }))
    .on("restriction-failed", (file, error) =>
      onError({ type: "restriction-failed", file, error }),
    )
    .on("upload-error", (file, error) =>
      onError({ type: "upload-error", file, error }),
    )
    .on("progress", onProgress)
    .on("upload-success", (file, response) => onSuccess(response, file))

  return uppy
}

interface UseUppyProps {
  maxMegabytes: number
  acceptedDocumentTypes: string[]
  getUploadParameters: (
    file: UppyFile,
    uppy: Uppy,
  ) => Promise<AwsS3UploadParameters>

  onStartLoad: () => void
  onProgress?: (n: number) => void
  onError: (e: UppyError) => Promise<void>
  onSuccess: (response: SuccessResponse, file?: UppyFile) => Promise<void>
}

const useUppy = ({
  maxMegabytes,
  acceptedDocumentTypes,
  getUploadParameters,

  onStartLoad,
  onProgress,
  onError,
  onSuccess,
}: UseUppyProps): Uppy => {
  const id = useId()

  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  const mkUppy = useCallback(() => {
    const _onStartLoad = (): void => {
      setHasLoaded(false)
      onStartLoad()
    }

    const _onSuccess = (response: SuccessResponse, file?: UppyFile): void => {
      setTimeout(() => {
        setHasLoaded(true)
        onSuccess(response, file)
      }, 1000)
    }

    const _onError = (e: UppyError): void => {
      setHasLoaded(true)
      onError(e)
    }

    return initialiseUppy(
      id,
      maxMegabytes,
      acceptedDocumentTypes,
      getUploadParameters,
      _onStartLoad,
      onProgress ??
        (() => {
          return
        }),
      _onError,
      _onSuccess,
    )
  }, [
    id,
    maxMegabytes,
    acceptedDocumentTypes,
    getUploadParameters,
    onStartLoad,
    onProgress,
    onError,
    onSuccess,
  ])

  const [uppy, setUppy] = useState<Uppy>(mkUppy())

  useEffect(() => {
    // Reinitialise Uppy after the user uploads a file, or else they won't be
    // able to upload any more
    if (hasLoaded) {
      setHasLoaded(false)
      setUppy(mkUppy())
    }
  }, [mkUppy, hasLoaded])

  return uppy
}

export default useUppy
