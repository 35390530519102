import { FC, Fragment } from "react"

import { Transition } from "@headlessui/react"
import { CopyRightIcon, IconButton, formatAmount } from "@appia/ui-components"

import {
  QuestionAnswerState,
  QuestionAnswerStateBoolean,
  QuestionAnswerStateEEA,
  QuestionAnswerStateOverlining,
} from "ReviewPBQA/state"

const ReadOnlyKiData: FC<{
  questionAnswerState: Exclude<
    QuestionAnswerState,
    | QuestionAnswerStateBoolean
    | QuestionAnswerStateOverlining
    | QuestionAnswerStateEEA
  >
  onCopyValue?: () => void
}> = ({ questionAnswerState, onCopyValue }) => {
  let value: string | null | undefined = null
  let unit: string | null | undefined = null
  switch (questionAnswerState.type) {
    case "broker":
      value = questionAnswerState.kiAnswer?.name
      break
    case "syndicate":
      value = questionAnswerState.kiAnswer?.name
      unit = questionAnswerState.kiAnswer?.code.toString()
      break
    case "date":
    case "inception_date": {
      if (questionAnswerState.kiAnswer) {
        value = new Intl.DateTimeFormat("en-GB", { dateStyle: "short" }).format(
          new Date(questionAnswerState.kiAnswer),
        )
      }
      break
    }
    case "country":
    case "insured":
    case "policy_reference":
    case "string":
    case "umr":
    case "option":
      value = questionAnswerState.kiAnswer
      break
    case "option_multi":
      value = questionAnswerState.kiAnswer?.join(", ")
      break
    case "decimal":
    case "integer": {
      const precision =
        "precision" in questionAnswerState.question
          ? questionAnswerState.question.precision
          : undefined
      if (questionAnswerState.kiAnswer) {
        value = formatAmount(
          questionAnswerState.kiAnswer.answer,
          precision ?? 2,
        )
        unit = questionAnswerState.kiAnswer.unit
      }
      break
    }
  }

  const { label } = questionAnswerState.question

  const valueAsString: string = `${value || ""} ${unit || ""}`.trim()

  const buttonText = `Replace extracted value with ${
    valueAsString
      ? `Ki quote value of ${valueAsString}`
      : `empty Ki quote value`
  }`

  return (
    <dl>
      <dt className="block flex min-h-[1.75rem] items-end justify-between pb-1">
        <span aria-hidden>{label}</span>
        <span className="sr-only">{`${label} from Ki quote`}</span>

        <Transition
          as={Fragment}
          show={onCopyValue !== undefined && valueAsString !== ""}
          leave="transition-opacity duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <IconButton
            icon={<CopyRightIcon />}
            color="text-otto-grey-700"
            size={6}
            label={buttonText}
            onClick={() => onCopyValue && onCopyValue()}
            data-cy="copy-across-trigger"
          />
        </Transition>
      </dt>

      <dd
        className="block flex min-h-[2rem] w-full items-center justify-between gap-1 rounded border border-otto-grey-400 bg-otto-grey-200 px-2 text-base"
        title={valueAsString || undefined} // If the string is empty, don't add a title
      >
        <span className="truncate">{value}</span>
        <span>{unit}</span>
      </dd>
    </dl>
  )
}

export default ReadOnlyKiData
