import * as Sentry from "@sentry/react"
import {
  EnergyReferenceSplit,
  MinorClassCode,
  MinorSubClassesRequest,
  ReferenceSplit,
  SyndicateLine,
} from "@appia/api"
import {
  parseEeaOrRow,
  parseMinorClass,
  parsePolicyReference,
  parseSectionPercentage,
  parseSectionValueAndCurrency,
  parseSubClass,
} from "../parsers"

import {
  BaseFormField,
  EnergySectionFormField,
  SectionFormField,
} from "./types"
import { FormField } from "@appia/form-data"
import { EnergyGroupClassCode } from "@appia/api/src/Contracts/EnergyGroupClass"

export function CreateSectionFormField(
  line: SyndicateLine,
  refSplit: ReferenceSplit | EnergyReferenceSplit,
  premiumAllocationAmount: number | null,
  premiumAllocationCurrencyValue: string | null,
  premiumAllocationPercentValue: number | null,
  isEnergy: boolean,
  energyFields?: {
    limitAllocation: number
    limitPercent: number
    minorClass: FormField<MinorClassCode, string> & { showError: boolean }
    subClass: FormField<string, string | null> & { showError: boolean }
    groupClass: EnergyGroupClassCode
  },
): BaseFormField {
  const baseSectionFormField: SectionFormField = {
    layerWrittenPremium: line.writtenPremium,
    policyRef: {
      raw: refSplit.policyReference,
      validated: parsePolicyReference(refSplit.policyReference),
      showError: false,
    },
    eea: {
      raw: refSplit.eea,
      validated: parseEeaOrRow(refSplit.eea),
      showError: false,
    },
    premiumAllocationValueAndCurrency: {
      raw: {
        amount: premiumAllocationAmount,
        unit: premiumAllocationCurrencyValue,
      },
      validated: parseSectionValueAndCurrency({
        amount: premiumAllocationAmount,
        unit: premiumAllocationCurrencyValue,
      }),
      showError: false,
    },
    premiumAllocationPercent: {
      raw: premiumAllocationPercentValue,
      validated: parseSectionPercentage(premiumAllocationPercentValue),
      showError: false,
    },
  }

  // Handle energy sections
  if (isEnergy && energyFields) {
    return {
      ...baseSectionFormField,
      layerLimit: null,
      limitAllocationValueAndCurrency: {
        raw: {
          amount: energyFields.limitAllocation,
          unit: premiumAllocationCurrencyValue,
        },
        validated: parseSectionValueAndCurrency({
          amount: energyFields.limitAllocation,
          unit: premiumAllocationCurrencyValue,
        }),
        showError: false,
      },
      limitAllocationPercent: {
        raw: energyFields.limitPercent,
        validated: parseSectionPercentage(energyFields.limitPercent),
        showError: false,
      },
      minorClass: {
        raw: energyFields.minorClass.raw,
        validated: parseMinorClass(energyFields.minorClass.raw),
        showError: false,
      },
      subClass: {
        raw: energyFields.subClass.raw,
        validated: parseSubClass(energyFields.subClass.raw),
        showError: false,
      },
      groupClass: energyFields.groupClass,
    } as EnergySectionFormField
  }

  return {
    ...baseSectionFormField,
  }
}

export const validateClassesRequest = (
  request: MinorSubClassesRequest,
): MinorSubClassesRequest => {
  const missingFields: string[] = []

  if (!request.groupClass) missingFields.push("Group Class")
  if (!request.producingTeam) missingFields.push("Producing Team")
  if (!request.classType) missingFields.push("Class Type")
  if (!request.majorClass) missingFields.push("Major Class")

  if (missingFields.length > 0) {
    const error = new Error(
      `Missing fields for Minor Subclasses Request: ${missingFields.join(
        ", ",
      )}`,
    )
    Sentry.captureException(error)
    throw error
  }

  return request
}
