export const SubClassCode = {
  ACCOUNTANTS_CHARTERED: "90002",
  ACCOUNTANTS_OTHER: "90003",
  ACCOUNTANTS_TAX_CONSUMER: "96108",
  ACTIVITY_CENTRES_SKI_RESORTS_RIDING_SCHOOLS_ETC: "91179",
  ACTUARIES: "90004",
  ADF_AND_T_NON_USA: "18400",
  AD_CAPS: "99601",
  AERIAL_PHOTOGRAPHY: "99559",
  AEROBATIC_FLYING: "99560",
  AEROSPACE_DEFENCE: "90400",
  AFRICA: "26400",
  AFRICAN_HULLS: "56200",
  AGRICULTURAL_AIRCRAFT: "99561",
  AGRICULTURE_90430: "90430",
  AGRICULTURE_90621: "90621",
  AGRICULTURE_FARMING: "99402",
  AIRCRAFT_AND_SPARES: "12100",
  AIRCREW: "91100",
  AIRLINES: "96127",
  AIRLINE_AIRCRAFT_MANUFACTURER: "99403",
  AIRLINE_AIRPORTS_ALL_NON_FLYING_ACTIVITIES_INCL_CATERING: "91180",
  AIRPORT: "99404",
  AIRPORTS: "90603",
  AIRSHIP: "99563",
  AIR_CARGO: "96128",
  AIR_RESCUE: "99562",
  AIR_SENDINGS: "11900",
  ALARM_INSTALLERS: "91101",
  ALCOHOL_MANUFACTURER: "96126",
  ALLIED_HEALTH: "90354",
  AMERICAN_EX_NORTH_HULLS: "74000",
  AMUSEMENT_PARKS: "99405",
  ANIMAL_FEEDS: "91102",
  ANNUAL_CONSTRUCTION: "91177",
  APARTMENTS_GARDEN_STYLE: "90600",
  APARTMENTS_HIGH_RISE: "90632",
  APPARTMENTS_FLOOD: "90760",
  ARCHITECTS: "90101",
  ARCHITECTS_AND_ENGINEERS: "91181",
  ARCHITECTS_ENGINEERS: "90100",
  ARENA_RACETRACK: "99406",
  ARMED_FORCES: "99594",
  ARMS_AMMUNITIONS_AND_MILITARY_EQUIPMENT: "12200",
  ART_EXHIBITIONS_AND_RELATED_TRANSITS: "24200",
  ART_GALLERIES: "91182",
  ASBESTOS: "12000",
  ASBESTOS_REMOVAL_CONTRACTORS: "91103",
  ASBESTOS_SURVEYING: "91183",
  ASSET_GUARD: "24111",
  ASSISTED_LIVING: "90664",
  ASSOCIATIONS: "90300",
  ASTRONAUT_EXCL_EVA: "99564",
  ASTRONAUT_INCL_EVA: "99565",
  ATHLETICS: "99503",
  ATM_ABM_Coffers: "23707",
  AUSTRALIAN_HULLS: "52400",
  AUSTRALIA_AND_NZ: "26500",
  AUTOMOBILES: "90401",
  AUTOMOTIVE_RETAIL: "96111",
  AUTOS: "16603",
  AUTO_LIABILITY: "90960",
  AVERAGE_DISBURSEMENTS: "29100",
  AVIATION_CONFISCATION: "29600",
  AVIATION_WAR_HULL: "29400",
  AVIATION_WAR_LIABILITY: "29401",
  BALLOONING: "99566",
  BALLOON_OPERATORS: "91184",
  BANK: "99407",
  BANKS: "90795",
  BANKS_FI: "90200",
  BANNER_TOWING: "99567",
  BARGE_TUG: "63401",
  BARRISTERS: "90005",
  BASEBALL: "99505",
  BASKETBALL: "99530",
  BEAUTY_AND_COSMETICS_RETAIL: "96114",
  BELGIAN_HULLS: "44200",
  BEVERAGES_BREWERIES: "90402",
  BINDING_AUTHORITIES: "16612",
  BOATS: "16606",
  BOATS_AS_CARGO: "21200",
  BOAT_DEALERS: "24400",
  BOBSLEIGH: "99506",
  BOND_CREDIT_SURETY: "99003",
  BOWLS: "99507",
  BRANCH_BANK: "90432",
  BREACH_CHEMICAL_TANKER: "62402",
  BREACH_CONTAINER: "62403",
  BREACH_DREDGER: "62404",
  BREACH_DRY_BULK: "62405",
  BREACH_FISH: "62406",
  BREACH_FISH_FACTORY: "62407",
  BREACH_GENERAL_CARGO: "62408",
  BREACH_HEAVY_LIFT: "62409",
  BREACH_LIVESTOCK: "62410",
  BREACH_LNG: "62411",
  BREACH_LPG: "62412",
  BREACH_MISC: "62413",
  BREACH_PASSENGER: "62414",
  BREACH_PIPE_LAYER: "62415",
  BREACH_REEFER: "62416",
  BREACH_RESEARCH_VESSEL: "62417",
  BREACH_RORO: "62418",
  BREACH_SUPPLY_SUPPORT: "62419",
  BREACH_TANKER: "62420",
  BREACH_TUG: "62421",
  BREACH_VEHICLE_CARRIER: "62422",
  BREWERY_WINERY: "99408",
  BRIDGES: "90913",
  BRITISH_HULLS: "40000",
  BROKERS_LINESLIPS: "16611",
  BROKER_DEALER_FUND_MANAGER_COMMERCIAL_MIXED: "96103",
  BUILDERS: "91104",
  BUILDING_MATERIALS_AND_DIY_GOODS: "21600",
  BUILDING_SOCIETY: "90501",
  BULLION_DEALERS: "23708",
  BUNGEE_JUMPING: "99509",
  BUSINESS: "99588",
  BUSINESS_ADVISORS_INDEMNITY: "90308",
  BUSINESS_FINANCE_BANKING: "96101",
  CABLES: "79500",
  CABLE_PIPE_LAYER: "63415",
  CANADA: "26200",
  CANADIAN_HULLS: "47000",
  CANNED_GOODS: "12400",
  CARGO_DUTCH: "25200",
  CARGO_FRENCH: "25300",
  CARGO_GERMAN: "25400",
  CARGO_ITALIAN: "25500",
  CARGO_SPANISH: "25600",
  CARGO_WAR: "29200",
  CARNET: "21300",
  CARPETS_AND_RUGS: "12500",
  CART: "99511",
  CAR_PARK_DEALERSHIP: "99409",
  CASH_IN_TRANSIT: "23900",
  CASH_IN_TRANSIT_FACILITY: "23905",
  CASINO_NIGHT_CLUB_BAR: "99410",
  CEMENT: "12600",
  CEMENT_MANUFACTURING: "90686",
  CENTRAL_BANK: "90502",
  CENTRAL_DEPOSITORY: "90503",
  CHARITY: "91185",
  CHARTERERS_ENTERED: "27900",
  CHARTERERS_NON_ENTERED: "27901",
  CHEMICALS: "12700",
  CHEMICALS_NON_PETRO: "90622",
  CHEMICALS_PETRO: "90654",
  CHEMICAL_MISC: "91106",
  CHEMICAL_PROCESSING_WIND: "90750",
  CHEMICAL_TRAILS: "91109",
  CHEQUE_CASHING: "23709",
  CHILLED_AND_FROZEN_FOOD: "15700",
  CHINESE_HULLS: "53200",
  CIGAR_AND_CIGARETTE_MANUFACTURERS: "91186",
  CINEMA_THEATER: "99411",
  CIVIL_ENGINEERS: "91107",
  CLAIMS_MADE_LIABILITY: "90950",
  CLASS: "52100",
  CLEANERS_INDUSTRIAL: "91108",
  CLINICAL_TRIALS: "91187",
  CLOTHING_CLOTH_AND_TEXTILES: "19800",
  CLOTHING_RETAIL: "96112",
  COAL_ROCK_AND_SAND: "13500",
  COCOA: "13600",
  COCOA_COFFEE_AND_TEA: "13700",
  COINS_STAMPS_COLLECTABLES: "24109",
  COLUMBIA_NON_BUREAU: "90880",
  COMBINED_PROPERTY_PL_EL: "95100",
  COMMERCIAL_AIRCRAFT: "99568",
  COMMERCIAL_BANK: "90500",
  COMMERCIAL_COMBINED_DELEGATED: "94100",
  COMMERCIAL_COMBINED_DIRECT: "94150",
  COMMERCIAL_ENTITY: "90437",
  COMMERCIAL_WEATHER_DAY: "91010",
  COMMUNICATIONS_AND_TELECOMS: "90623",
  COMPUTERS_CONSUMER_ELECTRONICS: "96113",
  COMPUTER_CENTER_PROCESSOR: "90436",
  COMPUTER_CONSULTANTS: "90302",
  COMPUTER_HARDWARE: "90404",
  COMPUTER_SERVICES_SUPPORT: "90406",
  COMPUTER_SOFTWARE: "90405",
  CONDOMINIUMS: "90601",
  CONFERENCE_EXHIBITION: "99412",
  CONFIDENTIAL_LIFE: "91013",
  CONSTRUCTION_90624: "90624",
  CONSTRUCTION_99413: "99413",
  CONSTRUCTION_99595: "99595",
  CONSTRUCTION_OPEN_MARKET: "62501",
  CONSTRUCTION_BUILDING: "90407",
  CONSTRUCTION_FLOOD: "90784",
  CONSTRUCTION_LONG_TERM: "90103",
  CONSTRUCTION_RISKS: "30000",
  CONSULTING_ENGINEERS: "90102",
  CONSUMER_FINANCE_BANKING: "96102",
  CONTAINERS: "11800",
  CONTAINER_REEFER: "62103",
  CONTINGENCY_K_R: "99001",
  CONTRACTORS_EQUIPMENT: "22900",
  CONTRACTS_LIABILITY: "90958",
  CONTRACT_PROTECTION_INSURANCE: "91014",
  CONVERSION_OPEN_MARKET: "62601",
  CONVERSION_RISKS: "30001",
  COPRA: "14000",
  CORPORATE_AIRCRAFT: "99569",
  CORPORATE_COLLECTION: "24105",
  COSMETICS_AND_PERFUMES: "11200",
  COTTON_AND_WOOL: "14100",
  COURIER_24000: "24000",
  COURIER_99414: "99414",
  CRANE_HIRERS: "91188",
  CREW_ABANDONMENT: "29050",
  CRICKET: "99512",
  CROWN_AGENTS: "76600",
  CURRENCY_EXCHANGE: "23710",
  CURRENCY_MANUFACTURER: "23711",
  CYBER_TERRORISM: "90819",
  CYBER_UK_BRIT_TECH_PORTAL: "98501",
  CYBER_UK_CPR_PORTAL: "98500",
  CYBER_UK_PRIVACY_PROTECT_PORTAL: "98502",
  CYCLING: "99514",
  CYPRUS_HULLS: "58400",
  C_AND_S_AMERICA: "26300",
  DAIRY_PRODUCE: "23100",
  DANISH_HULLS: "71200",
  DBA_FVWC: "99597",
  DEALERS_OPEN_LOT: "90729",
  DEATH: "99600",
  DEMOLISION: "91110",
  DESIGN_CONSTRUCT: "90104",
  DIAMONDS_UNMOUNTED_DIAMOND_DEALERS: "23804",
  DIAMOND_CUTTING: "23712",
  DIAMOND_POLISHING: "23713",
  DISTRIBUTORS: "90408",
  DIVERSIFIED_INDUSTRIES: "90409",
  DIVING: "91111",
  DIVING_SUB_AQUA: "99516",
  DOCUMENTS_OF_VALUE: "23500",
  DRAG_RACING: "99517",
  DREDGER: "63404",
  DRIED_FRUITS_WITH_REJECTION: "18100",
  DRILLING_CONTRACTORS: "91189",
  DRILLING_EQUIPMENT: "14400",
  DRY_BULK: "62105",
  DRY_DOCKS: "74800",
  DUMMY_CODE: "99999",
  DURABLE_GOODS_MANUFACTURER: "96125",
  DUTCH_HULLS: "79900",
  D_O: "95900",
  EASTERN_BLOCK: "77700",
  EDUCATION: "90320",
  EDUCATION_DIC: "90637",
  EDUCATION_FLOOD: "90765",
  EDUCATION_QUAKE: "90701",
  ELECTRICAL: "91190",
  ELECTRICAL_AND_MEDICAL_HI_TECH: "17100",
  ELECTRICAL_COMPONENT_MANUFACTURER: "91113",
  ELECTRICAL_GOODS_NOT_HI_TECH_OR_MEDICAL: "14700",
  ELECTRICAL_UTILITIES: "91114",
  ELECTRIC_UTILITY: "99415",
  ELECTRONICS_MANUFACTURING: "90669",
  ELECTRONIC_HARDWARE: "90410",
  EMERGENCY_FLOOD: "90766",
  EMERGENCY_QUAKE: "90702",
  EMPLOYERS_LIABILITY_UK_COMBINED: "95300",
  ENERGY: "99305",
  ENERGY_SUPPLIER_SIDE: "96120",
  ENERGY_CONSTRUCTION: "51001",
  ENERGY_RELATED: "51000",
  ENERGY_WAR: "47810",
  ENGINEER: "91115",
  ENGINEERING_90411: "90411",
  ENGINEERING_95600: "95600",
  ENTERTAINMENT_90607: "90607",
  ENTERTAINMENT_91011: "91011",
  ENTERTAINMENT_RECREATION_DIC: "90639",
  ENTERTAINMENT_RECREATION_FLOOD: "90767",
  ENTERTAINMENT_RECREATION_WIND: "90735",
  ENVIRONMENTAL_CONSULTANCY: "90304",
  ENVIRONMENTAL_CONSULTANTS: "91191",
  ENVIRONMENTAL_LIABILITY: "91178",
  ESTATE_AGENTS: "90105",
  PETROCHEMICAL_REFINING: "47802",
  EUROPEAN: "25700",
  EVENT: "99416",
  EVENT_OTHER_INDOOR: "91003",
  EVENT_OTHER_OUTDOOR: "91004",
  EXCESS_ARMOURED_CAR_XS_1M: "23702",
  EXCESS_SIPC: "23714",
  EXHIBITION: "23808",
  EXPATRIATE: "99591",
  EXPELLERS: "16500",
  EXPLOSIVE_MANUFACTURER: "91116",
  FACILITY_TREATY: "62324",
  FACILITY_TREATY_62424: "62424",
  FACILITY_TREATY_62624: "62624",
  FACILITY_TREATY_62724: "62724",
  FACILITY_TREATY_63324: "63324",
  FACILITY_TREATY_63624: "63624",
  FAILURE_TO_DELIVER: "47400",
  FAR_EAST_CHINA_AND_INDIA: "25900",
  FAR_EAST_HULLS: "45000",
  FERTILIZER: "13200",
  FERTILIZER_AND_AGRO_CHEMICALS: "91117",
  FILM_EX_COMP_BONDS: "91021",
  FILMS_NEGATIVES_SOFTWARE_MUSIC_AND_GAMES: "16300",
  FINANCIAL_ALL_RISKS: "90608",
  FINANCIAL_DIC: "90640",
  FINANCIAL_FLOOD: "90768",
  FINANCIAL_INSTITUTIONS_90316: "90316",
  FINANCIAL_INSTITUTIONS_90515: "90515",
  FINANCIAL_LIABILITY: "90971",
  FINANCIAL_PLANNERS: "90201",
  FINANCIAL_QUAKE: "90704",
  FINANCIAL_SERVICES: "99711",
  FINANCIAL_WIND: "90736",
  FINE_ARTS_FACILITY: "24110",
  FINE_ART_PRIMARY_OPEN_TO_PUBLIC: "24101",
  FINE_ART_XS_1M_OPEN_TO_PUBLIC: "24102",
  FINNISH_HULLS: "71400",
  FIREWORKS: "91175",
  FISHING: "99519",
  FISHMEAL: "15600",
  FISH_ETC_GIT: "15800",
  FISH_ETC_INC_REJECTION: "15900",
  FISH_FISH_FACTORY: "63406",
  FISH_SPOTTERS: "99570",
  FLIGHT_RISKS_ONLY: "99605",
  FLOUR: "16100",
  FLOWERS_AND_PLANTS: "15300",
  FOOD_DAIRY: "90689",
  FOOD_FISHERIES: "90785",
  FOOD_MEAT_PROCESSING: "90625",
  FOOD_OTHER: "90679",
  FOOD_POULTRY_INC_HATCHERIES: "90647",
  FOOD_SUGAR: "90687",
  FOOD_AND_DRINK: "91118",
  FOOD_DRINK_HEAVY: "10010",
  FOOD_DRINK_LIGHT_MEDIUM: "10011",
  FOOD_DRINK_MANUFACTURER: "96121",
  FOOD_DRINK_RETAIL: "96118",
  FOOD_DRUGS_PROCESSING_WIND: "90753",
  FOOD_PRODUCERS: "90412",
  FOOTBALL_AMERICAN: "99520",
  FOOTBALL_OZZIE_RULES: "99521",
  FOOTBALL_SOCCER: "99522",
  FOOTBALL_CLUBS_STANDS_AND_PAVILIONS: "91192",
  FORBES_BIKES_MEDEX: "99501",
  FORBES_BIKES_PA: "99502",
  FORBES_CAR_MEDEX: "99508",
  FORBES_CAR_PA: "99504",
  FORBES_KART_MEDEX: "99513",
  FORBES_KART_PA: "99510",
  FORBES_MOTORSPORT_MEDEX: "99515",
  FORBES_MOTORSPORT_PA: "99518",
  FORESTRY: "91193",
  FORMULA_1: "99523",
  FORMULA_2: "99524",
  FORMULA_3: "99525",
  FOUNDRY: "91165",
  FPA: "18900",
  FPA_ABS: "45800",
  FPA_UCB: "45900",
  FRAGILE_CARGO: "17800",
  FREIGHT: "16000",
  FREIGHT_FORWARDERS: "11600",
  FRENCH_HULLS: "44800",
  FRUIT_AND_VEGETABLES: "15100",
  FRUIT_AND_VEGETABLE_JUICE: "15200",
  FTC_USA: "18800",
  FULL_BENEFITS: "99602",
  FUND_MANAGERS: "90202",
  GALLERY_DEALERS: "24104",
  GAS: "19200",
  GAS_MANUFACTURERS: "91119",
  GAS_PLANTS: "47803",
  GAS_UTILITIES: "91120",
  GENERAL_CARGO_AND_COMMODITIES: "10100",
  GENERAL_CARGO_LIVESTOCK: "62108",
  GENERAL_COMMERCIAL_ALL_RISKS: "90616",
  GENERAL_COMMERCIAL_ALL_RISKS_90916: "90916",
  GENERAL_COMMERCIAL_FLOOD: "90776",
  GENERAL_COMMERCIAL_QUAKE: "90712",
  GENERAL_COMMERCIAL_WIND: "90744",
  GENERAL_COST_OF_CONTROL: "47200",
  GENERAL_DOWNSTREAM_ENERGY: "47800",
  GENERAL_ENERGY: "47500",
  GENERAL_FREIGHT: "16601",
  GENERAL_HOUSEHOLD_ALL_RISKS: "90850",
  GENERAL_HOUSEHOLD_DIC: "90851",
  GENERAL_HOUSEHOLD_FLOOD: "90855",
  GENERAL_HOUSEHOLD_HSB_HOMEOWNER_PRODUCTS: "99991",
  GENERAL_HOUSEHOLD_QUAKE: "90853",
  GENERAL_HOUSEHOLD_WIND: "90854",
  GENERAL_INDUSTRIAL: "90626",
  GENERAL_INDUSTRIAL_DIC: "90658",
  GENERAL_INDUSTRIAL_FLOOD: "90786",
  GENERAL_INDUSTRIAL_QUAKE: "90722",
  GENERAL_INDUSTRIAL_WIND: "90754",
  GENERAL_LIABILITY: "90953",
  GENERAL_LIABILTY: "50000",
  GENERAL_MEDICAL_MALPRACTICE: "90350",
  GENERAL_MUNICIPLE_DIC: "90641",
  GENERAL_MUNICIPLE_FLOOD: "90769",
  GENERAL_MUNICIPLE_QUAKE: "90705",
  GENERAL_MUNICIPLE_WIND: "90737",
  GENERAL_OFFSHORE_ENERGY: "47300",
  GENERAL_ONSHORE_ENERGY: "47700",
  GENERAL_SPECIE: "23700",
  GERMAN_HULLS: "46100",
  GLASS_MANUFACTURING_BOTTLING: "90633",
  GLASS_MANUFACTURERS: "91166",
  GLASS_METALS_MANUFACTURER: "96124",
  GL_CLASH: "99005",
  GLIDING: "99571",
  GLOBAL_FINANCIAL_SERVICES: "90433",
  GL_RISK: "99011",
  GOLF: "99527",
  GOVERNMENT_AND_PUBLIC_SERVICES: "99713",
  GOVERNMENT_BUILDINGS: "99417",
  GO_KARTING: "99526",
  GRAIN_AND_FLOUR: "16200",
  GREEK_HULLS: "72200",
  GROUNDNUTS_ETC: "18000",
  GROUNDWORK_CONTRACTORS: "91194",
  GYMNASTICS: "99528",
  HANG_GLIDING: "99572",
  HAULAGE_CONTRACTORS: "91121",
  HEALTHCARE: "90413",
  HEALTHCARE_HOSPITALS: "90610",
  HEALTHCARE_DIC: "90642",
  HEALTHCARE_FLOOD: "90770",
  HEALTHCARE_NETWORKS: "90319",
  HEALTHCARE_PROVIDER: "90313",
  HEALTHCARE_QUAKE: "90706",
  HEALTHCARE_WIND: "90738",
  HEATING_AND_VENTILATING_CONTRACTORS: "91122",
  HEAVY_LIFT_AND_SEMI_SUB: "17500",
  HEAVY_LIFT_LIFT_BOATS: "63409",
  HEAVY_MACHINERY: "16605",
  HEAVY_MANUFACTURING_ASSEMBLY_FLOOD: "90787",
  HEAVY_MANUFACTURING_ASSEMBLY_WIND: "90755",
  HELICOPTERS: "99573",
  HIDES_SKINS_ETC: "16800",
  HIGHWAY_ALL_RISKS: "90611",
  HIGHWAY_DIC: "90643",
  HIGHWAY_FLOOD: "90771",
  HIGHWAY_QUAKE: "90707",
  HIGHWAY_WIND: "90739",
  HIGH_TECHNOLOGY: "90628",
  HIGH_TECHNOLOGY_DIC: "90660",
  HIGH_TECHNOLOGY_FLOOD: "90788",
  HIGH_TECHNOLOGY_QUAKE: "90724",
  HIGH_TECHNOLOGY_WIND: "90756",
  HIGH_VALUE_HOMEOWNER_DELEGATED: "94300",
  HIGH_VALUE_HOMEOWNER_DIRECT: "94350",
  HOCKEY: "99529",
  HOIST_AND_LIFT_MANUFACTURERS: "91195",
  HOME_RETAIL: "96115",
  HOME_WARES_FURNISHINGS_AND_FURNITURE: "11300",
  HOSPITALITY_MANAGEMENT: "96116",
  HOSPITALS_90351: "90351",
  HOSPITALS_91123: "91123",
  HOSPITALS_99418: "99418",
  HOTELS_OTHER: "90666",
  HOTELS_ALL_RISKS: "90602",
  HOTELS_BEACHFRONT: "90798",
  HOTELS_DIC: "90634",
  HOTELS_FLOOD: "90762",
  HOTELS_QUAKE: "90698",
  HOTELS_WIND: "90730",
  HOTEL_AND_RESTAURANT: "91124",
  HOTEL_RESORT: "99419",
  HOUSEHOLD_GOODS_TEXTILES: "90414",
  HOUSING_ASSOCIATIONS: "91196",
  HULL_AMERICA_EX_NORTH: "76204",
  HULL_AND_MACHINERY_WAR: "29300",
  HULL_AUSTRALIA_NZ: "76203",
  HULL_CANADA: "76205",
  HULL_FAR_EAST: "76206",
  HULL_FOR_VOYAGE: "24900",
  HULL_FRANCE: "76207",
  HULL_GERMANY: "76208",
  HULL_ITALY: "76209",
  HULL_JAPAN: "76210",
  HULL_KOREA: "76216",
  HULL_MIDDLE_EAST: "76211",
  HULL_OTHER: "76214",
  HULL_OTHER_EUROPE: "76212",
  HULL_PHILIPPINES: "76217",
  HULL_SCANDINAVIA: "76215",
  HULL_SPAIN: "76213",
  HULL_UK: "76201",
  HULL_USA: "76202",
  HUNTING: "99531",
  HURLING: "99532",
  HYDRO: "97805",
  HYDRO_ELECTRIC: "47805",
  HYDRO_ELECTRIC_PLANT: "99420",
  ICELANDIC_HULLS: "71300",
  ICE_HOCKEY: "99533",
  ICE_SKATING: "99534",
  ICE_SKATING_RINKS: "91197",
  INCOME_PROTECTION: "99609",
  INDIAN_HULLS: "53000",
  INDUSTRIAL: "90415",
  INDUSTRIAL_HEAVY: "10012",
  INDUSTRIAL_LIGHT_MEDIUM: "10013",
  INDY_CARS: "99535",
  INSURANCE_COMMERCIAL: "96104",
  INSURANCE_HEALTH: "96106",
  INSURANCE_PERSONAL: "96105",
  INSURANCE_BROKERS: "90006",
  INSURANCE_BROKERS_CONSUMER: "96109",
  INSURANCE_COMPANY: "90506",
  INSURANCE_LIFE_ASSURANCE: "90416",
  INTERNATIONAL_MEDICAL: "80101",
  INVESTMENT_BANK: "90507",
  INVESTMENT_COMPANIES_ASSET_MANAGERS_EX: "90417",
  ITALIAN_HULLS: "79400",
  JAPAN: "26000",
  JAPANESE_HULLS: "75600",
  JB_PRIVATE_COLLECTION: "23811",
  JEWELLERS_BLOCK: "23800",
  JEWELLERS_BLOCK_FACILITY: "23810",
  JEWELLERS_BLOCK_PRIMARY: "23801",
  JEWELLERS_BLOCK_XS_500_000: "23802",
  JOCKEYS: "99536",
  JOURNALISTS: "99596",
  JUTE_HESSIAN_ETC: "16900",
  J_FORM: "23721",
  KARATE: "99537",
  KIDNAP_RANSOM: "99611",
  LABOUR_SUPPLY_COMPANIES: "91198",
  LAKERS: "75800",
  LANDRIGS: "47806",
  LANDSCAPE_GARDENING: "91199",
  LAND_CONSTRUCTION: "24700",
  LAND_SPEED_RECORD: "99538",
  LAND_YACHTS: "99539",
  LAWYERS_CONSUMER: "96110",
  LAWYERS_SOLICITORS: "90001",
  LEATHER_GOODS_FURS_HIDES_AND_SKINS: "17200",
  LEGAL_LIABILITY_TO_CARGO: "22000",
  LEISURE_CENTRES_SPORTS_CENTRES_HEALTH_CLUBS: "91080",
  LEISURE_HIGH_RISK: "10014",
  LEISURE_HOTELS_ENTERTAINMENT: "90418",
  LEISURE_LOW_TO_MEDIUM_RISK: "10015",
  LIFT_BOAT: "79800",
  LIGHT_MANUFACTURING_ASSEMBLY_FLOOD: "90777",
  LIGHT_MANUFACTURING_ASSEMBLY_WIND: "90745",
  LIMITED_CONDITIONS: "63301",
  LLOYDS_BROKERS: "90007",
  LLOYDS_CHINA_TERRORISM: "90887",
  LOCAL_COUNCILS: "90107",
  LOCAL_MUNICIPAL_AUTHORITIES: "91125",
  LOGGING_EQUIPMENT: "22800",
  LOGS_LUMBER_TIMBER_AND_WOOD: "21100",
  LOH_BARGE_62801: "62801",
  LOH_BARGE_63201: "63201",
  LOH_CHEMICAL_TANKER_62802: "62802",
  LOH_CHEMICAL_TANKER_63202: "63202",
  LOH_CONTAINER_62803: "62803",
  LOH_CONTAINER_63203: "63203",
  LOH_DREDGER_62804: "62804",
  LOH_DREDGER_63204: "63204",
  LOH_DRY_BULK_62805: "62805",
  LOH_DRY_BULK_63205: "63205",
  LOH_FACILITY_62824: "62824",
  LOH_FISH_62806: "62806",
  LOH_FISH_63206: "63206",
  LOH_FISH_FACTORY_62807: "62807",
  LOH_FISH_FACTORY_63207: "63207",
  LOH_GENERAL_CARGO_62808: "62808",
  LOH_GENERAL_CARGO_63208: "63208",
  LOH_HEAVY_LIFT_62809: "62809",
  LOH_HEAVY_LIFT_63209: "63209",
  LOH_LIVESTOCK_62810: "62810",
  LOH_LIVESTOCK_63210: "63210",
  LOH_LNG_62811: "62811",
  LOH_LNG: "63211",
  LOH_LPG_62812: "62812",
  LOH_LPG_63212: "63212",
  LOH_MISC_62813: "62813",
  LOH_PASSENGER_62814: "62814",
  LOH_PASSENGER_63214: "63214",
  LOH_PIPE_LAYER: "62815",
  LOH_PIPE_LAYER_63215: "63215",
  LOH_REEFER: "62816",
  LOH_REEFER_63216: "63216",
  LOH_RESEARCH_VESSEL_62817: "62817",
  LOH_RESEARCH_VESSEL_63217: "63217",
  LOH_RORO_62818: "62818",
  LOH_RORO_63218: "63218",
  LOH_SUPPLY_SUPPORT_62819: "62819",
  LOH_SUPPLY_SUPPORT_63219: "63219",
  LOH_TANKER_62820: "62820",
  LOH_TANKER_63220: "63220",
  LOH_TUG_62821: "62821",
  LOH_TUG_63221: "63221",
  LOH_VEHICLE_CARRIER_62822: "62822",
  LOH_VEHICLE_CARRIER_63222: "63222",
  LOH_YACHT: "62823",
  LOSS_ADJUSTERS: "90303",
  LOSS_OF_HIRE_63213: "63213",
  LOSS_OF_HIRE_79300: "79300",
  LOSS_OF_LICENCE: "99574",
  MACHINERY_PLANT_AND_PROJECT_CARGO_EX_DSU: "17400",
  MACHINE_TOOLS_MANUFACTURER: "91126",
  MALL: "99421",
  MANAGED_CARE: "99590",
  MANAGEMENT_CONSULTANTS: "90305",
  MANUAL: "99582",
  MANUFACTURED_GOODS: "19900",
  MANUFACTURES: "23809",
  MANUFACTURING: "90315",
  MANUFACTURING_HEAVY_90627: "90627",
  MANUFACTURING_LIGHT: "90617",
  MANUFACTURING_HEAVY_10016: "10016",
  MANUFACTURING_LIGHT_MEDIUM: "10017",
  MARINA: "28800",
  MARINE_CARGO: "90536",
  MARINE_CONFISCATION: "29900",
  MARINE_OTHER: "28200",
  MARITIME_EMPLOYERS_LIABILITY: "52200",
  MARSH_Q_S: "90000",
  MARTIAL_ARTS: "99540",
  MATTRESS_MANUFACTURING: "90659",
  MEAT_CHILLED: "22600",
  MEAT_FROZEN: "22500",
  MEAT_INC_REJ: "22700",
  MECHANICAL_ENGINEER: "91127",
  MEDIA_COMPANY_PUBLISHING: "99422",
  MEDIA_PUBLISHING: "90419",
  MEDICAL_MALPRACTICE_CLASH: "99019",
  MEDICAL_MALPRACTICE_RISK: "99018",
  MERCHANT_BANK: "90513",
  METALS_ALUMINIMIUM_MANUFACTURING: "90697",
  METALS_STEEL_MANUFACTURING: "90629",
  METALS_MINERALS_PROCESSING_FLOOD: "90789",
  METALS_MINERALS_PROCESSING_WIND: "90757",
  METAL_AND_METAL_GOODS: "18700",
  MIAP: "29000",
  MIDDLE_EAST_HULLS: "79600",
  MILL: "99423",
  MINES: "91128",
  MINE_CLEARERS: "99598",
  MINING_23715: "23715",
  MINING_90420: "90420",
  MINING_99424: "99424",
  MINING_ALL_OTHER_ABOVE_GROUND: "90764",
  MINING_ALL_OTHER_BELOW_GROUND: "90693",
  MINING_COAL_BELOW_GROUND: "90630",
  MINING_COPPER_ABOVE_GROUND: "90752",
  MINING_COPPER_BELOW_GROUND: "90761",
  MINING_GENERAL_MINING: "90751",
  MINING_GOLD_ABOVE_GROUND: "90783",
  MINING_GOLD_BELOW_GROUND: "90733",
  MINING_IRON_ORE_ABOVE_GROUND: "90719",
  MINING_IRON_ORE_BELOW_GROUND: "90606",
  MINING_NICKEL_ABOVE_GROUND: "90782",
  MINING_NICKEL_BELOW_GROUND: "90734",
  MINING_COAL_ABOVE_GROUND: "90820",
  MINING_DIC: "90662",
  MINING_FLOOD: "90790",
  MINING_QUAKE: "90726",
  MINING_SALT: "90794",
  MINING_WIND: "90758",
  MISC_62113: "62113",
  MISC_63113: "63113",
  MISC_91220: "91220",
  MISC_63413: "63413",
  MISCELLANEOUS: "91129",
  MISCELLANEOUS_ACCIDENT: "99004",
  MISCELLANEOUS_CONTRACTORS: "91130",
  MISCELLANEOUS_LLOYDS_BROKERS_COVERS: "23000",
  MISCELLANEOUS_MANUFACTURING: "99425",
  MISCELLANEOUS_SHORT_TAIL_DIC: "90901",
  MISCELLANEOUS_SHORT_TAIL_FLOOD: "90905",
  MISCELLANEOUS_SHORT_TAIL_QUAKE: "90903",
  MISCELLANEOUS_SHORT_TAIL_WIND: "90904",
  MISC_ACCIDENT_CLASH: "99016",
  MISC_HULLS: "74600",
  MISC_PI: "90301",
  MIXED_OCCUPANCY_URBAN: "99426",
  MOBILE_HOMES: "16604",
  MORTAGEES_INTEREST: "78400",
  MORTGAGES_INTEREST_INNOCENT_OWNERS: "63499",
  MORTGAGE_BANKER: "90508",
  MORTGAGE_BROKER: "90435",
  MOTORCYCLING: "99541",
  MOTORSPORTS: "99607",
  MOTOR_CLASH_CAT: "99017",
  MOTOR_RISK_WORKING: "99006",
  MOTOR_SPORTS: "91173",
  MOTOR_VEHICLES_AND_PARTS: "17600",
  MOTOR_VEHICLE_MAINTENANCE: "91132",
  MOTOR_VEHICLE_MANUFACTURERS: "91081",
  MOUNTAINEERING: "99542",
  MTC_GENERAL: "16600",
  MTC_TRUCKERS_GL: "90959",
  MUNICIPALITIES_90609: "90609",
  MUNICIPALITIES_99427: "99427",
  MUNICIPAL_PROPERTY: "90821",
  MUSEUM: "24106",
  MUSEUM_GALLERY: "99428",
  NAMED_PERILS: "16609",
  NASCAR: "99606",
  NEAR_AND_MIDDLE_EAST: "25800",
  NETT_ACCOUNT: "25000",
  NICHE_LIABILITY: "98100",
  NIGHT_CLUBS: "91082",
  NON_BREACH_BARGE: "62901",
  NON_BREACH_CHEMICAL_TANKER: "62902",
  NON_BREACH_CONTAINER: "62903",
  NON_BREACH_DREDGER: "62904",
  NON_BREACH_DRY_BULK: "62905",
  NON_BREACH_FACILITY: "62924",
  NON_BREACH_FISH: "62906",
  NON_BREACH_FISH_FACTORY: "62907",
  NON_BREACH_GENERAL_CARGO: "62908",
  NON_BREACH_HEAVY_LIFT: "62909",
  NON_BREACH_LIVESTOCK: "62910",
  NON_BREACH_LNG: "62911",
  NON_BREACH_LPG: "62912",
  NON_BREACH_MISC: "62913",
  NON_BREACH_PASSENGER: "62914",
  NON_BREACH_PIPE_LAYER: "62915",
  NON_BREACH_REEFER: "62916",
  NON_BREACH_RESEARCH_VESSEL: "62917",
  NON_BREACH_RORO: "62918",
  NON_BREACH_SUPPLY_SUPPORT: "62919",
  NON_BREACH_TANKER: "62920",
  NON_BREACH_TUG: "62921",
  NON_BREACH_VEHICLE_CARRIER: "62922",
  NON_BREACH_YACHT: "62923",
  NON_MANUAL: "99583",
  NORWEGIAN_HULLS: "45500",
  NURSING_HOMES: "90352",
  NUTS_PULSES_RICE_AND_SOYA: "15400",
  OCCUPATIONAL_ACCIDENTS: "99604",
  OCCURRENCE_LIABILITY: "90951",
  OFFICES: "90910",
  OFFICE_HEAD_OFFICE: "99429",
  OFFSHORE_CONSTRUCTION: "47302",
  OFFSHORE_CONTRACTORS: "47303",
  OFFSHORE_CONTROL_OF_WELL: "47202",
  OFFSHORE_MANUAL: "91136",
  OFFSHORE_MOBILS_JACK_UP_ETC: "47304",
  OFFSHORE_NON_MANUAL: "91137",
  OFFSHORE_OPERATING: "47301",
  OFFSHORE_PRODUCTS_MANUFACTURE_SUPPLY: "91138",
  OFF_SHIP: "99586",
  OILS_AND_PETRO_PRODUCTS: "19100",
  OIL_AND_GAS: "90421",
  OIL_AND_GAS_PRODUCTS: "91139",
  OIL_GAS_OR_CHEMICAL_PLANT: "99430",
  OIL_POLLUTION_ACT: "28101",
  ONSHORE_CONFISCATION: "29500",
  ONSHORE_CONSTRUCTION: "47702",
  ONSHORE_CONTROL_OF_WELL: "47201",
  ONSHORE_OPERATING: "47701",
  ON_BOARD: "99585",
  OPEN_MARKET: "62401",
  OPEN_MARKET_62701: "62701",
  ORES_AND_CONCENTRATES: "18600",
  OTHER_26600: "26600",
  OTHER_99589: "99589",
  OTHER_NON_APPEARANCE: "91016",
  OUTWARDS_RI: "60000",
  OUT_OF_DOMICLE: "99610",
  PAINTING_AND_DECORATING: "91140",
  PANAMA_HULLS: "56000",
  PAPER_AND_PULP: "19600",
  PARACHUTING: "99575",
  PARAPENDIO: "99576",
  PARASCENDING: "99577",
  PARKING_ALL_RISKS: "90612",
  PARKING_DIC: "90644",
  PARKING_FLOOD: "90772",
  PARKING_QUAKE: "90708",
  PARKING_WIND: "90740",
  PASSENGER_CRUISE: "62114",
  PATENT_AGENTS: "90306",
  PAWN_BROKER: "23716",
  PAYMENT_PROCESSOR: "96129",
  PEACE_KEEPERS: "99599",
  PENSION_PROTECTION: "99612",
  PENSION_TRUSTEES: "90204",
  PERSONAL_ACCIDENT: "95500",
  PERSONAL_ACCIDENT_CAT: "99002",
  PERSONAL_ACCIDENT_RISK: "99014",
  PERSONAL_JEWELLERY: "23805",
  PETROCHEMICALS: "91141",
  PETROLEUM_ALL_RISKS: "90631",
  PETROLEUM_DIC: "90663",
  PETROLEUM_FLOOD: "90791",
  PETROLEUM_QUAKE: "90727",
  PETROLEUM_WIND: "90759",
  PETROL_STATION: "99431",
  PHARMACEUTICAL: "90653",
  PHARMACEUTICALS: "99432",
  PHARMACEUTICALS_BIO_TECHNOLOGY: "90422",
  PHARMACEUTICALS_MANUFACTURER: "96122",
  PHARMACEUTICALS_NON_TEMPERATURE_SENSITIVE: "12800",
  PHARMACEUTICALS_TEMPERATURE_SENSITIVE: "12900",
  PHARMACEUTICAL_MANUFACTURING: "91142",
  PHYSICIANS: "90353",
  PIER_WHARVES_ETC: "24600",
  PILING_GUARANTEE: "91084",
  PIPELINES_47804: "47804",
  PIPELINES_99433: "99433",
  PIPELINE_INSPECTION: "99578",
  PI_E_O_D_O_RISK: "99007",
  PI_LIABILITY_YACHT: "63498",
  PI_E_O_D_O_CLASH: "99012",
  PLANT_HIRE_CONTRACTORS: "91143",
  PLASTICS: "13000",
  PLASTICS_MANUFACTURING: "90685",
  PLASTIC_POLYMERS_MANUFACTURERS: "91144",
  PLUMBERS: "91085",
  PLUVIOUS: "91007",
  POLITICAL_RISK: "99000",
  POLLUTION: "28100",
  POLO: "99543",
  PORT: "99434",
  PORTS_AND_DISTRIBUTION: "90824",
  PORTUGESE_HULLS: "44400",
  PORT_AUTHORITIES: "90615",
  PORT_RISKS_34000: "34000",
  PORT_RISKS_63313: "63313",
  PORT_RISKS_CHEMICAL_TANKER: "63302",
  PORT_RISKS_CONTAINER: "63303",
  PORT_RISKS_DREDGER: "63304",
  PORT_RISKS_DRY_BULK: "63305",
  PORT_RISKS_FISH: "63306",
  PORT_RISKS_FISH_FACTORY: "63307",
  PORT_RISKS_GENERAL_CARGO: "63308",
  PORT_RISKS_HEAVY_LIFT: "63309",
  PORT_RISKS_LIVESTOCK: "63310",
  PORT_RISKS_LNG: "63311",
  PORT_RISKS_LPG: "63312",
  PORT_RISKS_PASSENGER: "63314",
  PORT_RISKS_PIPE_LAYER: "63315",
  PORT_RISKS_REEFER: "63316",
  PORT_RISKS_RESEARCH_VESSEL: "63317",
  PORT_RISKS_RORO: "63318",
  PORT_RISKS_SUPPLY_SUPPORT: "63319",
  PORT_RISKS_TANKER: "63320",
  PORT_RISKS_VEHICLE_CARRIER: "63322",
  POTHOLING: "99544",
  POWERBOAT_RACING: "99545",
  POWER_DISTRIBUTION_ONLY: "90781",
  POWER_HYDROS: "90717",
  POWER_UTILITIES: "97801",
  POWER_AND_WATER: "90825",
  POWER_PLANT: "99435",
  PRECIOUS_METAL_REFINING_RECYCLING: "23705",
  PRIMARY_ARMOURED_CAR: "23701",
  PRISONS: "99436",
  PRIVACY: "90312",
  PRIVATE_BANK: "90514",
  PRIVATE_CLUB_FLYING: "99579",
  PRIVATE_COLLECTION: "24103",
  PRIVATE_FINE_ART_COLLECTION: "99437",
  PRODUCE: "16602",
  PRODUCE_AND_SUPER_MARKET_GOODS: "11100",
  PRODUCT_RECALL: "99613",
  PROFESSIONAL_FIDELITY: "90203",
  PROFESSIONAL_SERVICES: "90314",
  PROJECT_CARGO_INC_DSU: "19000",
  PROJECT_MANAGERS: "90108",
  PROPERTY_ONLY: "95200",
  PROPERTY_OWNERS: "91145",
  PROPERTY_OWNERS_DELEGATED: "94200",
  PROPERTY_OWNERS_DIRECT: "94250",
  PROPERTY_REAL_ESTATE: "90423",
  PROPERTY_RISKS: "10018",
  PROTECTION_INDEMNITY: "52000",
  PUBLIC_SERVICES_HEAVY: "10019",
  PUBLIC_SERVICES_LIGHT_TO_MEDIUM: "10020",
  PULP_PAPER: "90749",
  QUANTITY_SURVEYING: "90109",
  QUARRIES: "91146",
  RAIL_FREIGHT: "90613",
  RAIL_PASSANGER: "90677",
  RAILROAD_DIC: "90645",
  RAILROAD_FLOOD: "90773",
  RAILROAD_QUAKE: "90709",
  RAILROAD_WIND: "90741",
  RAIL_TRACK_ONLY: "90656",
  RAILWAY_CONTRACTORS: "91147",
  RAILWAY_EQUIPMENT: "20400",
  RAILWAY_OPERATORS: "91148",
  RAIL_INFRASTRUCTURE: "90796",
  RALLYING: "99546",
  REAL_ESTATE_HOTELS_BEACHFRONT: "90635",
  REAL_ESTATE_LIGHT_INDUSTRIAL: "90718",
  REAL_ESTATE_MIXED: "90667",
  REAL_ESTATE_OFFICE: "90763",
  REAL_ESTATE_RETAIL: "90699",
  REAL_ESTATE_STORAGE: "90731",
  REAL_ESTATE_HOTELS: "90911",
  RECRUITMENT_CONSULTANTS: "90309",
  REGISTRAR: "90509",
  REJECTION: "23200",
  RELIGION_NON_PROFIT_DIC: "90646",
  RELIGION_NON_PROFIT_FLOOD: "90774",
  RELIGION_NON_PROFIT_WIND: "90742",
  RELIGIOUS: "90614",
  RELIGIOUS_PROPERTY: "99438",
  REMOVALS_AND_SELF_STORAGE: "34500",
  RENTAL_LEASING: "96117",
  REPS_WARRANTIES_RISK: "99021",
  RESEARCH_SUPPLY_SUPPORT: "63419",
  RESIDENTIAL: "99439",
  RESTAURANTS: "90618",
  RESTAURANTS_DIC: "90650",
  RESTAURANTS_FLOOD: "90778",
  RESTAURANTS_QUAKE: "90714",
  RESTAURANTS_WIND: "90746",
  RESTORATION_COMPANIES: "24107",
  RETAIL_10021: "10021",
  RETAIL_23807: "23807",
  RETAIL_90619: "90619",
  RETAIL_99440: "99440",
  RETAILERS: "90424",
  RETAIL_BANK: "23717",
  RETAIL_HOSPITALITY: "90317",
  RETAIL_TRADE_DIC: "90651",
  RETAIL_TRADE_FLOOD: "90779",
  RETAIL_TRADE_QUAKE: "90715",
  RETAIL_TRADE_WIND: "90747",
  RICE: "16400",
  ROADS: "90823",
  ROAD_INFRASTRUCTURE_TOLL_BOOTH: "99441",
  ROCK_CLIMBING: "99547",
  ROOFING_CONTRACTORS: "91149",
  RORO_VEHICLE_CARRIER: "62118",
  RUBBER_MANUFACTURING: "91150",
  RUGBY: "99548",
  SAFETY_DEPOSIT_BOX: "23718",
  SALVORS: "28600",
  SATELLITES_IN_TRANSIT: "20200",
  SAVINGS_LOAN: "90510",
  SAWMILLS: "90912",
  SCAFFOLDING: "91151",
  SCHOOLS: "90605",
  SCHOOLS_ABSENCE: "99593",
  SCHOOLS_COLLEGES: "99442",
  SCRAP_METAL: "20300",
  SCRAP_METAL_MERCHANTS: "91152",
  SEA_WATER_BASED_FLOOD: "90775",
  SEA_WATER_BASED_WIND: "90743",
  SECURITY_GUARDS: "91153",
  SELF_FUNDED: "99592",
  SELLERS_INTEREST: "11500",
  SEMICONDUCTORS: "90696",
  SEMI_CONDUCTORS_MANUFACTURER: "96123",
  SHEET_METAL_WORKERS: "91086",
  SHIPOWNERS: "28500",
  SHIPPERS_PACKERS: "24108",
  SHIPS_STORES: "20800",
  SHIP_BROKERS: "90008",
  SHIP_REPAIRERS_LEGAL: "28300",
  SHOTBLASHING: "91155",
  SINGLE_FAMILY_HABITATION: "90797",
  SINGLE_PROJECT_CONSTRUCTION: "91176",
  SKIING: "99549",
  SKIP_HIRE: "91087",
  SNOOKER: "99550",
  SOLAR_PLANT: "99443",
  SOUTH_AFRICAN_HULLS: "45400",
  SOUTH_KOREAN_HULLS: "53400",
  SPANISH_HULLS: "70200",
  SPECIALTY_FINANCE_PROFESSIONAL_SERVI: "90425",
  SPECIE_AS_CARGO: "14900",
  SPECIE_FACILITY: "23722",
  SPEEDWAY: "99551",
  SPORTING_CLOTHING_GOODS_AND_EQUIPMENT: "19700",
  SPORTS: "91012",
  SPORTS_ASSOCIATIONS: "91088",
  SPORTS_CONSORTIUM_BEAZLEY: "99553",
  SPORTS_INDOOR: "91005",
  SPORTS_OUTDOOR: "91006",
  SQUASH: "99552",
  STAFF_SCHEME: "99608",
  STAMP_DEALERS: "23719",
  STATICAL_PRIZE: "91034",
  STEEL_ERECTORS: "91156",
  STEEL_FABRICATORS: "91157",
  STEEL_GOODS: "20500",
  STEVEDORES_DOCKERS: "91168",
  STEVEDORS: "28900",
  STOCKBROKERS: "23706",
  STOCK_EXCHANGE: "90431",
  STUNT_FLYING: "99580",
  SUGAR: "20600",
  SUPERMARKET: "99444",
  SUPPORT_SERVICES: "90426",
  SURVEYORS_90106: "90106",
  SURVEYORS_91089: "91089",
  SURVEYS_VALUATIONS: "90110",
  SWEDISH_HULLS: "71000",
  SWIMMING: "99554",
  SWISS_HULLS: "70000",
  TAIWAN_HULLS: "59600",
  TANKER_LNG_LPG: "62102",
  TARGET_GOODS: "16608",
  TEA: "21000",
  TECHNOLOGY: "90310",
  TELECOMMUNICATIONS_90427: "90427",
  TELECOMMUNICATIONS_91158: "91158",
  TELECOMS: "99445",
  TENDER_HOUSE_SITES: "23720",
  TENNIS: "99555",
  TERMINAL_OPERATORS: "28700",
  TERRORISM_90792: "90792",
  TERRORISM_95700: "95700",
  TEXTILES_90728: "90728",
  TEXTILES_91169: "91169",
  THIRD_PARTY_ADMINISTRATORS_HEALTHCARE: "96107",
  TOBACCO_AND_PRODUCTS: "21500",
  TOOL_MANUFACTURERS: "91090",
  TOW: "63321",
  TOWS_14800: "14800",
  TOWS_24800: "24800",
  TOW_TRUCKS: "16607",
  TRADE_NAME_RESTORATION: "91033",
  TRAINS_RAILROAD_INFRASTRUCTURE: "99446",
  TRANSPORT_90428: "90428",
  TRANSPORT_90914: "90914",
  TRAVEL: "23803",
  TREASURY_RISK: "90512",
  TRIA: "90793",
  TRIPWRAP: "90818",
  TRUCK_BROKERS: "16613",
  TRUST_COMPANY: "90434",
  TURKISH_HULLS: "59200",
  TWENTY_FOUR_HOUR_99603: "99603",
  TWENTY_FOUR_HOUR_99584: "99584",
  TYRES_AND_RUBBER: "20000",
  TYRE_AND_EXHAUST_FITTERS: "91091",
  TYRE_MANUFACTURERS: "91170",
  TYRE_MANUFACTURING: "90604",
  TYRE_RETREADERS: "91092",
  UK_AND_IRELAND: "25100",
  ULTRALIGHT: "99581",
  UMBRELLA: "24300",
  UNIVERSITIES_90307: "90307",
  UNIVERSITIES_90636: "90636",
  USA: "26100",
  USA_HULLS: "42000",
  UTILITIES_47801: "47801",
  UTILITIES_51002: "51002",
  UTILITIES_90429: "90429",
  UTILITY: "90318",
  UTILITY_CUSTOMER_SIDE: "96130",
  UTILITY_SUPPLIER_SIDE: "96119",
  U_W_AGENTS_NON_LLOYDS: "90010",
  VACATION: "99587",
  VAULT: "23703",
  VAULTS_RISKS_XS_1M: "23704",
  VEGETABLES: "15500",
  VEG_OILS_AND_ANIMAL_FATS: "19400",
  VEHICLES: "99447",
  WAREHOUSE: "99448",
  WAREHOUSES: "91159",
  WAREHOUSING_HIGH_HAZARD: "90720",
  WAREHOUSING_OTHER: "90700",
  WAREHOUSING_HEAVY: "10022",
  WAREHOUSING_LIGHT: "10023",
  WAREHOUSING_MEDIUM: "10024",
  WAR_ON_LAND: "29700",
  WASTE_DISPOSAL_CONTRACTORS: "91160",
  WASTE_PROCESSING: "90732",
  WASTE_RECYCLING_LANDFILL: "99449",
  WATER_SERVICES: "91161",
  WATER_SKIING: "99556",
  WATER_TREATMENT: "90638",
  WATER_UTILITY: "99450",
  WCA_EL_RISK: "99015",
  WCA_EL_CAT: "99009",
  WELDERS: "91162",
  WEST_INDIES_HULLS: "55600",
  WHALERS_FISHING_VESSELS: "75000",
  WHOLESALE: "23806",
  WHOLESALE_TRADE_ALL_RISKS: "90620",
  WHOLESALE_TRADE_DIC: "90652",
  WHOLESALE_TRADE_FLOOD: "90780",
  WHOLESALE_TRADE_QUAKE: "90716",
  WHOLESALE_TRADE_WIND: "90748",
  WHOLE_A_C_CASUALTY_CLASH: "99010",
  WHOLE_A_C_CASUALTY_RISK: "99013",
  WIND_FARM: "99451",
  WIND_SURFING: "99557",
  WINES_SPIRITS_AND_BEER: "22100",
  WOODWORKING: "91163",
  WOOL: "22200",
  WORKERS_COMPENSATION: "90952",
  WORKS_OF_ART: "24100",
  XL_REPORTER: "22400",
  XS_GT_100_000: "16610",
  YACHTING: "99558",
  YACHT: "62423",
  YARD_STAFF: "91172",
  YUGOSLAVIAN_HULLS: "70100",
  BOILER_MACHINERY_BREAKDOWN_ALL_RISKS: "90915",
  CEMENT_CONCRETE: "91105",
  CONF_EXHIB_INDOOR: "91001",
  CONF_EXHIB_OUTDOOR: "91002",
  CONSTRUCTION_FIRE_PERILS: "90688",
  DEATH_DISGRACE: "91015",
  EMERGENCY_FIRE_PERILS: "90670",
  ENTERTAINMENT_RECREATION_FIRE_PERILS: "90671",
  ENTERTAINMENT_RECREATION_QUAKE: "90703",
  FILMS_COMMERCIAL_SHOOTS: "91022",
  FINANCIAL_FIRE_PERILS: "90672",
  FONDEN: "90665",
  FOOD_FLOUR_GRAIN: "90655",
  FOOD_DRUGS_PROCESSING_DIC: "90657",
  FOOD_DRUGS_PROCESSING_QUAKE: "90721",
  GAS_PROCESSING_STORAGE: "90822",
  GENERAL_COMMERCIAL_DIC: "90648",
  GENERAL_COMMERCIAL_FIRE_PERILS: "90680",
  GENERAL_HOUSEHOLD_BOILER_MACHINERY_BREAKDOWN_ALL: "90917",
  GENERAL_HOUSEHOLD_FIRE_PERILS: "90852",
  GENERAL_INDUSTRIAL_FIRE_PERILS: "90690",
  GENERAL_MUNICIPLE_FIRE_PERILS: "90673",
  HEALTHCARE_FIRE_PERILS: "90674",
  HEAVY_MANUFACTURING_ASSEMBLY_FIRE_PERILS: "90691",
  HEAVY_MANUFACTURING_ASSEMBLY_QUAKE: "90723",
  HIGHWAY_FIRE_PERILS: "90675",
  HIGH_TECHNOLOGY_FIRE_PERILS: "90692",
  LIGHT_MANUFACTURING_ASSEMBLY_FIRE_PERILS: "90681",
  LIGHT_MANUFACTURING_ASSEMBLY_QUAKE: "90713",
  LIGHT_MANUFACTURING_ASSEMBLY_DIC: "90649",
  METALS_MINERALS_PROCESSING_QUAKE: "90725",
  METALS_MINERALS_PROCESSING_DIC: "90661",
  MINING_FIRE_PERILS: "90694",
  MISCELLANEOUS_SHORT_TAIL_ALL_RISKS: "90900",
  MISCELLANEOUS_SHORT_TAIL_FIRE_PERILS: "90902",
  MISC_NO_R_I: "91031",
  MISC_R_I: "91032",
  NON_STATICAL_PRIZE: "91035",
  OTHER_CANC_ABANDONMENT: "91008",
  PARKING_FIRE_PERILS: "90676",
  PETROLEUM_FIRE_PERILS: "90695",
  PILING_CONTRACTORS_EXCAVATION: "91083",
  RELIGION_NON_PROFIT_FIRE_PERILS: "90678",
  RELIGION_NON_PROFIT_QUAKE: "90710",
  RESTAURANTS_FIRE_PERILS: "90682",
  RETAIL_TRADE_FIRE_PERILS: "90683",
  SEA_WATER_BASED_QUAKE: "90711",
  TELECOMS_AND_T_D: "90799",
  TRANSMISSION_INTERRUPTION_FAILURE: "91009",
  WAREHOUSING_COLD_STORAGE_EPS: "90668",
  WATER_SEWAGE_COMPANIES: "91093",
  WHOLESALE_TRADE_FIRE_PERILS: "90684",
} as const

// Local and CI formatters are at odds with each other, hen90952ce disabling for next line.
// prettier_ignore
export type SubClassCode = (typeof SubClassCode)[keyof typeof SubClassCode]

export const SUB_CLASS_CODE_DESCRIPTION_MAPPING: Record<SubClassCode, string> =
  {
    [SubClassCode.FOOD_DRINK_HEAVY]: "10010 - Food & Drink - Heavy",
    [SubClassCode.FOOD_DRINK_LIGHT_MEDIUM]:
      "10011 - Food & Drink - Light/Medium",
    [SubClassCode.INDUSTRIAL_HEAVY]: "10012 - Industrial - Heavy",
    [SubClassCode.INDUSTRIAL_LIGHT_MEDIUM]: "10013 - Industrial - Light/Medium",
    [SubClassCode.LEISURE_HIGH_RISK]: "10014 - Leisure - High Risk",
    [SubClassCode.LEISURE_LOW_TO_MEDIUM_RISK]:
      "10015 - Leisure - Low to Medium Risk",
    [SubClassCode.MANUFACTURING_HEAVY_10016]: "10016 - Manufacturing - Heavy",
    [SubClassCode.MANUFACTURING_LIGHT_MEDIUM]:
      "10017 - Manufacturing - Light/Medium",
    [SubClassCode.PROPERTY_RISKS]: "10018 - Property Risks",
    [SubClassCode.PUBLIC_SERVICES_HEAVY]: "10019 - Public Services - Heavy",
    [SubClassCode.PUBLIC_SERVICES_LIGHT_TO_MEDIUM]:
      "10020 - Public Services - Light to Medium",
    [SubClassCode.RETAIL_10021]: "10021 - Retail",
    [SubClassCode.WAREHOUSING_HEAVY]: "10022 - Warehousing - Heavy",
    [SubClassCode.WAREHOUSING_LIGHT]: "10023 - Warehousing - Light",
    [SubClassCode.WAREHOUSING_MEDIUM]: "10024 - Warehousing - Medium",
    [SubClassCode.GENERAL_CARGO_AND_COMMODITIES]:
      "10100 - GENERAL CARGO & COMMODITIES",
    [SubClassCode.PRODUCE_AND_SUPER_MARKET_GOODS]:
      "11100 - PRODUCE & SUPER MARKET GOODS",
    [SubClassCode.COSMETICS_AND_PERFUMES]: "11200 - COSMETICS & PERFUMES",
    [SubClassCode.HOME_WARES_FURNISHINGS_AND_FURNITURE]:
      "11300 - HOME WARES, FURNISHINGS & FURNITURE",
    [SubClassCode.SELLERS_INTEREST]: "11500 - SELLERS INTEREST",
    [SubClassCode.FREIGHT_FORWARDERS]: "11600 - FREIGHT FORWARDERS",
    [SubClassCode.CONTAINERS]: "11800 - CONTAINERS",
    [SubClassCode.AIR_SENDINGS]: "11900 - AIR SENDINGS",
    [SubClassCode.ASBESTOS]: "12000 - ASBESTOS",
    [SubClassCode.AIRCRAFT_AND_SPARES]: "12100 - AIRCRAFT & SPARES",
    [SubClassCode.ARMS_AMMUNITIONS_AND_MILITARY_EQUIPMENT]:
      "12200 - ARMS, AMMUNITIONS & MILITARY EQUIPMENT",
    [SubClassCode.CANNED_GOODS]: "12400 - CANNED GOODS",
    [SubClassCode.CARPETS_AND_RUGS]: "12500 - CARPETS AND RUGS",
    [SubClassCode.CEMENT]: "12600 - CEMENT",
    [SubClassCode.CHEMICALS]: "12700 - CHEMICALS",
    [SubClassCode.PHARMACEUTICALS_NON_TEMPERATURE_SENSITIVE]:
      "12800 - PHARMACEUTICALS (NON TEMPERATURE SENSITIVE)",
    [SubClassCode.PHARMACEUTICALS_TEMPERATURE_SENSITIVE]:
      "12900 - PHARMACEUTICALS (TEMPERATURE SENSITIVE)",
    [SubClassCode.PLASTICS]: "13000 - PLASTICS",
    [SubClassCode.FERTILIZER]: "13200 - FERTILIZER",
    [SubClassCode.COAL_ROCK_AND_SAND]: "13500 - COAL, ROCK & SAND",
    [SubClassCode.COCOA]: "13600 - COCOA",
    [SubClassCode.COCOA_COFFEE_AND_TEA]: "13700 - COCOA, COFFEE & TEA",
    [SubClassCode.COPRA]: "14000 - COPRA",
    [SubClassCode.COTTON_AND_WOOL]: "14100 - COTTON & WOOL",
    [SubClassCode.DRILLING_EQUIPMENT]: "14400 - DRILLING EQUIPMENT",
    [SubClassCode.ELECTRICAL_GOODS_NOT_HI_TECH_OR_MEDICAL]:
      "14700 - ELECTRICAL GOODS (NOT HI-TECH OR MEDICAL)",
    [SubClassCode.TOWS_14800]: "14800 - TOWS",
    [SubClassCode.SPECIE_AS_CARGO]: "14900 - SPECIE AS CARGO",
    [SubClassCode.FRUIT_AND_VEGETABLES]: "15100 - FRUIT & VEGETABLES",
    [SubClassCode.FRUIT_AND_VEGETABLE_JUICE]: "15200 - FRUIT & VEGETABLE JUICE",
    [SubClassCode.FLOWERS_AND_PLANTS]: "15300 - FLOWERS & PLANTS",
    [SubClassCode.NUTS_PULSES_RICE_AND_SOYA]:
      "15400 - NUTS, PULSES, RICE & SOYA",
    [SubClassCode.VEGETABLES]: "15500 - VEGETABLES",
    [SubClassCode.FISHMEAL]: "15600 - FISHMEAL",
    [SubClassCode.CHILLED_AND_FROZEN_FOOD]: "15700 - CHILLED & FROZEN FOOD",
    [SubClassCode.FISH_ETC_GIT]: "15800 - FISH ETC - G.I.T.",
    [SubClassCode.FISH_ETC_INC_REJECTION]: "15900 - FISH ETC INC REJECTION",
    [SubClassCode.FREIGHT]: "16000 - FREIGHT",
    [SubClassCode.FLOUR]: "16100 - FLOUR",
    [SubClassCode.GRAIN_AND_FLOUR]: "16200 - GRAIN & FLOUR",
    [SubClassCode.FILMS_NEGATIVES_SOFTWARE_MUSIC_AND_GAMES]:
      "16300 - FILMS, NEGATIVES, SOFTWARE, MUSIC & GAMES",
    [SubClassCode.RICE]: "16400 - RICE",
    [SubClassCode.EXPELLERS]: "16500 - EXPELLERS",
    [SubClassCode.MTC_GENERAL]: "16600 - MTC GENERAL",
    [SubClassCode.GENERAL_FREIGHT]: "16601 - GENERAL FREIGHT",
    [SubClassCode.PRODUCE]: "16602 - PRODUCE",
    [SubClassCode.AUTOS]: "16603 - AUTOS",
    [SubClassCode.MOBILE_HOMES]: "16604 - MOBILE HOMES",
    [SubClassCode.HEAVY_MACHINERY]: "16605 - HEAVY MACHINERY",
    [SubClassCode.BOATS]: "16606 - BOATS",
    [SubClassCode.TOW_TRUCKS]: "16607 - TOW TRUCKS",
    [SubClassCode.TARGET_GOODS]: "16608 - TARGET GOODS",
    [SubClassCode.NAMED_PERILS]: "16609 - NAMED PERILS",
    [SubClassCode.XS_GT_100_000]: "16610 - XS > $100,000",
    [SubClassCode.BROKERS_LINESLIPS]: "16611 - BROKERS LINESLIPS",
    [SubClassCode.BINDING_AUTHORITIES]: "16612 - BINDING AUTHORITIES",
    [SubClassCode.TRUCK_BROKERS]: "16613 - TRUCK BROKERS",
    [SubClassCode.HIDES_SKINS_ETC]: "16800 - HIDES / SKINS ETC",
    [SubClassCode.JUTE_HESSIAN_ETC]: "16900 - JUTE / HESSIAN ETC",
    [SubClassCode.ELECTRICAL_AND_MEDICAL_HI_TECH]:
      "17100 - ELECTRICAL & MEDICAL (HI-TECH)",
    [SubClassCode.LEATHER_GOODS_FURS_HIDES_AND_SKINS]:
      "17200 - LEATHER GOODS, FURS, HIDES & SKINS",
    [SubClassCode.MACHINERY_PLANT_AND_PROJECT_CARGO_EX_DSU]:
      "17400 - MACHINERY, PLANT & PROJECT CARGO (EX DSU)",
    [SubClassCode.HEAVY_LIFT_AND_SEMI_SUB]: "17500 - HEAVY LIFT & SEMI SUB",
    [SubClassCode.MOTOR_VEHICLES_AND_PARTS]: "17600 - MOTOR VEHICLES & PARTS",
    [SubClassCode.FRAGILE_CARGO]: "17800 - FRAGILE CARGO",
    [SubClassCode.GROUNDNUTS_ETC]: "18000 - GROUNDNUTS ETC",
    [SubClassCode.DRIED_FRUITS_WITH_REJECTION]:
      "18100 - DRIED FRUITS WITH REJECTION",
    [SubClassCode.ADF_AND_T_NON_USA]: "18400 - A.D.F. & T. (NON-USA)",
    [SubClassCode.ORES_AND_CONCENTRATES]: "18600 - ORES & CONCENTRATES",
    [SubClassCode.METAL_AND_METAL_GOODS]: "18700 - METAL & METAL GOODS",
    [SubClassCode.FTC_USA]: "18800 - FTC (USA)",
    [SubClassCode.FPA]: "18900 - F.P.A.",
    [SubClassCode.PROJECT_CARGO_INC_DSU]: "19000 - PROJECT CARGO INC DSU",
    [SubClassCode.OILS_AND_PETRO_PRODUCTS]: "19100 - OILS & PETRO PRODUCTS",
    [SubClassCode.GAS]: "19200 - GAS",
    [SubClassCode.VEG_OILS_AND_ANIMAL_FATS]: "19400 - VEG OILS & ANIMAL FATS",
    [SubClassCode.PAPER_AND_PULP]: "19600 - PAPER & PULP",
    [SubClassCode.SPORTING_CLOTHING_GOODS_AND_EQUIPMENT]:
      "19700 - SPORTING CLOTHING, GOODS & EQUIPMENT",
    [SubClassCode.CLOTHING_CLOTH_AND_TEXTILES]:
      "19800 - CLOTHING, CLOTH & TEXTILES",
    [SubClassCode.MANUFACTURED_GOODS]: "19900 - MANUFACTURED GOODS",
    [SubClassCode.TYRES_AND_RUBBER]: "20000 - TYRES & RUBBER",
    [SubClassCode.SATELLITES_IN_TRANSIT]: "20200 - SATELLITES IN TRANSIT",
    [SubClassCode.SCRAP_METAL]: "20300 - SCRAP METAL",
    [SubClassCode.RAILWAY_EQUIPMENT]: "20400 - RAILWAY EQUIPMENT",
    [SubClassCode.STEEL_GOODS]: "20500 - STEEL GOODS",
    [SubClassCode.SUGAR]: "20600 - SUGAR",
    [SubClassCode.SHIPS_STORES]: "20800 - SHIPS STORES",
    [SubClassCode.TEA]: "21000 - TEA",
    [SubClassCode.LOGS_LUMBER_TIMBER_AND_WOOD]:
      "21100 - LOGS, LUMBER, TIMBER & WOOD",
    [SubClassCode.BOATS_AS_CARGO]: "21200 - BOATS AS CARGO",
    [SubClassCode.CARNET]: "21300 - CARNET",
    [SubClassCode.TOBACCO_AND_PRODUCTS]: "21500 - TOBACCO & PRODUCTS",
    [SubClassCode.BUILDING_MATERIALS_AND_DIY_GOODS]:
      "21600 - BUILDING MATERIALS & DIY GOODS",
    [SubClassCode.LEGAL_LIABILITY_TO_CARGO]: "22000 - LEGAL LIABILITY TO CARGO",
    [SubClassCode.WINES_SPIRITS_AND_BEER]: "22100 - WINES, SPIRITS & BEER",
    [SubClassCode.WOOL]: "22200 - WOOL",
    [SubClassCode.XL_REPORTER]: "22400 - XL REPORTER",
    [SubClassCode.MEAT_FROZEN]: "22500 - MEAT (FROZEN)",
    [SubClassCode.MEAT_CHILLED]: "22600 - MEAT (CHILLED)",
    [SubClassCode.MEAT_INC_REJ]: "22700 - MEAT (INC REJ)",
    [SubClassCode.LOGGING_EQUIPMENT]: "22800 - LOGGING EQUIPMENT",
    [SubClassCode.CONTRACTORS_EQUIPMENT]: "22900 - CONTRACTORS EQUIPMENT",
    [SubClassCode.MISCELLANEOUS_LLOYDS_BROKERS_COVERS]:
      "23000 - MISCELLANEOUS LLOYD'S BROKERS COVERS",
    [SubClassCode.DAIRY_PRODUCE]: "23100 - DAIRY PRODUCE",
    [SubClassCode.REJECTION]: "23200 - REJECTION",
    [SubClassCode.DOCUMENTS_OF_VALUE]: "23500 - DOCUMENTS OF VALUE",
    [SubClassCode.GENERAL_SPECIE]: "23700 - GENERAL SPECIE",
    [SubClassCode.PRIMARY_ARMOURED_CAR]: "23701 - PRIMARY ARMOURED CAR",
    [SubClassCode.EXCESS_ARMOURED_CAR_XS_1M]:
      "23702 - EXCESS ARMOURED CAR XS $1M",
    [SubClassCode.VAULT]: "23703 - Vault",
    [SubClassCode.VAULTS_RISKS_XS_1M]: "23704 - VAULTS RISKS - XS $1M",
    [SubClassCode.PRECIOUS_METAL_REFINING_RECYCLING]:
      "23705 - Precious Metal Refining/Recycling",
    [SubClassCode.STOCKBROKERS]: "23706 - STOCKBROKERS",
    [SubClassCode.ATM_ABM_Coffers]: "23707 - ATM/ABM/Coffers",
    [SubClassCode.BULLION_DEALERS]: "23708 - Bullion Dealers",
    [SubClassCode.CHEQUE_CASHING]: "23709 - Cheque Cashing",
    [SubClassCode.CURRENCY_EXCHANGE]: "23710 - Currency Exchange",
    [SubClassCode.CURRENCY_MANUFACTURER]: "23711 - Currency Manufacturer",
    [SubClassCode.DIAMOND_CUTTING]: "23712 - Diamond Cutting",
    [SubClassCode.DIAMOND_POLISHING]: "23713 - Diamond Polishing",
    [SubClassCode.EXCESS_SIPC]: "23714 - Excess SIPC",
    [SubClassCode.MINING_23715]: "23715 - Mining",
    [SubClassCode.PAWN_BROKER]: "23716 - Pawn Broker",
    [SubClassCode.RETAIL_BANK]: "23717 - Retail Bank",
    [SubClassCode.SAFETY_DEPOSIT_BOX]: "23718 - Safety Deposit Box",
    [SubClassCode.STAMP_DEALERS]: "23719 - Stamp Dealers",
    [SubClassCode.TENDER_HOUSE_SITES]: "23720 - Tender House/Sites",
    [SubClassCode.J_FORM]: "23721 - J Form",
    [SubClassCode.SPECIE_FACILITY]: "23722 - Specie Facility",
    [SubClassCode.JEWELLERS_BLOCK]: "23800 - JEWELLERS BLOCK",
    [SubClassCode.JEWELLERS_BLOCK_PRIMARY]: "23801 - JEWELLERS BLOCK PRIMARY",
    [SubClassCode.JEWELLERS_BLOCK_XS_500_000]:
      "23802 - JEWELLERS BLOCK XS $500,000",
    [SubClassCode.TRAVEL]: "23803 - TRAVEL",
    [SubClassCode.DIAMONDS_UNMOUNTED_DIAMOND_DEALERS]:
      "23804 - DIAMONDS UNMOUNTED, DIAMOND DEALERS",
    [SubClassCode.PERSONAL_JEWELLERY]: "23805 - PERSONAL JEWELLERY",
    [SubClassCode.WHOLESALE]: "23806 - Wholesale",
    [SubClassCode.RETAIL_23807]: "23807 - Retail",
    [SubClassCode.EXHIBITION]: "23808 - Exhibition",
    [SubClassCode.MANUFACTURES]: "23809 - Manufactures",
    [SubClassCode.JEWELLERS_BLOCK_FACILITY]: "23810 - Jewellers Block Facility",
    [SubClassCode.JB_PRIVATE_COLLECTION]: "23811 - JB PRIVATE COLLECTION",
    [SubClassCode.CASH_IN_TRANSIT]: "23900 - Cash in Transit",
    [SubClassCode.CASH_IN_TRANSIT_FACILITY]: "23905 - Cash in Transit Facility",
    [SubClassCode.COURIER_24000]: "24000 - Courier",
    [SubClassCode.WORKS_OF_ART]: "24100 - WORKS OF ART",
    [SubClassCode.FINE_ART_PRIMARY_OPEN_TO_PUBLIC]:
      "24101 - FINE ART PRIMARY , OPEN TO PUBLIC",
    [SubClassCode.FINE_ART_XS_1M_OPEN_TO_PUBLIC]:
      "24102 - FINE ART XS $1M , OPEN TO PUBLIC",
    [SubClassCode.PRIVATE_COLLECTION]: "24103 - Private Collection",
    [SubClassCode.GALLERY_DEALERS]: "24104 - Gallery/Dealers",
    [SubClassCode.CORPORATE_COLLECTION]: "24105 - Corporate Collection",
    [SubClassCode.MUSEUM]: "24106 - Museum",
    [SubClassCode.RESTORATION_COMPANIES]: "24107 - Restoration Companies",
    [SubClassCode.SHIPPERS_PACKERS]: "24108 - Shippers & Packers",
    [SubClassCode.COINS_STAMPS_COLLECTABLES]:
      "24109 - Coins/Stamps/Collectables",
    [SubClassCode.FINE_ARTS_FACILITY]: "24110 - Fine Arts Facility",
    [SubClassCode.ASSET_GUARD]: "24111 - ASSETGUARD",
    [SubClassCode.ART_EXHIBITIONS_AND_RELATED_TRANSITS]:
      "24200 - ART EXHIBITIONS & RELATED TRANSITS",
    [SubClassCode.UMBRELLA]: "24300 - UMBRELLA",
    [SubClassCode.BOAT_DEALERS]: "24400 - BOAT DEALERS",
    [SubClassCode.PIER_WHARVES_ETC]: "24600 - PIER WHARVES ETC",
    [SubClassCode.LAND_CONSTRUCTION]: "24700 - LAND CONSTRUCTION",
    [SubClassCode.TOWS_24800]: "24800 - TOWS",
    [SubClassCode.HULL_FOR_VOYAGE]: "24900 - HULL FOR VOYAGE",
    [SubClassCode.NETT_ACCOUNT]: "25000 - NETT ACCOUNT",
    [SubClassCode.UK_AND_IRELAND]: "25100 - UK & IRELAND",
    [SubClassCode.CARGO_DUTCH]: "25200 - CARGO (DUTCH)",
    [SubClassCode.CARGO_FRENCH]: "25300 - CARGO (FRENCH)",
    [SubClassCode.CARGO_GERMAN]: "25400 - CARGO (GERMAN)",
    [SubClassCode.CARGO_ITALIAN]: "25500 - CARGO (ITALIAN)",
    [SubClassCode.CARGO_SPANISH]: "25600 - CARGO (SPANISH)",
    [SubClassCode.EUROPEAN]: "25700 - EUROPEAN",
    [SubClassCode.NEAR_AND_MIDDLE_EAST]: "25800 - NEAR & MIDDLE EAST",
    [SubClassCode.FAR_EAST_CHINA_AND_INDIA]: "25900 - FAR EAST, CHINA & INDIA",
    [SubClassCode.JAPAN]: "26000 - JAPAN",
    [SubClassCode.USA]: "26100 - USA",
    [SubClassCode.CANADA]: "26200 - CANADA",
    [SubClassCode.C_AND_S_AMERICA]: "26300 - C & S AMERICA",
    [SubClassCode.AFRICA]: "26400 - AFRICA",
    [SubClassCode.AUSTRALIA_AND_NZ]: "26500 - AUSTRALIA & NZ",
    [SubClassCode.OTHER_26600]: "26600 - OTHER",
    [SubClassCode.CHARTERERS_ENTERED]: "27900 - CHARTERERS (Entered)",
    [SubClassCode.CHARTERERS_NON_ENTERED]: "27901 - Charterers (non-Entered)",
    [SubClassCode.POLLUTION]: "28100 - Pollution",
    [SubClassCode.OIL_POLLUTION_ACT]: "28101 - Oil Pollution Act",
    [SubClassCode.MARINE_OTHER]: "28200 - marine other",
    [SubClassCode.SHIP_REPAIRERS_LEGAL]: "28300 - SHIP REPAIRERS LEGAL",
    [SubClassCode.SHIPOWNERS]: "28500 - SHIPOWNERS",
    [SubClassCode.SALVORS]: "28600 - Salvors",
    [SubClassCode.TERMINAL_OPERATORS]: "28700 - TERMINAL OPERATORS",
    [SubClassCode.MARINA]: "28800 - MARINA",
    [SubClassCode.STEVEDORS]: "28900 - STEVEDORS",
    [SubClassCode.MIAP]: "29000 - MIAP",
    [SubClassCode.CREW_ABANDONMENT]: "29050 - Crew Abandonment",
    [SubClassCode.AVERAGE_DISBURSEMENTS]: "29100 - AVERAGE DISBURSEMENTS",
    [SubClassCode.CARGO_WAR]: "29200 - CARGO WAR",
    [SubClassCode.HULL_AND_MACHINERY_WAR]: "29300 - HULL & MACHINERY WAR",
    [SubClassCode.AVIATION_WAR_HULL]: "29400 - Aviation War Hull",
    [SubClassCode.AVIATION_WAR_LIABILITY]: "29401 - Aviation War Liability",
    [SubClassCode.ONSHORE_CONFISCATION]: "29500 - ONSHORE CONFISCATION",
    [SubClassCode.AVIATION_CONFISCATION]: "29600 - AVIATION CONFISCATION",
    [SubClassCode.WAR_ON_LAND]: "29700 - WAR ON LAND",
    [SubClassCode.MARINE_CONFISCATION]: "29900 - MARINE CONFISCATION",
    [SubClassCode.CONSTRUCTION_RISKS]: "30000 - CONSTRUCTION RISKS",
    [SubClassCode.CONVERSION_RISKS]: "30001 - CONVERSION RISKS",
    [SubClassCode.PORT_RISKS_34000]: "34000 - PORT RISKS",
    [SubClassCode.REMOVALS_AND_SELF_STORAGE]: "34500 - REMOVALS & SELF STORAGE",
    [SubClassCode.BRITISH_HULLS]: "40000 - BRITISH HULLS",
    [SubClassCode.USA_HULLS]: "42000 - USA HULLS",
    [SubClassCode.BELGIAN_HULLS]: "44200 - BELGIAN HULLS",
    [SubClassCode.PORTUGESE_HULLS]: "44400 - PORTUGESE HULLS",
    [SubClassCode.FRENCH_HULLS]: "44800 - FRENCH HULLS",
    [SubClassCode.FAR_EAST_HULLS]: "45000 - FAR EAST HULLS",
    [SubClassCode.SOUTH_AFRICAN_HULLS]: "45400 - SOUTH AFRICAN HULLS",
    [SubClassCode.NORWEGIAN_HULLS]: "45500 - NOWEGIAN HULLS",
    [SubClassCode.FPA_ABS]: "45800 - F.P.A. ABS",
    [SubClassCode.FPA_UCB]: "45900 - F.P.A. UCB",
    [SubClassCode.GERMAN_HULLS]: "46100 - GERMAN HULLS",
    [SubClassCode.CANADIAN_HULLS]: "47000 - CANADIAN HULLS",
    [SubClassCode.GENERAL_COST_OF_CONTROL]: "47200 - GENERAL COST OF CONTROL",
    [SubClassCode.ONSHORE_CONTROL_OF_WELL]: "47201 - Onshore Control of Well",
    [SubClassCode.OFFSHORE_CONTROL_OF_WELL]: "47202 - Offshore Control of Well",
    [SubClassCode.GENERAL_OFFSHORE_ENERGY]: "47300 - GENERAL OFFSHORE ENERGY",
    [SubClassCode.OFFSHORE_OPERATING]: "47301 - OFFSHORE OPERATING",
    [SubClassCode.OFFSHORE_CONSTRUCTION]: "47302 - OFFSHORE CONSTRUCTION",
    [SubClassCode.OFFSHORE_CONTRACTORS]: "47303 - OFFSHORE CONTRACTORS",
    [SubClassCode.OFFSHORE_MOBILS_JACK_UP_ETC]:
      "47304 - OFFSHORE MOBILS/JACK-UP ETC",
    [SubClassCode.FAILURE_TO_DELIVER]: "47400 - FAILURE TO DELIVER",
    [SubClassCode.GENERAL_ENERGY]: "47500 - GENERAL ENERGY",
    [SubClassCode.GENERAL_ONSHORE_ENERGY]: "47700 - GENERAL ONSHORE ENERGY",
    [SubClassCode.ONSHORE_OPERATING]: "47701 - ONSHORE OPERATING",
    [SubClassCode.ONSHORE_CONSTRUCTION]: "47702 - ONSHORE CONSTRUCTION",
    [SubClassCode.GENERAL_DOWNSTREAM_ENERGY]:
      "47800 - GENERAL DOWNSTREAM ENERGY",
    [SubClassCode.UTILITIES_47801]: "47801 - Utilities",
    [SubClassCode.PETROCHEMICAL_REFINING]: "47802 - Petrochemical / Refining",
    [SubClassCode.GAS_PLANTS]: "47803 - Gas Plants",
    [SubClassCode.PIPELINES_47804]: "47804 - Pipelines",
    [SubClassCode.HYDRO_ELECTRIC]: "47805 - Hydro Electric",
    [SubClassCode.LANDRIGS]: "47806 - Landrigs",
    [SubClassCode.ENERGY_WAR]: "47810 - ENERGY WAR",
    [SubClassCode.GENERAL_LIABILTY]: "50000 - GENERAL LIABILTY",
    [SubClassCode.ENERGY_RELATED]: "51000 - ENERGY RELATED",
    [SubClassCode.ENERGY_CONSTRUCTION]: "51001 - Energy Construction",
    [SubClassCode.UTILITIES_51002]: "51002 - Utilities",
    [SubClassCode.PROTECTION_INDEMNITY]: "52000 - PROTECTION & INDEMNITY",
    [SubClassCode.CLASS]: "52100 - class",
    [SubClassCode.MARITIME_EMPLOYERS_LIABILITY]:
      "52200 - Maritime Employers Liability",
    [SubClassCode.AUSTRALIAN_HULLS]: "52400 - AUSTRALIAN HULLS",
    [SubClassCode.INDIAN_HULLS]: "53000 - INDIAN HULLS",
    [SubClassCode.CHINESE_HULLS]: "53200 - CHINESE HULLS",
    [SubClassCode.SOUTH_KOREAN_HULLS]: "53400 - SOUTH KOREAN HULLS",
    [SubClassCode.WEST_INDIES_HULLS]: "55600 - WEST INDIES HULLS",
    [SubClassCode.PANAMA_HULLS]: "56000 - PANAMA HULLS",
    [SubClassCode.AFRICAN_HULLS]: "56200 - AFRICAN HULLS",
    [SubClassCode.CYPRUS_HULLS]: "58400 - CYPRUS HULLS",
    [SubClassCode.TURKISH_HULLS]: "59200 - TURKISH HULLS",
    [SubClassCode.TAIWAN_HULLS]: "59600 - TAIWAN HULLS",
    [SubClassCode.OUTWARDS_RI]: "60000 - OUTWARDS R/I",
    [SubClassCode.TANKER_LNG_LPG]: "62102 - Tanker / LNG / LPG",
    [SubClassCode.CONTAINER_REEFER]: "62103 - Container & Reefer",
    [SubClassCode.DRY_BULK]: "62105 - Dry Bulk",
    [SubClassCode.GENERAL_CARGO_LIVESTOCK]: "62108 - General Cargo / Livestock",
    [SubClassCode.MISC_62113]: "62113 - Misc",
    [SubClassCode.PASSENGER_CRUISE]: "62114 - Passenger / Cruise",
    [SubClassCode.RORO_VEHICLE_CARRIER]: "62118 - RoRo / Vehicle Carrier",
    [SubClassCode.FACILITY_TREATY]: "62324 - Facility / Treaty",
    [SubClassCode.OPEN_MARKET]: "62401 - Open Market",
    [SubClassCode.BREACH_CHEMICAL_TANKER]: "62402 - Breach - Chemical Tanker",
    [SubClassCode.BREACH_CONTAINER]: "62403 - Breach - Container",
    [SubClassCode.BREACH_DREDGER]: "62404 - Breach - Dredger",
    [SubClassCode.BREACH_DRY_BULK]: "62405 - Breach - Dry Bulk",
    [SubClassCode.BREACH_FISH]: "62406 - Breach - Fish",
    [SubClassCode.BREACH_FISH_FACTORY]: "62407 - Breach - Fish Factory",
    [SubClassCode.BREACH_GENERAL_CARGO]: "62408 - Breach - General Cargo",
    [SubClassCode.BREACH_HEAVY_LIFT]: "62409 - Breach - Heavy Lift",
    [SubClassCode.BREACH_LIVESTOCK]: "62410 - Breach - Livestock",
    [SubClassCode.BREACH_LNG]: "62411 - Breach - LNG",
    [SubClassCode.BREACH_LPG]: "62412 - Breach - LPG",
    [SubClassCode.BREACH_MISC]: "62413 - Breach - Misc",
    [SubClassCode.BREACH_PASSENGER]: "62414 - Breach - Passenger",
    [SubClassCode.BREACH_PIPE_LAYER]: "62415 - Breach - Pipe Layer",
    [SubClassCode.BREACH_REEFER]: "62416 - Breach - Reefer",
    [SubClassCode.BREACH_RESEARCH_VESSEL]: "62417 - Breach - Research Vessel",
    [SubClassCode.BREACH_RORO]: "62418 - Breach - RoRo",
    [SubClassCode.BREACH_SUPPLY_SUPPORT]: "62419 - Breach - Supply/Support",
    [SubClassCode.BREACH_TANKER]: "62420 - Breach - Tanker",
    [SubClassCode.BREACH_TUG]: "62421 - Breach - Tug",
    [SubClassCode.BREACH_VEHICLE_CARRIER]: "62422 - Breach - Vehicle Carrier",
    [SubClassCode.YACHT]: "62423 - Yacht",
    [SubClassCode.FACILITY_TREATY_62424]: "62424 - Facility / Treaty",
    [SubClassCode.CONSTRUCTION_OPEN_MARKET]:
      "62501 - Construction - Open Market",
    [SubClassCode.CONVERSION_OPEN_MARKET]: "62601 - Conversion Open Market",
    [SubClassCode.FACILITY_TREATY_62624]: "62624 - Facility / Treaty",
    [SubClassCode.OPEN_MARKET_62701]: "62701 - Open Market",
    [SubClassCode.FACILITY_TREATY_62724]: "62724 - Facility / Treaty",
    [SubClassCode.LOH_BARGE_62801]: "62801 - LOH - Barge",
    [SubClassCode.LOH_CHEMICAL_TANKER_62802]: "62802 - LOH - Chemical Tanker",
    [SubClassCode.LOH_CONTAINER_62803]: "62803 - LOH - Container",
    [SubClassCode.LOH_DREDGER_62804]: "62804 - LOH - Dredger",
    [SubClassCode.LOH_DRY_BULK_62805]: "62805 - LOH - Dry Bulk",
    [SubClassCode.LOH_FISH_62806]: "62806 - LOH - Fish",
    [SubClassCode.LOH_FISH_FACTORY_62807]: "62807 - LOH - Fish Factory",
    [SubClassCode.LOH_GENERAL_CARGO_62808]: "62808 - LOH - General Cargo",
    [SubClassCode.LOH_HEAVY_LIFT_62809]: "62809 - LOH - Heavy Lift",
    [SubClassCode.LOH_LIVESTOCK_62810]: "62810 - LOH - Livestock",
    [SubClassCode.LOH_LNG_62811]: "62811 - LOH - LNG",
    [SubClassCode.LOH_LPG_62812]: "62812 - LOH - LPG",
    [SubClassCode.LOH_MISC_62813]: "62813 - LOH - Misc",
    [SubClassCode.LOH_PASSENGER_62814]: "62814 - LOH - Passenger",
    [SubClassCode.LOH_PIPE_LAYER]: "62815 - LOH - Pipe Layer",
    [SubClassCode.LOH_REEFER]: "62816 - LOH - Reefer",
    [SubClassCode.LOH_RESEARCH_VESSEL_62817]: "62817 - LOH - Research Vessel",
    [SubClassCode.LOH_RORO_62818]: "62818 - LOH - RoRo",
    [SubClassCode.LOH_SUPPLY_SUPPORT_62819]: "62819 - LOH - Supply/Support",
    [SubClassCode.LOH_TANKER_62820]: "62820 - LOH - Tanker",
    [SubClassCode.LOH_TUG_62821]: "62821 - LOH - Tug",
    [SubClassCode.LOH_VEHICLE_CARRIER_62822]: "62822 - LOH - Vehicle Carrier",
    [SubClassCode.LOH_YACHT]: "62823 - LOH - Yacht",
    [SubClassCode.LOH_FACILITY_62824]: "62824 - LOH - Facility",
    [SubClassCode.NON_BREACH_BARGE]: "62901 - Non Breach - Barge",
    [SubClassCode.NON_BREACH_CHEMICAL_TANKER]:
      "62902 - Non Breach - Chemical Tanker",
    [SubClassCode.NON_BREACH_CONTAINER]: "62903 - Non Breach - Container",
    [SubClassCode.NON_BREACH_DREDGER]: "62904 - Non Breach - Dredger",
    [SubClassCode.NON_BREACH_DRY_BULK]: "62905 - Non Breach - Dry Bulk",
    [SubClassCode.NON_BREACH_FISH]: "62906 - Non Breach - Fish",
    [SubClassCode.NON_BREACH_FISH_FACTORY]: "62907 - Non Breach - Fish Factory",
    [SubClassCode.NON_BREACH_GENERAL_CARGO]:
      "62908 - Non Breach - General Cargo",
    [SubClassCode.NON_BREACH_HEAVY_LIFT]: "62909 - Non Breach - Heavy Lift",
    [SubClassCode.NON_BREACH_LIVESTOCK]: "62910 - Non Breach - Livestock",
    [SubClassCode.NON_BREACH_LNG]: "62911 - Non Breach - LNG",
    [SubClassCode.NON_BREACH_LPG]: "62912 - Non Breach - LPG",
    [SubClassCode.NON_BREACH_MISC]: "62913 - Non Breach - Misc",
    [SubClassCode.NON_BREACH_PASSENGER]: "62914 - Non Breach - Passenger",
    [SubClassCode.NON_BREACH_PIPE_LAYER]: "62915 - Non Breach - Pipe Layer",
    [SubClassCode.NON_BREACH_REEFER]: "62916 - Non Breach - Reefer",
    [SubClassCode.NON_BREACH_RESEARCH_VESSEL]:
      "62917 - Non Breach - Research Vessel",
    [SubClassCode.NON_BREACH_RORO]: "62918 - Non Breach - RoRo",
    [SubClassCode.NON_BREACH_SUPPLY_SUPPORT]:
      "62919 - Non Breach - Supply/Support",
    [SubClassCode.NON_BREACH_TANKER]: "62920 - Non Breach - Tanker",
    [SubClassCode.NON_BREACH_TUG]: "62921 - Non Breach - Tug",
    [SubClassCode.NON_BREACH_VEHICLE_CARRIER]:
      "62922 - Non Breach - Vehicle Carrier",
    [SubClassCode.NON_BREACH_YACHT]: "62923 - Non Breach - Yacht",
    [SubClassCode.NON_BREACH_FACILITY]: "62924 - Non Breach - Facility",
    [SubClassCode.MISC_63113]: "63113 - Misc",
    [SubClassCode.LOH_BARGE_63201]: "63201 - LOH - Barge",
    [SubClassCode.LOH_CHEMICAL_TANKER_63202]: "63202 - LOH - Chemical Tanker",
    [SubClassCode.LOH_CONTAINER_63203]: "63203 - LOH - Container",
    [SubClassCode.LOH_DREDGER_63204]: "63204 - LOH - Dredger",
    [SubClassCode.LOH_DRY_BULK_63205]: "63205 - LOH - Dry Bulk",
    [SubClassCode.LOH_FISH_63206]: "63206 - LOH - Fish",
    [SubClassCode.LOH_FISH_FACTORY_63207]: "63207 - LOH - Fish Factory",
    [SubClassCode.LOH_GENERAL_CARGO_63208]: "63208 - LOH - General Cargo",
    [SubClassCode.LOH_HEAVY_LIFT_63209]: "63209 - LOH - Heavy Lift",
    [SubClassCode.LOH_LIVESTOCK_63210]: "63210 - LOH - Livestock",
    [SubClassCode.LOH_LNG]: "63211 - LOH - LNG",
    [SubClassCode.LOH_LPG_63212]: "63212 - LOH - LPG",
    [SubClassCode.LOSS_OF_HIRE_63213]: "63213 - Loss Of Hire",
    [SubClassCode.LOH_PASSENGER_63214]: "63214 - LOH - Passenger",
    [SubClassCode.LOH_PIPE_LAYER_63215]: "63215 - LOH - Pipe Layer",
    [SubClassCode.LOH_REEFER_63216]: "63216 - LOH - Reefer",
    [SubClassCode.LOH_RESEARCH_VESSEL_63217]: "63217 - LOH - Research Vessel",
    [SubClassCode.LOH_RORO_63218]: "63218 - LOH - RoRo",
    [SubClassCode.LOH_SUPPLY_SUPPORT_63219]: "63219 - LOH - Supply/Support",
    [SubClassCode.LOH_TANKER_63220]: "63220 - LOH - Tanker",
    [SubClassCode.LOH_TUG_63221]: "63221 - LOH - Tug",
    [SubClassCode.LOH_VEHICLE_CARRIER_63222]: "63222 - LOH - Vehicle Carrier",
    [SubClassCode.LIMITED_CONDITIONS]: "63301 - Limited Conditions",
    [SubClassCode.PORT_RISKS_CHEMICAL_TANKER]:
      "63302 - Port Risks - Chemical Tanker",
    [SubClassCode.PORT_RISKS_CONTAINER]: "63303 - Port Risks - Container",
    [SubClassCode.PORT_RISKS_DREDGER]: "63304 - Port Risks - Dredger",
    [SubClassCode.PORT_RISKS_DRY_BULK]: "63305 - Port Risks - Dry Bulk",
    [SubClassCode.PORT_RISKS_FISH]: "63306 - Port Risks - Fish",
    [SubClassCode.PORT_RISKS_FISH_FACTORY]: "63307 - Port Risks - Fish Factory",
    [SubClassCode.PORT_RISKS_GENERAL_CARGO]:
      "63308 - Port Risks - General Cargo",
    [SubClassCode.PORT_RISKS_HEAVY_LIFT]: "63309 - Port Risks - Heavy Lift",
    [SubClassCode.PORT_RISKS_LIVESTOCK]: "63310 - Port Risks - Livestock",
    [SubClassCode.PORT_RISKS_LNG]: "63311 - Port Risks - LNG",
    [SubClassCode.PORT_RISKS_LPG]: "63312 - Port Risks - LPG",
    [SubClassCode.PORT_RISKS_63313]: "63313 - Port Risks",
    [SubClassCode.PORT_RISKS_PASSENGER]: "63314 - Port Risks - Passenger",
    [SubClassCode.PORT_RISKS_PIPE_LAYER]: "63315 - Port Risks - Pipe Layer",
    [SubClassCode.PORT_RISKS_REEFER]: "63316 - Port Risks - Reefer",
    [SubClassCode.PORT_RISKS_RESEARCH_VESSEL]:
      "63317 - Port Risks - Research Vessel",
    [SubClassCode.PORT_RISKS_RORO]: "63318 - Port Risks - RoRo",
    [SubClassCode.PORT_RISKS_SUPPLY_SUPPORT]:
      "63319 - Port Risks - Supply/Support",
    [SubClassCode.PORT_RISKS_TANKER]: "63320 - Port Risks - Tanker",
    [SubClassCode.TOW]: "63321 - Tow",
    [SubClassCode.PORT_RISKS_VEHICLE_CARRIER]:
      "63322 - Port Risks - Vehicle Carrier",
    [SubClassCode.FACILITY_TREATY_63324]: "63324 - Facility / Treaty",
    [SubClassCode.BARGE_TUG]: "63401 - Barge / Tug",
    [SubClassCode.DREDGER]: "63404 - Dredger",
    [SubClassCode.FISH_FISH_FACTORY]: "63406 - Fish / Fish Factory",
    [SubClassCode.HEAVY_LIFT_LIFT_BOATS]: "63409 - Heavy Lift / Lift Boats",
    [SubClassCode.MISC_63413]: "63413 - Misc",
    [SubClassCode.CABLE_PIPE_LAYER]: "63415 - Cable & Pipe Layer",
    [SubClassCode.RESEARCH_SUPPLY_SUPPORT]:
      "63419 - Research / Supply / Support",
    [SubClassCode.PI_LIABILITY_YACHT]: "63498 - P&I/Liability - Yacht",
    [SubClassCode.MORTGAGES_INTEREST_INNOCENT_OWNERS]:
      "63499 - Mortgages Interest / Innocent Owners",
    [SubClassCode.FACILITY_TREATY_63624]: "63624 - Facility / Treaty",
    [SubClassCode.SWISS_HULLS]: "70000 - SWISS HULLS",
    [SubClassCode.YUGOSLAVIAN_HULLS]: "70100 - YUGOSLAVIAN HULLS",
    [SubClassCode.SPANISH_HULLS]: "70200 - SPANISH HULLS",
    [SubClassCode.SWEDISH_HULLS]: "71000 - SWEDISH HULLS",
    [SubClassCode.DANISH_HULLS]: "71200 - DANISH HULLS",
    [SubClassCode.ICELANDIC_HULLS]: "71300 - ICELANDIC HULLS",
    [SubClassCode.FINNISH_HULLS]: "71400 - FINNISH HULLS",
    [SubClassCode.GREEK_HULLS]: "72200 - GREEK HULLS",
    [SubClassCode.AMERICAN_EX_NORTH_HULLS]: "74000 - AMERICAN EX NORTH HULLS",
    [SubClassCode.MISC_HULLS]: "74600 - MISC HULLS",
    [SubClassCode.DRY_DOCKS]: "74800 - DRY DOCKS",
    [SubClassCode.WHALERS_FISHING_VESSELS]: "75000 - WHALERS / FISHING VESSELS",
    [SubClassCode.JAPANESE_HULLS]: "75600 - JAPANESE HULLS",
    [SubClassCode.LAKERS]: "75800 - LAKERS",
    [SubClassCode.HULL_UK]: "76201 - HULL (UK)",
    [SubClassCode.HULL_USA]: "76202 - HULL (USA)",
    [SubClassCode.HULL_AUSTRALIA_NZ]: "76203 - HULL (AUSTRALIA & NZ)",
    [SubClassCode.HULL_AMERICA_EX_NORTH]: "76204 - HULL (AMERICA EX NORTH)",
    [SubClassCode.HULL_CANADA]: "76205 - HULL (CANADA)",
    [SubClassCode.HULL_FAR_EAST]: "76206 - HULL (FAR EAST)",
    [SubClassCode.HULL_FRANCE]: "76207 - HULL (FRANCE)",
    [SubClassCode.HULL_GERMANY]: "76208 - HULL (GERMANY)",
    [SubClassCode.HULL_ITALY]: "76209 - HULL (ITALY)",
    [SubClassCode.HULL_JAPAN]: "76210 - HULL (JAPAN)",
    [SubClassCode.HULL_MIDDLE_EAST]: "76211 - HULL (MIDDLE EAST)",
    [SubClassCode.HULL_OTHER_EUROPE]: "76212 - HULL (OTHER EUROPE)",
    [SubClassCode.HULL_SPAIN]: "76213 - HULL (SPAIN)",
    [SubClassCode.HULL_OTHER]: "76214 - HULL (OTHER)",
    [SubClassCode.HULL_SCANDINAVIA]: "76215 - HULL (SCANDINAVIA)",
    [SubClassCode.HULL_KOREA]: "76216 - HULL (KOREA)",
    [SubClassCode.HULL_PHILIPPINES]: "76217 - HULL (PHILIPPINES)",
    [SubClassCode.CROWN_AGENTS]: "76600 - CROWN AGENTS",
    [SubClassCode.EASTERN_BLOCK]: "77700 - EASTERN BLOCK",
    [SubClassCode.MORTAGEES_INTEREST]: "78400 - MORTAGEES INTEREST",
    [SubClassCode.LOSS_OF_HIRE_79300]: "79300 - LOSS OF HIRE",
    [SubClassCode.ITALIAN_HULLS]: "79400 - ITALIAN HULLS",
    [SubClassCode.CABLES]: "79500 - CABLES",
    [SubClassCode.MIDDLE_EAST_HULLS]: "79600 - MIDDLE EAST HULLS",
    [SubClassCode.LIFT_BOAT]: "79800 - Lift Boat",
    [SubClassCode.DUTCH_HULLS]: "79900 - DUTCH HULLS",
    [SubClassCode.INTERNATIONAL_MEDICAL]: "80101 - International Medical",
    [SubClassCode.MARSH_Q_S]: "90000 - MARSH Q/S",
    [SubClassCode.LAWYERS_SOLICITORS]: "90001 - LAWYERS/SOLICITORS",
    [SubClassCode.ACCOUNTANTS_CHARTERED]: "90002 - ACCOUNTANTS - CHARTERED",
    [SubClassCode.ACCOUNTANTS_OTHER]: "90003 - ACCOUNTANTS - OTHER",
    [SubClassCode.ACTUARIES]: "90004 - ACTUARIES",
    [SubClassCode.BARRISTERS]: "90005 - BARRISTERS",
    [SubClassCode.INSURANCE_BROKERS]: "90006 - INSURANCE BROKERS",
    [SubClassCode.LLOYDS_BROKERS]: "90007 - LLOYDS BROKERS",
    [SubClassCode.SHIP_BROKERS]: "90008 - SHIP BROKERS",
    [SubClassCode.U_W_AGENTS_NON_LLOYDS]: "90010 - U/W AGENTS (NON LLOYDS)",
    [SubClassCode.ARCHITECTS_ENGINEERS]: "90100 - ARCHITECTS & ENGINEERS",
    [SubClassCode.ARCHITECTS]: "90101 - ARCHITECTS",
    [SubClassCode.CONSULTING_ENGINEERS]: "90102 - CONSULTING ENGINEERS",
    [SubClassCode.CONSTRUCTION_LONG_TERM]: "90103 - CONSTRUCTION LONG TERM",
    [SubClassCode.DESIGN_CONSTRUCT]: "90104 - DESIGN & CONSTRUCT",
    [SubClassCode.ESTATE_AGENTS]: "90105 - ESTASTE AGENTS",
    [SubClassCode.SURVEYORS_90106]: "90106 - SURVEYORS",
    [SubClassCode.LOCAL_COUNCILS]: "90107 - LOCAL COUNCILS",
    [SubClassCode.PROJECT_MANAGERS]: "90108 - PROJECT MANAGERS",
    [SubClassCode.QUANTITY_SURVEYING]: "90109 - QUANTITY SURVEYING",
    [SubClassCode.SURVEYS_VALUATIONS]: "90110 - SURVEYS & VALUATIONS",
    [SubClassCode.BANKS_FI]: "90200 - BANKS / FI",
    [SubClassCode.FINANCIAL_PLANNERS]: "90201 - FINANCIAL PLANNERS",
    [SubClassCode.FUND_MANAGERS]: "90202 - FUND MANAGERS",
    [SubClassCode.PROFESSIONAL_FIDELITY]: "90203 - PROFESSIONAL FIDELITY",
    [SubClassCode.PENSION_TRUSTEES]: "90204 - PENSION & TRUSTEES",
    [SubClassCode.ASSOCIATIONS]: "90300 - ASSOCIATIONS",
    [SubClassCode.MISC_PI]: "90301 - MISC PI",
    [SubClassCode.COMPUTER_CONSULTANTS]: "90302 - COMPUTER CONSULTANTS",
    [SubClassCode.LOSS_ADJUSTERS]: "90303 - LOSS ADJUSTERS",
    [SubClassCode.ENVIRONMENTAL_CONSULTANCY]:
      "90304 - ENVIRONMENTAL CONSULTANCY",
    [SubClassCode.MANAGEMENT_CONSULTANTS]: "90305 - MANAGEMENT CONSULTANTS",
    [SubClassCode.PATENT_AGENTS]: "90306 - PATENT AGENTS",
    [SubClassCode.UNIVERSITIES_90307]: "90307 - UNIVERSITIES",
    [SubClassCode.BUSINESS_ADVISORS_INDEMNITY]:
      "90308 - BUSINESS ADVISORS INDEMNITY",
    [SubClassCode.RECRUITMENT_CONSULTANTS]: "90309 - RECRUITMENT CONSULTANTS",
    [SubClassCode.TECHNOLOGY]: "90310 - technology",
    [SubClassCode.PRIVACY]: "90312 - Privacy",
    [SubClassCode.HEALTHCARE_PROVIDER]: "90313 - HEALTHCARE PROVIDER",
    [SubClassCode.PROFESSIONAL_SERVICES]: "90314 - PROFESSIONAL SERVICES",
    [SubClassCode.MANUFACTURING]: "90315 - MANUFACTURING",
    [SubClassCode.FINANCIAL_INSTITUTIONS_90316]:
      "90316 - FINANCIAL INSTITUTIONS",
    [SubClassCode.RETAIL_HOSPITALITY]: "90317 - RETAIL / HOSPITALITY",
    [SubClassCode.UTILITY]: "90318 - UTILITY",
    [SubClassCode.HEALTHCARE_NETWORKS]: "90319 - Healthcare Networks",
    [SubClassCode.EDUCATION]: "90320 - Education",
    [SubClassCode.GENERAL_MEDICAL_MALPRACTICE]:
      "90350 - GENERAL MEDICAL MALPRACTICE",
    [SubClassCode.HOSPITALS_90351]: "90351 - HOSPITALS",
    [SubClassCode.NURSING_HOMES]: "90352 - NURSING HOMES",
    [SubClassCode.PHYSICIANS]: "90353 - PHYSICIANS",
    [SubClassCode.ALLIED_HEALTH]: "90354 - ALLIED HEALTH",
    [SubClassCode.AEROSPACE_DEFENCE]: "90400 - AEROSPACE / DEFENCE",
    [SubClassCode.AUTOMOBILES]: "90401 - AUTOMOBILES",
    [SubClassCode.BEVERAGES_BREWERIES]: "90402 - BEVERAGES / BREWERIES",
    [SubClassCode.COMPUTER_HARDWARE]: "90404 - COMPUTER HARDWARE",
    [SubClassCode.COMPUTER_SOFTWARE]: "90405 - COMPUTER SOFTWARE",
    [SubClassCode.COMPUTER_SERVICES_SUPPORT]:
      "90406 - COMPUTER SERVICES / SUPPORT",
    [SubClassCode.CONSTRUCTION_BUILDING]: "90407 - CONSTRUCTION / BUILDING",
    [SubClassCode.DISTRIBUTORS]: "90408 - DISTRIBUTORS",
    [SubClassCode.DIVERSIFIED_INDUSTRIES]: "90409 - DIVERSIFIED INDUSTRIES",
    [SubClassCode.ELECTRONIC_HARDWARE]: "90410 - ELECTRONIC HARDWARE",
    [SubClassCode.ENGINEERING_90411]: "90411 - ENGINEERING",
    [SubClassCode.FOOD_PRODUCERS]: "90412 - FOOD PRODUCERS",
    [SubClassCode.HEALTHCARE]: "90413 - HEALTHCARE",
    [SubClassCode.HOUSEHOLD_GOODS_TEXTILES]:
      "90414 - HOUSEHOLD GOODS / TEXTILES",
    [SubClassCode.INDUSTRIAL]: "90415 - INDUSTRIAL",
    [SubClassCode.INSURANCE_LIFE_ASSURANCE]:
      "90416 - INSURANCE / LIFE ASSURANCE",
    [SubClassCode.INVESTMENT_COMPANIES_ASSET_MANAGERS_EX]:
      "90417 - INVESTMENT COMPANIES / ASSET MANAGERS EX",
    [SubClassCode.LEISURE_HOTELS_ENTERTAINMENT]:
      "90418 - LEISURE / HOTELS / ENTERTAINMENT",
    [SubClassCode.MEDIA_PUBLISHING]: "90419 - MEDIA / PUBLISHING",
    [SubClassCode.MINING_90420]: "90420 - MINING",
    [SubClassCode.OIL_AND_GAS]: "90421 - OIL AND GAS",
    [SubClassCode.PHARMACEUTICALS_BIO_TECHNOLOGY]:
      "90422 - PHARMACEUTICALS / BIO-TECHNOLOGY",
    [SubClassCode.PROPERTY_REAL_ESTATE]: "90423 - PROPERTY / REAL ESTATE",
    [SubClassCode.RETAILERS]: "90424 - RETAILERS",
    [SubClassCode.SPECIALTY_FINANCE_PROFESSIONAL_SERVI]:
      "90425 - SPECIALTY / FINANCE / PROFESSIONAL SERVI",
    [SubClassCode.SUPPORT_SERVICES]: "90426 - SUPPORT SERVICES",
    [SubClassCode.TELECOMMUNICATIONS_90427]: "90427 - TELECOMMUNICATIONS",
    [SubClassCode.TRANSPORT_90428]: "90428 - TRANSPORT",
    [SubClassCode.UTILITIES_90429]: "90429 - UTILITIES",
    [SubClassCode.AGRICULTURE_90430]: "90430 - AGRICULTURE",
    [SubClassCode.STOCK_EXCHANGE]: "90431 - STOCK EXCHANGE",
    [SubClassCode.BRANCH_BANK]: "90432 - BRANCH BANK",
    [SubClassCode.GLOBAL_FINANCIAL_SERVICES]:
      "90433 - GLOBAL FINANCIAL SERVICES",
    [SubClassCode.TRUST_COMPANY]: "90434 - TRUST COMPANY",
    [SubClassCode.MORTGAGE_BROKER]: "90435 - MORTGAGE BROKER",
    [SubClassCode.COMPUTER_CENTER_PROCESSOR]:
      "90436 - COMPUTER CENTER/PROCESSOR",
    [SubClassCode.COMMERCIAL_ENTITY]: "90437 - COMMERCIAL ENTITY",
    [SubClassCode.COMMERCIAL_BANK]: "90500 - COMMERCIAL BANK",
    [SubClassCode.BUILDING_SOCIETY]: "90501 - BUILDING SOCIETY",
    [SubClassCode.CENTRAL_BANK]: "90502 - CENTRAL BANK",
    [SubClassCode.CENTRAL_DEPOSITORY]: "90503 - CENTRAL DEPOSITORY",
    [SubClassCode.INSURANCE_COMPANY]: "90506 - INSURANCE COMPANY",
    [SubClassCode.INVESTMENT_BANK]: "90507 - INVESTMENT BANK",
    [SubClassCode.MORTGAGE_BANKER]: "90508 - MORTGAGE BANKER",
    [SubClassCode.REGISTRAR]: "90509 - REGISTRAR",
    [SubClassCode.SAVINGS_LOAN]: "90510 - SAVINGS & LOAN",
    [SubClassCode.TREASURY_RISK]: "90512 - TREASURY RISK",
    [SubClassCode.MERCHANT_BANK]: "90513 - MERCHANT BANK",
    [SubClassCode.PRIVATE_BANK]: "90514 - PRIVATE BANK",
    [SubClassCode.FINANCIAL_INSTITUTIONS_90515]:
      "90515 - FINANCIAL INSTITUTIONS",
    [SubClassCode.MARINE_CARGO]: "90536 - MARINE (CARGO)",
    [SubClassCode.APARTMENTS_GARDEN_STYLE]: "90600 - APARTMENTS GARDEN STYLE",
    [SubClassCode.CONDOMINIUMS]: "90601 - CONDOMINIUMS",
    [SubClassCode.HOTELS_ALL_RISKS]: "90602 - HOTELS ALL RISKS",
    [SubClassCode.AIRPORTS]: "90603 - AIRPORTS",
    [SubClassCode.TYRE_MANUFACTURING]: "90604 - TYRE MANUFACTURING",
    [SubClassCode.SCHOOLS]: "90605 - SCHOOLS",
    [SubClassCode.MINING_IRON_ORE_BELOW_GROUND]:
      "90606 - MINING - IRON ORE BELOW GROUND",
    [SubClassCode.ENTERTAINMENT_90607]: "90607 - ENTERTAINMENT",
    [SubClassCode.FINANCIAL_ALL_RISKS]: "90608 - FINANCIAL ALL RISKS",
    [SubClassCode.MUNICIPALITIES_90609]: "90609 - MUNICIPALITIES",
    [SubClassCode.HEALTHCARE_HOSPITALS]: "90610 - HEALTHCARE - HOSPITALS",
    [SubClassCode.HIGHWAY_ALL_RISKS]: "90611 - HIGHWAY ALL RISKS",
    [SubClassCode.PARKING_ALL_RISKS]: "90612 - PARKING ALL RISKS",
    [SubClassCode.RAIL_FREIGHT]: "90613 - RAIL - FREIGHT",
    [SubClassCode.RELIGIOUS]: "90614 - RELIGIOUS",
    [SubClassCode.PORT_AUTHORITIES]: "90615 - PORT AUTHORITIES",
    [SubClassCode.GENERAL_COMMERCIAL_ALL_RISKS]:
      "90616 - GENERAL COMMERCIAL ALL RISKS",
    [SubClassCode.MANUFACTURING_LIGHT]: "90617 - MANUFACTURING - LIGHT",
    [SubClassCode.RESTAURANTS]: "90618 - RESTAURANTS",
    [SubClassCode.RETAIL_90619]: "90619 - RETAIL",
    [SubClassCode.WHOLESALE_TRADE_ALL_RISKS]:
      "90620 - WHOLESALE TRADE ALL RISKS",
    [SubClassCode.AGRICULTURE_90621]: "90621 - AGRICULTURE",
    [SubClassCode.CHEMICALS_NON_PETRO]: "90622 - CHEMICALS - NON PETRO",
    [SubClassCode.COMMUNICATIONS_AND_TELECOMS]:
      "90623 - COMMUNICATIONS AND TELECOMS",
    [SubClassCode.CONSTRUCTION_90624]: "90624 - CONSTRUCTION",
    [SubClassCode.FOOD_MEAT_PROCESSING]: "90625 - FOOD - MEAT PROCESSING",
    [SubClassCode.GENERAL_INDUSTRIAL]: "90626 - GENERAL INDUSTRIAL",
    [SubClassCode.MANUFACTURING_HEAVY_90627]: "90627 - MANUFACTURING - HEAVY",
    [SubClassCode.HIGH_TECHNOLOGY]: "90628 - HIGH TECHNOLOGY",
    [SubClassCode.METALS_STEEL_MANUFACTURING]:
      "90629 - METALS - STEEL MANUFACTURING",
    [SubClassCode.MINING_COAL_BELOW_GROUND]:
      "90630 - MINING - COAL BELOW GROUND",
    [SubClassCode.PETROLEUM_ALL_RISKS]: "90631 - PETROLEUM ALL RISKS",
    [SubClassCode.APARTMENTS_HIGH_RISE]: "90632 - APARTMENTS HIGH RISE",
    [SubClassCode.GLASS_MANUFACTURING_BOTTLING]:
      "90633 - GLASS MANUFACTURING (BOTTLING)",
    [SubClassCode.HOTELS_DIC]: "90634 - HOTELS DIC",
    [SubClassCode.REAL_ESTATE_HOTELS_BEACHFRONT]:
      "90635 - REAL ESTATE - HOTELS BEACHFRONT",
    [SubClassCode.UNIVERSITIES_90636]: "90636 - UNIVERSITIES",
    [SubClassCode.EDUCATION_DIC]: "90637 - EDUCATION DIC",
    [SubClassCode.WATER_TREATMENT]: "90638 - WATER TREATMENT",
    [SubClassCode.ENTERTAINMENT_RECREATION_DIC]:
      "90639 - ENTERTAINMENT/RECREATION DIC",
    [SubClassCode.FINANCIAL_DIC]: "90640 - FINANCIAL DIC",
    [SubClassCode.GENERAL_MUNICIPLE_DIC]: "90641 - GENERAL MUNICIPLE DIC",
    [SubClassCode.HEALTHCARE_DIC]: "90642 - HEALTHCARE DIC",
    [SubClassCode.HIGHWAY_DIC]: "90643 - HIGHWAY DIC",
    [SubClassCode.PARKING_DIC]: "90644 - PARKING DIC",
    [SubClassCode.RAILROAD_DIC]: "90645 - RAILROAD DIC",
    [SubClassCode.RELIGION_NON_PROFIT_DIC]: "90646 - RELIGION/NON-PROFIT DIC",
    [SubClassCode.FOOD_POULTRY_INC_HATCHERIES]:
      "90647 - FOOD - POULTRY INC HATCHERIES",
    [SubClassCode.GENERAL_COMMERCIAL_DIC]: "90648 - GENERAL COMMERCIAL DIC",
    [SubClassCode.LIGHT_MANUFACTURING_ASSEMBLY_DIC]:
      "90649 - LIGHT MANUFACTURING & ASSEMBLY DIC",
    [SubClassCode.RESTAURANTS_DIC]: "90650 - RESTAURANTS DIC",
    [SubClassCode.RETAIL_TRADE_DIC]: "90651 - RETAIL TRADE DIC",
    [SubClassCode.WHOLESALE_TRADE_DIC]: "90652 - WHOLESALE TRADE DIC",
    [SubClassCode.PHARMACEUTICAL]: "90653 - PHARMACEUTICAL",
    [SubClassCode.CHEMICALS_PETRO]: "90654 - CHEMICALS - PETRO",
    [SubClassCode.FOOD_FLOUR_GRAIN]: "90655 - FOOD - FLOUR / GRAIN",
    [SubClassCode.RAIL_TRACK_ONLY]: "90656 - RAIL - TRACK ONLY",
    [SubClassCode.FOOD_DRUGS_PROCESSING_DIC]:
      "90657 - FOOD & DRUGS PROCESSING DIC",
    [SubClassCode.GENERAL_INDUSTRIAL_DIC]: "90658 - GENERAL INDUSTRIAL DIC",
    [SubClassCode.MATTRESS_MANUFACTURING]: "90659 - MATTRESS MANUFACTURING",
    [SubClassCode.HIGH_TECHNOLOGY_DIC]: "90660 - HIGH TECHNOLOGY DIC",
    [SubClassCode.METALS_MINERALS_PROCESSING_DIC]:
      "90661 - METALS & MINERALS PROCESSING DIC",
    [SubClassCode.MINING_DIC]: "90662 - MINING DIC",
    [SubClassCode.PETROLEUM_DIC]: "90663 - PETROLEUM DIC",
    [SubClassCode.ASSISTED_LIVING]: "90664 - ASSISTED LIVING",
    [SubClassCode.FONDEN]: "90665 - FONDEN",
    [SubClassCode.HOTELS_OTHER]: "90666 - HOTELS - OTHER",
    [SubClassCode.REAL_ESTATE_MIXED]: "90667 - REAL ESTATE - MIXED",
    [SubClassCode.WAREHOUSING_COLD_STORAGE_EPS]:
      "90668 - WAREHOUSING - COLD STORAGE & EPS",
    [SubClassCode.ELECTRONICS_MANUFACTURING]:
      "90669 - ELECTRONICS MANUFACTURING",
    [SubClassCode.EMERGENCY_FIRE_PERILS]: "90670 - EMERGENCY FIRE & PERILS",
    [SubClassCode.ENTERTAINMENT_RECREATION_FIRE_PERILS]:
      "90671 - ENTERTAINMENT/RECREATION FIRE & PERILS",
    [SubClassCode.FINANCIAL_FIRE_PERILS]: "90672 - FINANCIAL FIRE & PERILS",
    [SubClassCode.GENERAL_MUNICIPLE_FIRE_PERILS]:
      "90673 - GENERAL MUNICIPLE FIRE & PERILS",
    [SubClassCode.HEALTHCARE_FIRE_PERILS]: "90674 - HEALTHCARE FIRE & PERILS",
    [SubClassCode.HIGHWAY_FIRE_PERILS]: "90675 - HIGHWAY FIRE & PERILS",
    [SubClassCode.PARKING_FIRE_PERILS]: "90676 - PARKING FIRE & PERILS",
    [SubClassCode.RAIL_PASSANGER]: "90677 - RAIL - PASSANGER",
    [SubClassCode.RELIGION_NON_PROFIT_FIRE_PERILS]:
      "90678 - RELIGION/NON-PROFIT FIRE & PERILS",
    [SubClassCode.FOOD_OTHER]: "90679 - FOOD - OTHER",
    [SubClassCode.GENERAL_COMMERCIAL_FIRE_PERILS]:
      "90680 - GENERAL COMMERCIAL FIRE & PERILS",
    [SubClassCode.LIGHT_MANUFACTURING_ASSEMBLY_FIRE_PERILS]:
      "90681 - LIGHT MANUFACTURING & ASSEMBLY FIRE & PERILS",
    [SubClassCode.RESTAURANTS_FIRE_PERILS]: "90682 - RESTAURANTS FIRE & PERILS",
    [SubClassCode.RETAIL_TRADE_FIRE_PERILS]:
      "90683 - RETAIL TRADE FIRE & PERILS",
    [SubClassCode.WHOLESALE_TRADE_FIRE_PERILS]:
      "90684 - WHOLESALE TRADE FIRE & PERILS",
    [SubClassCode.PLASTICS_MANUFACTURING]: "90685 - PLASTICS MANUFACTURING",
    [SubClassCode.CEMENT_MANUFACTURING]: "90686 - CEMENT MANUFACTURING",
    [SubClassCode.FOOD_SUGAR]: "90687 - FOOD - SUGAR",
    [SubClassCode.CONSTRUCTION_FIRE_PERILS]:
      "90688 - CONSTRUCTION FIRE & PERILS",
    [SubClassCode.FOOD_DAIRY]: "90689 - FOOD - DAIRY",
    [SubClassCode.GENERAL_INDUSTRIAL_FIRE_PERILS]:
      "90690 - GENERAL INDUSTRIAL FIRE & PERILS",
    [SubClassCode.HEAVY_MANUFACTURING_ASSEMBLY_FIRE_PERILS]:
      "90691 - HEAVY MANUFACTURING & ASSEMBLY FIRE & PERILS",
    [SubClassCode.HIGH_TECHNOLOGY_FIRE_PERILS]:
      "90692 - HIGH TECHNOLOGY FIRE & PERILS",
    [SubClassCode.MINING_ALL_OTHER_BELOW_GROUND]:
      "90693 - MINING - ALL OTHER BELOW GROUND",
    [SubClassCode.MINING_FIRE_PERILS]: "90694 - MINING FIRE & PERILS",
    [SubClassCode.PETROLEUM_FIRE_PERILS]: "90695 - PETROLEUM FIRE & PERILS",
    [SubClassCode.SEMICONDUCTORS]: "90696 - SEMICONDUCTORS",
    [SubClassCode.METALS_ALUMINIMIUM_MANUFACTURING]:
      "90697 - METALS - ALUMINIMIUM MANUFACTURING",
    [SubClassCode.HOTELS_QUAKE]: "90698 - HOTELS QUAKE",
    [SubClassCode.REAL_ESTATE_RETAIL]: "90699 - REAL ESTATE - RETAIL",
    [SubClassCode.WAREHOUSING_OTHER]: "90700 - WAREHOUSING - OTHER",
    [SubClassCode.EDUCATION_QUAKE]: "90701 - EDUCATION QUAKE",
    [SubClassCode.EMERGENCY_QUAKE]: "90702 - EMERGENCY QUAKE",
    [SubClassCode.ENTERTAINMENT_RECREATION_QUAKE]:
      "90703 - ENTERTAINMENT/RECREATION QUAKE",
    [SubClassCode.FINANCIAL_QUAKE]: "90704 - FINANCIAL QUAKE",
    [SubClassCode.GENERAL_MUNICIPLE_QUAKE]: "90705 - GENERAL MUNICIPLE QUAKE",
    [SubClassCode.HEALTHCARE_QUAKE]: "90706 - HEALTHCARE QUAKE",
    [SubClassCode.HIGHWAY_QUAKE]: "90707 - HIGHWAY QUAKE",
    [SubClassCode.PARKING_QUAKE]: "90708 - PARKING QUAKE",
    [SubClassCode.RAILROAD_QUAKE]: "90709 - RAILROAD QUAKE",
    [SubClassCode.RELIGION_NON_PROFIT_QUAKE]:
      "90710 - RELIGION/NON-PROFIT QUAKE",
    [SubClassCode.SEA_WATER_BASED_QUAKE]: "90711 - SEA/WATER BASED QUAKE",
    [SubClassCode.GENERAL_COMMERCIAL_QUAKE]: "90712 - GENERAL COMMERCIAL QUAKE",
    [SubClassCode.LIGHT_MANUFACTURING_ASSEMBLY_QUAKE]:
      "90713 - LIGHT MANUFACTURING & ASSEMBLY QUAKE",
    [SubClassCode.RESTAURANTS_QUAKE]: "90714 - RESTAURANTS QUAKE",
    [SubClassCode.RETAIL_TRADE_QUAKE]: "90715 - RETAIL TRADE QUAKE",
    [SubClassCode.WHOLESALE_TRADE_QUAKE]: "90716 - WHOLESALE TRADE QUAKE",
    [SubClassCode.POWER_HYDROS]: "90717 - POWER - HYDROS",
    [SubClassCode.REAL_ESTATE_LIGHT_INDUSTRIAL]:
      "90718 - REAL ESTATE - LIGHT INDUSTRIAL",
    [SubClassCode.MINING_IRON_ORE_ABOVE_GROUND]:
      "90719 - MINING - IRON ORE ABOVE GROUND",
    [SubClassCode.WAREHOUSING_HIGH_HAZARD]: "90720 - WAREHOUSING - HIGH HAZARD",
    [SubClassCode.FOOD_DRUGS_PROCESSING_QUAKE]:
      "90721 - FOOD & DRUGS PROCESSING QUAKE",
    [SubClassCode.GENERAL_INDUSTRIAL_QUAKE]: "90722 - GENERAL INDUSTRIAL QUAKE",
    [SubClassCode.HEAVY_MANUFACTURING_ASSEMBLY_QUAKE]:
      "90723 - HEAVY MANUFACTURING & ASSEMBLY QUAKE",
    [SubClassCode.HIGH_TECHNOLOGY_QUAKE]: "90724 - HIGH TECHNOLOGY QUAKE",
    [SubClassCode.METALS_MINERALS_PROCESSING_QUAKE]:
      "90725 - METALS & MINERALS PROCESSING QUAKE",
    [SubClassCode.MINING_QUAKE]: "90726 - MINING QUAKE",
    [SubClassCode.PETROLEUM_QUAKE]: "90727 - PETROLEUM QUAKE",
    [SubClassCode.TEXTILES_90728]: "90728 - TEXTILES",
    [SubClassCode.DEALERS_OPEN_LOT]: "90729 - DEALERS OPEN LOT",
    [SubClassCode.HOTELS_WIND]: "90730 - HOTELS WIND",
    [SubClassCode.REAL_ESTATE_STORAGE]: "90731 - REAL ESTATE - STORAGE",
    [SubClassCode.WASTE_PROCESSING]: "90732 - WASTE PROCESSING",
    [SubClassCode.MINING_GOLD_BELOW_GROUND]:
      "90733 - MINING - GOLD BELOW GROUND",
    [SubClassCode.MINING_NICKEL_BELOW_GROUND]:
      "90734 - MINING - NICKEL BELOW GROUND",
    [SubClassCode.ENTERTAINMENT_RECREATION_WIND]:
      "90735 - ENTERTAINMENT/RECREATION WIND",
    [SubClassCode.FINANCIAL_WIND]: "90736 - FINANCIAL WIND",
    [SubClassCode.GENERAL_MUNICIPLE_WIND]: "90737 - GENERAL MUNICIPLE WIND",
    [SubClassCode.HEALTHCARE_WIND]: "90738 - HEALTHCARE WIND",
    [SubClassCode.HIGHWAY_WIND]: "90739 - HIGHWAY WIND",
    [SubClassCode.PARKING_WIND]: "90740 - PARKING WIND",
    [SubClassCode.RAILROAD_WIND]: "90741 - RAILROAD WIND",
    [SubClassCode.RELIGION_NON_PROFIT_WIND]: "90742 - RELIGION/NON-PROFIT WIND",
    [SubClassCode.SEA_WATER_BASED_WIND]: "90743 - SEA/WATER BASED WIND",
    [SubClassCode.GENERAL_COMMERCIAL_WIND]: "90744 - GENERAL COMMERCIAL WIND",
    [SubClassCode.LIGHT_MANUFACTURING_ASSEMBLY_WIND]:
      "90745 - LIGHT MANUFACTURING & ASSEMBLY WIND",
    [SubClassCode.RESTAURANTS_WIND]: "90746 - RESTAURANTS WIND",
    [SubClassCode.RETAIL_TRADE_WIND]: "90747 - RETAIL TRADE WIND",
    [SubClassCode.WHOLESALE_TRADE_WIND]: "90748 - WHOLESALE TRADE WIND",
    [SubClassCode.PULP_PAPER]: "90749 - PULP & PAPER",
    [SubClassCode.CHEMICAL_PROCESSING_WIND]:
      "90750 - CHEMICAL PROCESSING  WIND",
    [SubClassCode.MINING_GENERAL_MINING]: "90751 - MINING - GENERAL MINING",
    [SubClassCode.MINING_COPPER_ABOVE_GROUND]:
      "90752 - MINING - COPPER ABOVE GROUND",
    [SubClassCode.FOOD_DRUGS_PROCESSING_WIND]:
      "90753 - FOOD & DRUGS PROCESSING WIND",
    [SubClassCode.GENERAL_INDUSTRIAL_WIND]: "90754 - GENERAL INDUSTRIAL WIND",
    [SubClassCode.HEAVY_MANUFACTURING_ASSEMBLY_WIND]:
      "90755 - HEAVY MANUFACTURING & ASSEMBLY WIND",
    [SubClassCode.HIGH_TECHNOLOGY_WIND]: "90756 - HIGH TECHNOLOGY WIND",
    [SubClassCode.METALS_MINERALS_PROCESSING_WIND]:
      "90757 - METALS & MINERALS PROCESSING WIND",
    [SubClassCode.MINING_WIND]: "90758 - MINING WIND",
    [SubClassCode.PETROLEUM_WIND]: "90759 - PETROLEUM WIND",
    [SubClassCode.APPARTMENTS_FLOOD]: "90760 - APPARTMENTS FLOOD",
    [SubClassCode.MINING_COPPER_BELOW_GROUND]:
      "90761 - MINING - COPPER BELOW GROUND",
    [SubClassCode.HOTELS_FLOOD]: "90762 - HOTELS FLOOD",
    [SubClassCode.REAL_ESTATE_OFFICE]: "90763 - REAL ESTATE - OFFICE",
    [SubClassCode.MINING_ALL_OTHER_ABOVE_GROUND]:
      "90764 - MINING - ALL OTHER ABOVE GROUND",
    [SubClassCode.EDUCATION_FLOOD]: "90765 - EDUCATION FLOOD",
    [SubClassCode.EMERGENCY_FLOOD]: "90766 - EMERGENCY FLOOD",
    [SubClassCode.ENTERTAINMENT_RECREATION_FLOOD]:
      "90767 - ENTERTAINMENT/RECREATION FLOOD",
    [SubClassCode.FINANCIAL_FLOOD]: "90768 - FINANCIAL FLOOD",
    [SubClassCode.GENERAL_MUNICIPLE_FLOOD]: "90769 - GENERAL MUNICIPLE FLOOD",
    [SubClassCode.HEALTHCARE_FLOOD]: "90770 - HEALTHCARE FLOOD",
    [SubClassCode.HIGHWAY_FLOOD]: "90771 - HIGHWAY FLOOD",
    [SubClassCode.PARKING_FLOOD]: "90772 - PARKING FLOOD",
    [SubClassCode.RAILROAD_FLOOD]: "90773 - RAILROAD FLOOD",
    [SubClassCode.RELIGION_NON_PROFIT_FLOOD]:
      "90774 - RELIGION/NON-PROFIT FLOOD",
    [SubClassCode.SEA_WATER_BASED_FLOOD]: "90775 - SEA/WATER BASED FLOOD",
    [SubClassCode.GENERAL_COMMERCIAL_FLOOD]: "90776 - GENERAL COMMERCIAL FLOOD",
    [SubClassCode.LIGHT_MANUFACTURING_ASSEMBLY_FLOOD]:
      "90777 - LIGHT MANUFACTURING & ASSEMBLY FLOOD",
    [SubClassCode.RESTAURANTS_FLOOD]: "90778 - RESTAURANTS FLOOD",
    [SubClassCode.RETAIL_TRADE_FLOOD]: "90779 - RETAIL TRADE FLOOD",
    [SubClassCode.WHOLESALE_TRADE_FLOOD]: "90780 - WHOLESALE TRADE FLOOD",
    [SubClassCode.POWER_DISTRIBUTION_ONLY]: "90781 - POWER - DISTRIBUTION ONLY",
    [SubClassCode.MINING_NICKEL_ABOVE_GROUND]:
      "90782 - MINING - NICKEL ABOVE GROUND",
    [SubClassCode.MINING_GOLD_ABOVE_GROUND]:
      "90783 - MINING - GOLD ABOVE GROUND",
    [SubClassCode.CONSTRUCTION_FLOOD]: "90784 - CONSTRUCTION FLOOD",
    [SubClassCode.FOOD_FISHERIES]: "90785 - FOOD - FISHERIES",
    [SubClassCode.GENERAL_INDUSTRIAL_FLOOD]: "90786 - GENERAL INDUSTRIAL FLOOD",
    [SubClassCode.HEAVY_MANUFACTURING_ASSEMBLY_FLOOD]:
      "90787 - HEAVY MANUFACTURING & ASSEMBLY FLOOD",
    [SubClassCode.HIGH_TECHNOLOGY_FLOOD]: "90788 - HIGH TECHNOLOGY FLOOD",
    [SubClassCode.METALS_MINERALS_PROCESSING_FLOOD]:
      "90789 - METALS & MINERALS PROCESSING FLOOD",
    [SubClassCode.MINING_FLOOD]: "90790 - MINING FLOOD",
    [SubClassCode.PETROLEUM_FLOOD]: "90791 - PETROLEUM FLOOD",
    [SubClassCode.TERRORISM_90792]: "90792 - TERRORISM",
    [SubClassCode.TRIA]: "90793 - T.R.I.A.",
    [SubClassCode.MINING_SALT]: "90794 - MINING - SALT",
    [SubClassCode.BANKS]: "90795 - BANKS",
    [SubClassCode.RAIL_INFRASTRUCTURE]: "90796 - RAIL - INFASTRUCTURE",
    [SubClassCode.SINGLE_FAMILY_HABITATION]: "90797 - SINGLE FAMILY HABITATION",
    [SubClassCode.HOTELS_BEACHFRONT]: "90798 - HOTELS - BEACHFRONT",
    [SubClassCode.TELECOMS_AND_T_D]: "90799 - TELECOMS AND T&D",
    [SubClassCode.TRIPWRAP]: "90818 - TRIPWRAP",
    [SubClassCode.CYBER_TERRORISM]: "90819 - CYBER TERRORISM",
    [SubClassCode.MINING_COAL_ABOVE_GROUND]:
      "90820 - MINING - COAL ABOVE GROUND",
    [SubClassCode.MUNICIPAL_PROPERTY]: "90821 - Municipal Property",
    [SubClassCode.GAS_PROCESSING_STORAGE]: "90822 - GAS PROCESSING & STORAGE",
    [SubClassCode.ROADS]: "90823 - ROADS",
    [SubClassCode.PORTS_AND_DISTRIBUTION]: "90824 - Ports and Distribution",
    [SubClassCode.POWER_AND_WATER]: "90825 - Power and Water",
    [SubClassCode.GENERAL_HOUSEHOLD_ALL_RISKS]:
      "90850 - GENERAL HOUSEHOLD ALL RISKS",
    [SubClassCode.GENERAL_HOUSEHOLD_DIC]: "90851 - GENERAL HOUSEHOLD DIC",
    [SubClassCode.GENERAL_HOUSEHOLD_FIRE_PERILS]:
      "90852 - GENERAL HOUSEHOLD FIRE & PERILS",
    [SubClassCode.GENERAL_HOUSEHOLD_QUAKE]: "90853 - GENERAL HOUSEHOLD QUAKE",
    [SubClassCode.GENERAL_HOUSEHOLD_WIND]: "90854 - GENERAL HOUSEHOLD WIND",
    [SubClassCode.GENERAL_HOUSEHOLD_FLOOD]: "90855 - GENERAL HOUSEHOLD FLOOD",
    [SubClassCode.COLUMBIA_NON_BUREAU]: "90880 - COLUMBIA NON BUREAU",
    [SubClassCode.LLOYDS_CHINA_TERRORISM]: "90887 - Lloyd's China Terrorism",
    [SubClassCode.MISCELLANEOUS_SHORT_TAIL_ALL_RISKS]:
      "90900 - MISCELLANEOUS SHORT TAIL ALL RISKS",
    [SubClassCode.MISCELLANEOUS_SHORT_TAIL_DIC]:
      "90901 - MISCELLANEOUS SHORT TAIL DIC",
    [SubClassCode.MISCELLANEOUS_SHORT_TAIL_FIRE_PERILS]:
      "90902 - MISCELLANEOUS SHORT TAIL FIRE & PERILS",
    [SubClassCode.MISCELLANEOUS_SHORT_TAIL_QUAKE]:
      "90903 - MISCELLANEOUS SHORT TAIL QUAKE",
    [SubClassCode.MISCELLANEOUS_SHORT_TAIL_WIND]:
      "90904 - MISCELLANEOUS SHORT TAIL WIND",
    [SubClassCode.MISCELLANEOUS_SHORT_TAIL_FLOOD]:
      "90905 - MISCELLANEOUS SHORT TAIL FLOOD",
    [SubClassCode.OFFICES]: "90910 - OFFICES",
    [SubClassCode.REAL_ESTATE_HOTELS]: "90911 - REAL ESTATE – HOTELS",
    [SubClassCode.SAWMILLS]: "90912 - SAWMILLS",
    [SubClassCode.BRIDGES]: "90913 - BRIDGES",
    [SubClassCode.TRANSPORT_90914]: "90914 - TRANSPORT",
    [SubClassCode.BOILER_MACHINERY_BREAKDOWN_ALL_RISKS]:
      "90915 - BOILER & MACHINERY BREAKDOWN ALL RISKS",
    [SubClassCode.GENERAL_COMMERCIAL_ALL_RISKS_90916]:
      "90916 - GENERAL COMMERCIAL ALL RISKS",
    [SubClassCode.GENERAL_HOUSEHOLD_BOILER_MACHINERY_BREAKDOWN_ALL]:
      "90917 - GENERAL HOUSEHOLD BOILER & MACHINERY BREAKDOWN ALL",
    [SubClassCode.CLAIMS_MADE_LIABILITY]: "90950 - CLAIMS MADE LIABILITY",
    [SubClassCode.OCCURRENCE_LIABILITY]: "90951 - OCCURRENCE LIABILITY",
    [SubClassCode.WORKERS_COMPENSATION]: "90952 - WORKERS COMPENSATION",
    [SubClassCode.GENERAL_LIABILITY]: "90953 - GENERAL LIABILITY",
    [SubClassCode.CONTRACTS_LIABILITY]: "90958 - Contracts Liability",
    [SubClassCode.MTC_TRUCKERS_GL]: "90959 - MTC Truckers GL",
    [SubClassCode.AUTO_LIABILITY]: "90960 - Auto Liability",
    [SubClassCode.FINANCIAL_LIABILITY]: "90971 - FINANCIAL LIABILITY",
    [SubClassCode.CONF_EXHIB_INDOOR]: "91001 - CONF/EXHIB INDOOR",
    [SubClassCode.CONF_EXHIB_OUTDOOR]: "91002 - CONF/EXHIB OUTDOOR",
    [SubClassCode.EVENT_OTHER_INDOOR]: "91003 - EVENT OTHER INDOOR",
    [SubClassCode.EVENT_OTHER_OUTDOOR]: "91004 - EVENT OTHER OUTDOOR",
    [SubClassCode.SPORTS_INDOOR]: "91005 - SPOOTS INDOOR",
    [SubClassCode.SPORTS_OUTDOOR]: "91006 - SPORTS OUTDOOR",
    [SubClassCode.PLUVIOUS]: "91007 - PLUVIOUS",
    [SubClassCode.OTHER_CANC_ABANDONMENT]: "91008 - OTHER CANC/ABANDONMENT",
    [SubClassCode.TRANSMISSION_INTERRUPTION_FAILURE]:
      "91009 - TRANSMISSION INTERRUPTION/FAILURE",
    [SubClassCode.COMMERCIAL_WEATHER_DAY]: "91010 - COMMERCIAL WEATHER DAY",
    [SubClassCode.ENTERTAINMENT_91011]: "91011 - ENTERTAINMENT",
    [SubClassCode.SPORTS]: "91012 - SPORTS",
    [SubClassCode.CONFIDENTIAL_LIFE]: "91013 - CONFIDENTIAL LIFE",
    [SubClassCode.CONTRACT_PROTECTION_INSURANCE]:
      "91014 - Contract Protection Insurance",
    [SubClassCode.DEATH_DISGRACE]: "91015 - DEATH & DISGRACE",
    [SubClassCode.OTHER_NON_APPEARANCE]: "91016 - OTHER NON APPEARANCE",
    [SubClassCode.FILM_EX_COMP_BONDS]: "91021 - FILMS (EX COMP BONDS)",
    [SubClassCode.FILMS_COMMERCIAL_SHOOTS]: "91022 - FILMS/COMMERCIAL SHOOTS",
    [SubClassCode.MISC_NO_R_I]: "91031 - MISC - NO R/I",
    [SubClassCode.MISC_R_I]: "91032 - MISC - R/I",
    [SubClassCode.TRADE_NAME_RESTORATION]: "91033 - Trade Name Restoration",
    [SubClassCode.STATICAL_PRIZE]: "91034 - Statical Prize",
    [SubClassCode.NON_STATICAL_PRIZE]: "91035 - Non-Statical Prize",
    [SubClassCode.LEISURE_CENTRES_SPORTS_CENTRES_HEALTH_CLUBS]:
      "91080 - LEISURE CENTRES, SPORTS CENTRES & HEALTH CLUBS",
    [SubClassCode.MOTOR_VEHICLE_MANUFACTURERS]:
      "91081 - MOTOR VEHICLE - MANUFACTURERS",
    [SubClassCode.NIGHT_CLUBS]: "91082 - NIGHT CLUBS",
    [SubClassCode.PILING_CONTRACTORS_EXCAVATION]:
      "91083 - PILING CONTRACTORS / EXCAVATION",
    [SubClassCode.PILING_GUARANTEE]: "91084 - PILING GUARANTEE",
    [SubClassCode.PLUMBERS]: "91085 - PLUMBERS",
    [SubClassCode.SHEET_METAL_WORKERS]: "91086 - SHEET METAL WORKERS",
    [SubClassCode.SKIP_HIRE]: "91087 - SKIP HIRE",
    [SubClassCode.SPORTS_ASSOCIATIONS]: "91088 - SPORTS ASSOCIATIONS",
    [SubClassCode.SURVEYORS_91089]: "91089 - SURVEYORS",
    [SubClassCode.TOOL_MANUFACTURERS]: "91090 - TOOL MANUFACTURERS",
    [SubClassCode.TYRE_AND_EXHAUST_FITTERS]: "91091 - TYRE AND EXHAUST FITTERS",
    [SubClassCode.TYRE_RETREADERS]: "91092 - TYRE RETREADERS",
    [SubClassCode.WATER_SEWAGE_COMPANIES]: "91093 - WATER / SEWAGE COMPANIES",
    [SubClassCode.AIRCREW]: "91100 - AIRCREW",
    [SubClassCode.ALARM_INSTALLERS]: "91101 - ALARM INSTALLERS",
    [SubClassCode.ANIMAL_FEEDS]: "91102 - ANIMAL FEEDS",
    [SubClassCode.ASBESTOS_REMOVAL_CONTRACTORS]:
      "91103 - ASBESTOS REMOVAL CONTRACTORS",
    [SubClassCode.BUILDERS]: "91104 - BUILDERS",
    [SubClassCode.CEMENT_CONCRETE]: "91105 - CEMENT / CONCRETE",
    [SubClassCode.CHEMICAL_MISC]: "91106 - CHEMICAL MISC",
    [SubClassCode.CIVIL_ENGINEERS]: "91107 - CIVIL ENGINEERS",
    [SubClassCode.CLEANERS_INDUSTRIAL]: "91108 - CLEANERS INDUSTRIAL",
    [SubClassCode.CHEMICAL_TRAILS]: "91109 - CHEMICAL TRAILS",
    [SubClassCode.DEMOLISION]: "91110 - DEMOLISION",
    [SubClassCode.DIVING]: "91111 - DIVING",
    [SubClassCode.ELECTRICAL_COMPONENT_MANUFACTURER]:
      "91113 - ELECTRICAL COMPONENT MANUFACTURER",
    [SubClassCode.ELECTRICAL_UTILITIES]: "91114 - ELECTRICAL UTILITIES",
    [SubClassCode.ENGINEER]: "91115 - ENGINEER",
    [SubClassCode.EXPLOSIVE_MANUFACTURER]: "91116 - EXPLOSIVE MANUFACTURER",
    [SubClassCode.FERTILIZER_AND_AGRO_CHEMICALS]:
      "91117 - FERTILIZER AND AGRO CHEMICALS",
    [SubClassCode.FOOD_AND_DRINK]: "91118 - FOOD AND DRINK",
    [SubClassCode.GAS_MANUFACTURERS]: "91119 - GAS MANUFACTURERS",
    [SubClassCode.GAS_UTILITIES]: "91120 - GAS UTILITIES",
    [SubClassCode.HAULAGE_CONTRACTORS]: "91121 - HAULAGE CONTRACTORS",
    [SubClassCode.HEATING_AND_VENTILATING_CONTRACTORS]:
      "91122 - HEATING AND VENTILATING CONTRACTORS",
    [SubClassCode.HOSPITALS_91123]: "91123 - HOSPITALS",
    [SubClassCode.HOTEL_AND_RESTAURANT]: "91124 - HOTEL AND RESTAURANT",
    [SubClassCode.LOCAL_MUNICIPAL_AUTHORITIES]:
      "91125 - LOCAL MUNICIPAL AUTHORITIES",
    [SubClassCode.MACHINE_TOOLS_MANUFACTURER]:
      "91126 - MACHINE TOOLS MANUFACTURER",
    [SubClassCode.MECHANICAL_ENGINEER]: "91127 - MECHANICAL ENGINEER",
    [SubClassCode.MINES]: "91128 - MINES",
    [SubClassCode.MISCELLANEOUS]: "91129 - MISCELLANEOUS",
    [SubClassCode.MISCELLANEOUS_CONTRACTORS]:
      "91130 - MISCELLANEOUS CONTRACTORS",
    [SubClassCode.MOTOR_VEHICLE_MAINTENANCE]:
      "91132 - MOTOR VEHICLE MAINTENANCE",
    [SubClassCode.OFFSHORE_MANUAL]: "91136 - OFFSHORE MANUAL",
    [SubClassCode.OFFSHORE_NON_MANUAL]: "91137 - OFFSHORE NON-MANUAL",
    [SubClassCode.OFFSHORE_PRODUCTS_MANUFACTURE_SUPPLY]:
      "91138 - OFFSHORE PRODUCTS MANUFACTURE/SUPPLY",
    [SubClassCode.OIL_AND_GAS_PRODUCTS]: "91139 - OIL AND GAS PRODUCTS",
    [SubClassCode.PAINTING_AND_DECORATING]: "91140 - PAINTING AND DECORATING",
    [SubClassCode.PETROCHEMICALS]: "91141 - PETROCHEMICALS",
    [SubClassCode.PHARMACEUTICAL_MANUFACTURING]:
      "91142 - PHARMACUTICAL MANUFACTURING",
    [SubClassCode.PLANT_HIRE_CONTRACTORS]: "91143 - PLANT HIRE CONTRACTORS",
    [SubClassCode.PLASTIC_POLYMERS_MANUFACTURERS]:
      "91144 - PLASTIC / POLYMERS MANUFACTURERS",
    [SubClassCode.PROPERTY_OWNERS]: "91145 - PROPERTY OWNERS",
    [SubClassCode.QUARRIES]: "91146 - QUARRIES",
    [SubClassCode.RAILWAY_CONTRACTORS]: "91147 - RAILWAY CONTRACTORS",
    [SubClassCode.RAILWAY_OPERATORS]: "91148 - RAILWAY OPERATORS",
    [SubClassCode.ROOFING_CONTRACTORS]: "91149 - ROOFING CONTRACTORS",
    [SubClassCode.RUBBER_MANUFACTURING]: "91150 - RUBBER MANUFACTURING",
    [SubClassCode.SCAFFOLDING]: "91151 - SCAFFOLDING",
    [SubClassCode.SCRAP_METAL_MERCHANTS]: "91152 - SCRAP METAL MERCHANTS",
    [SubClassCode.SECURITY_GUARDS]: "91153 - SECURITY GUARDS",
    [SubClassCode.SHOTBLASHING]: "91155 - SHOTBLASHING",
    [SubClassCode.STEEL_ERECTORS]: "91156 - STEEL ERECTORS",
    [SubClassCode.STEEL_FABRICATORS]: "91157 - STEEL FABRICATORS",
    [SubClassCode.TELECOMMUNICATIONS_91158]: "91158 - TELECOMMUNICATIONS",
    [SubClassCode.WAREHOUSES]: "91159 - WAREHOUSES",
    [SubClassCode.WASTE_DISPOSAL_CONTRACTORS]:
      "91160 - WASTE DISPOSAL CONTRACTORS",
    [SubClassCode.WATER_SERVICES]: "91161 - WATER SERVICES",
    [SubClassCode.WELDERS]: "91162 - WELDERS",
    [SubClassCode.WOODWORKING]: "91163 - WOODWORKING",
    [SubClassCode.FOUNDRY]: "91165 - FOUNDRY",
    [SubClassCode.GLASS_MANUFACTURERS]: "91166 - GLASS MANUFRACTURERS",
    [SubClassCode.STEVEDORES_DOCKERS]: "91168 - STEVEDORES & DOCKERS",
    [SubClassCode.TEXTILES_91169]: "91169 - TEXTILES",
    [SubClassCode.TYRE_MANUFACTURERS]: "91170 - TYRE MANUFACTURERS",
    [SubClassCode.YARD_STAFF]: "91172 - YARD STAFF",
    [SubClassCode.MOTOR_SPORTS]: "91173 - Motor Sports",
    [SubClassCode.FIREWORKS]: "91175 - Fireworks",
    [SubClassCode.SINGLE_PROJECT_CONSTRUCTION]:
      "91176 - Single Project Construction",
    [SubClassCode.ANNUAL_CONSTRUCTION]: "91177 - Annual Construction",
    [SubClassCode.ENVIRONMENTAL_LIABILITY]: "91178 - Environmental Liability",
    [SubClassCode.ACTIVITY_CENTRES_SKI_RESORTS_RIDING_SCHOOLS_ETC]:
      "91179 - ACTIVITY CENTRES (SKI RESORTS, RIDING SCHOOLS ETC.)",
    [SubClassCode.AIRLINE_AIRPORTS_ALL_NON_FLYING_ACTIVITIES_INCL_CATERING]:
      "91180 - AIRLINE/AIRPORTS - ALL NON-FLYING ACTIVITIES (INCL. CATERING)",
    [SubClassCode.ARCHITECTS_AND_ENGINEERS]: "91181 - ARCHITECTS AND ENGINEERS",
    [SubClassCode.ART_GALLERIES]: "91182 - ART GALLERIES",
    [SubClassCode.ASBESTOS_SURVEYING]: "91183 - ASBESTOS SURVEYING",
    [SubClassCode.BALLOON_OPERATORS]: "91184 - BALLOON OPERATORS",
    [SubClassCode.CHARITY]: "91185 - CHARITY",
    [SubClassCode.CIGAR_AND_CIGARETTE_MANUFACTURERS]:
      "91186 - CIGAR AND CIGARETTE - MANUFACTURERS",
    [SubClassCode.CLINICAL_TRIALS]: "91187 - CLINICAL TRIALS",
    [SubClassCode.CRANE_HIRERS]: "91188 - CRANE HIRERS",
    [SubClassCode.DRILLING_CONTRACTORS]: "91189 - DRILLING CONTRACTORS",
    [SubClassCode.ELECTRICAL]: "91190 - ELECTRICAL",
    [SubClassCode.ENVIRONMENTAL_CONSULTANTS]:
      "91191 - ENVIRONMENTAL CONSULTANTS",
    [SubClassCode.FOOTBALL_CLUBS_STANDS_AND_PAVILIONS]:
      "91192 - FOOTBALL CLUBS, STANDS AND PAVILIONS",
    [SubClassCode.FORESTRY]: "91193 - FORESTRY",
    [SubClassCode.GROUNDWORK_CONTRACTORS]: "91194 - GROUNDWORK CONTRACTORS",
    [SubClassCode.HOIST_AND_LIFT_MANUFACTURERS]:
      "91195 - HOIST AND LIFT MANUFACTURERS",
    [SubClassCode.HOUSING_ASSOCIATIONS]: "91196 - HOUSING ASSOCIATIONS",
    [SubClassCode.ICE_SKATING_RINKS]: "91197 - ICE SKATING RINKS",
    [SubClassCode.LABOUR_SUPPLY_COMPANIES]: "91198 - LABOUR SUPPLY COMPANIES",
    [SubClassCode.LANDSCAPE_GARDENING]: "91199 - LANDSCAPE GARDENING",
    [SubClassCode.MISC_91220]: "91220 - MISC",
    [SubClassCode.COMMERCIAL_COMBINED_DELEGATED]:
      "94100 - Commercial Combined Delegated",
    [SubClassCode.COMMERCIAL_COMBINED_DIRECT]:
      "94150 - Commercial Combined Direct",
    [SubClassCode.PROPERTY_OWNERS_DELEGATED]:
      "94200 - Property Owners Delegated",
    [SubClassCode.PROPERTY_OWNERS_DIRECT]: "94250 - Property Owners Direct",
    [SubClassCode.HIGH_VALUE_HOMEOWNER_DELEGATED]:
      "94300 - High Value Homeowner Delegated",
    [SubClassCode.HIGH_VALUE_HOMEOWNER_DIRECT]:
      "94350 - High Value Homeowner Direct",
    [SubClassCode.COMBINED_PROPERTY_PL_EL]:
      "95100 - COMBINED PROPERTY, PL & EL",
    [SubClassCode.PROPERTY_ONLY]: "95200 - PROPERTY ONLY",
    [SubClassCode.EMPLOYERS_LIABILITY_UK_COMBINED]:
      "95300 - EMPLOYERS LIABILITY UK COMBINED",
    [SubClassCode.PERSONAL_ACCIDENT]: "95500 - PERSONAL ACCIDENT",
    [SubClassCode.ENGINEERING_95600]: "95600 - ENGINEERING",
    [SubClassCode.TERRORISM_95700]: "95700 - TERRORISM",
    [SubClassCode.D_O]: "95900 - D&O",
    [SubClassCode.BUSINESS_FINANCE_BANKING]:
      "96101 - BUSINESS FINANCE / BANKING",
    [SubClassCode.CONSUMER_FINANCE_BANKING]:
      "96102 - CONSUMER FINANCE / BANKING",
    [SubClassCode.BROKER_DEALER_FUND_MANAGER_COMMERCIAL_MIXED]:
      "96103 - BROKER/DEALER/FUND MANAGER - COMMERCIAL & MIXED",
    [SubClassCode.INSURANCE_COMMERCIAL]: "96104 - INSURANCE - COMMERCIAL",
    [SubClassCode.INSURANCE_PERSONAL]: "96105 - INSURANCE - PERSONAL",
    [SubClassCode.INSURANCE_HEALTH]: "96106 - INSURANCE - HEALTH",
    [SubClassCode.THIRD_PARTY_ADMINISTRATORS_HEALTHCARE]:
      "96107 - THIRD PARTY ADMINISTRATORS - HEALTHCARE",
    [SubClassCode.ACCOUNTANTS_TAX_CONSUMER]:
      "96108 - ACCOUNTANTS / TAX - CONSUMER",
    [SubClassCode.INSURANCE_BROKERS_CONSUMER]:
      "96109 - INSURANCE BROKERS - CONSUMER",
    [SubClassCode.LAWYERS_CONSUMER]: "96110 - LAWYERS - CONSUMER",
    [SubClassCode.AUTOMOTIVE_RETAIL]: "96111 - AUTOMOTIVE RETAIL",
    [SubClassCode.CLOTHING_RETAIL]: "96112 - CLOTHING RETAIL",
    [SubClassCode.COMPUTERS_CONSUMER_ELECTRONICS]:
      "96113 - COMPUTERS & CONSUMER ELECTRONICS",
    [SubClassCode.BEAUTY_AND_COSMETICS_RETAIL]:
      "96114 - BEAUTY AND COSMETICS RETAIL",
    [SubClassCode.HOME_RETAIL]: "96115 - HOME RETAIL",
    [SubClassCode.HOSPITALITY_MANAGEMENT]: "96116 - HOSPITALITY MANAGEMENT",
    [SubClassCode.RENTAL_LEASING]: "96117 - RENTAL & LEASING",
    [SubClassCode.FOOD_DRINK_RETAIL]: "96118 - FOOD / DRINK RETAIL",
    [SubClassCode.UTILITY_SUPPLIER_SIDE]: "96119 - UTILITY - SUPPLIER SIDE",
    [SubClassCode.ENERGY_SUPPLIER_SIDE]: "96120 - ENERGY - SUPPLIER SIDE",
    [SubClassCode.FOOD_DRINK_MANUFACTURER]: "96121 - FOOD / DRINK MANUFACTURER",
    [SubClassCode.PHARMACEUTICALS_MANUFACTURER]:
      "96122 - PHARMACEUTICALS MANUFACTURER",
    [SubClassCode.SEMI_CONDUCTORS_MANUFACTURER]:
      "96123 - SEMI-CONDUCTORS MANUFACTURER",
    [SubClassCode.GLASS_METALS_MANUFACTURER]:
      "96124 - GLASS / METALS MANUFACTURER",
    [SubClassCode.DURABLE_GOODS_MANUFACTURER]:
      "96125 - DURABLE GOODS MANUFACTURER",
    [SubClassCode.ALCOHOL_MANUFACTURER]: "96126 - ALCOHOL MANUFACTURER",
    [SubClassCode.AIRLINES]: "96127 - AIRLINES",
    [SubClassCode.AIR_CARGO]: "96128 - AIR CARGO",
    [SubClassCode.PAYMENT_PROCESSOR]: "96129 - PAYMENT PROCESSOR",
    [SubClassCode.UTILITY_CUSTOMER_SIDE]: "96130 - UTILITY - CUSTOMER SIDE",
    [SubClassCode.POWER_UTILITIES]: "97801 - POWER - UTILITIES",
    [SubClassCode.HYDRO]: "97805 - Hydro",
    [SubClassCode.NICHE_LIABILITY]: "98100 - NICHE LIABILITY",
    [SubClassCode.CYBER_UK_CPR_PORTAL]: "98500 - CYBER UK CPR PORTAL",
    [SubClassCode.CYBER_UK_BRIT_TECH_PORTAL]:
      "98501 - CYBER UK BRIT TECH PORTAL",
    [SubClassCode.CYBER_UK_PRIVACY_PROTECT_PORTAL]:
      "98502 - CYBER UK PRIVACY PROTECT PORTAL",
    [SubClassCode.POLITICAL_RISK]: "99000 - POLITICAL RISK",
    [SubClassCode.CONTINGENCY_K_R]: "99001 - CONTINGENCY / K & R",
    [SubClassCode.PERSONAL_ACCIDENT_CAT]: "99002 - PERSONAL ACCIDENT (CAT)",
    [SubClassCode.BOND_CREDIT_SURETY]: "99003 - BOND / CREDIT / SURETY",
    [SubClassCode.MISCELLANEOUS_ACCIDENT]: "99004 - MISCELLANEOUS ACCIDENT",
    [SubClassCode.GL_CLASH]: "99005 - GL (CLASH)",
    [SubClassCode.MOTOR_RISK_WORKING]: "99006 - Motor Risk/Working",
    [SubClassCode.PI_E_O_D_O_RISK]: "99007 - PI / E & O / D & O (RISK)",
    [SubClassCode.WCA_EL_CAT]: "99009 - WCA / EL (CAT)",
    [SubClassCode.WHOLE_A_C_CASUALTY_CLASH]:
      "99010 - WHOLE A/C - CASUALTY (CLASH)",
    [SubClassCode.GL_RISK]: "99011 - GL (RISK)",
    [SubClassCode.PI_E_O_D_O_CLASH]: "99012 - PI / E & O / D & O (CLASH)",
    [SubClassCode.WHOLE_A_C_CASUALTY_RISK]:
      "99013 - WHOLE A/C - CASUALTY (RISK)",
    [SubClassCode.PERSONAL_ACCIDENT_RISK]: "99014 - PERSONAL ACCIDENT (RISK)",
    [SubClassCode.WCA_EL_RISK]: "99015 - WCA / EL (RISK)",
    [SubClassCode.MISC_ACCIDENT_CLASH]: "99016 - Misc Accident Clash",
    [SubClassCode.MOTOR_CLASH_CAT]: "99017 - Motor Clash/CAT",
    [SubClassCode.MEDICAL_MALPRACTICE_RISK]:
      "99018 - MEDICAL MALPRACTICE (RISK)",
    [SubClassCode.MEDICAL_MALPRACTICE_CLASH]:
      "99019 - MEDICAL MALPRACTICE (CLASH)",
    [SubClassCode.REPS_WARRANTIES_RISK]: "99021 - REPS & WARRANTIES (RISK)",
    [SubClassCode.ENERGY]: "99305 - ENERGY",
    [SubClassCode.AGRICULTURE_FARMING]: "99402 - AGRICULTURE/FARMING",
    [SubClassCode.AIRLINE_AIRCRAFT_MANUFACTURER]:
      "99403 - AIRLINE/AIRCRAFT MANUFACTURER",
    [SubClassCode.AIRPORT]: "99404 - AIRPORT",
    [SubClassCode.AMUSEMENT_PARKS]: "99405 - AMUSEMENT PARKS",
    [SubClassCode.ARENA_RACETRACK]: "99406 - ARENA/RACETRACK",
    [SubClassCode.BANK]: "99407 - BANK",
    [SubClassCode.BREWERY_WINERY]: "99408 - BREWERY/WINERY",
    [SubClassCode.CAR_PARK_DEALERSHIP]: "99409 - CAR PARK/DEALERSHIP",
    [SubClassCode.CASINO_NIGHT_CLUB_BAR]: "99410 - CASINO/NIGHT CLUB/BAR",
    [SubClassCode.CINEMA_THEATER]: "99411 - CINEMA/THEATER",
    [SubClassCode.CONFERENCE_EXHIBITION]: "99412 - CONFERENCE/EXHIBITION",
    [SubClassCode.CONSTRUCTION_99413]: "99413 - CONSTRUCTION",
    [SubClassCode.COURIER_99414]: "99414 - COURIER",
    [SubClassCode.ELECTRIC_UTILITY]: "99415 - ELECTRIC UTILITY",
    [SubClassCode.EVENT]: "99416 - EVENT",
    [SubClassCode.GOVERNMENT_BUILDINGS]: "99417 - GOVERNMENT BUILDINGS",
    [SubClassCode.HOSPITALS_99418]: "99418 - HOSPITALS",
    [SubClassCode.HOTEL_RESORT]: "99419 - HOTEL & RESORT",
    [SubClassCode.HYDRO_ELECTRIC_PLANT]: "99420 - HYDRO-ELECTRIC PLANT",
    [SubClassCode.MALL]: "99421 - MALL",
    [SubClassCode.MEDIA_COMPANY_PUBLISHING]: "99422 - MEDIA COMPANY/PUBLISHING",
    [SubClassCode.MILL]: "99423 - MILL",
    [SubClassCode.MINING_99424]: "99424 - MINING",
    [SubClassCode.MISCELLANEOUS_MANUFACTURING]:
      "99425 - MISCELLANEOUS MANUFACTURING",
    [SubClassCode.MIXED_OCCUPANCY_URBAN]: "99426 - MIXED OCCUPANCY - URBAN",
    [SubClassCode.MUNICIPALITIES_99427]: "99427 - MUNICIPALITIES",
    [SubClassCode.MUSEUM_GALLERY]: "99428 - MUSEUM/GALLERY",
    [SubClassCode.OFFICE_HEAD_OFFICE]: "99429 - OFFICE/HEAD OFFICE",
    [SubClassCode.OIL_GAS_OR_CHEMICAL_PLANT]:
      "99430 - OIL, GAS OR CHEMICAL PLANT",
    [SubClassCode.PETROL_STATION]: "99431 - PETROL STATION",
    [SubClassCode.PHARMACEUTICALS]: "99432 - PHARMACEUTICALS",
    [SubClassCode.PIPELINES_99433]: "99433 - PIPELINES",
    [SubClassCode.PORT]: "99434 - PORT",
    [SubClassCode.POWER_PLANT]: "99435 - POWER PLANT",
    [SubClassCode.PRISONS]: "99436 - PRISONS",
    [SubClassCode.PRIVATE_FINE_ART_COLLECTION]:
      "99437 - PRIVATE FINE ART COLLECTION",
    [SubClassCode.RELIGIOUS_PROPERTY]: "99438 - RELIGIOUS PROPERTY",
    [SubClassCode.RESIDENTIAL]: "99439 - RESIDENTIAL",
    [SubClassCode.RETAIL_99440]: "99440 - RETAIL",
    [SubClassCode.ROAD_INFRASTRUCTURE_TOLL_BOOTH]:
      "99441 - ROAD INFRASTRUCTURE & TOLL BOOTH",
    [SubClassCode.SCHOOLS_COLLEGES]: "99442 - SCHOOLS/COLLEGES",
    [SubClassCode.SOLAR_PLANT]: "99443 - SOLAR PLANT",
    [SubClassCode.SUPERMARKET]: "99444 - SUPERMARKET",
    [SubClassCode.TELECOMS]: "99445 - TELECOMS",
    [SubClassCode.TRAINS_RAILROAD_INFRASTRUCTURE]:
      "99446 - TRAINS & RAILROAD INFRASTRUCTURE",
    [SubClassCode.VEHICLES]: "99447 - VEHICLES",
    [SubClassCode.WAREHOUSE]: "99448 - WAREHOUSE",
    [SubClassCode.WASTE_RECYCLING_LANDFILL]: "99449 - WASTE RECYCLING/LANDFILL",
    [SubClassCode.WATER_UTILITY]: "99450 - WATER UTILITY",
    [SubClassCode.WIND_FARM]: "99451 - WIND FARM",
    [SubClassCode.FORBES_BIKES_MEDEX]: "99501 - Forbes Bikes MedEx",
    [SubClassCode.FORBES_BIKES_PA]: "99502 - Forbes Bikes PA",
    [SubClassCode.ATHLETICS]: "99503 - Athletics",
    [SubClassCode.FORBES_CAR_PA]: "99504 - Forbes Car PA",
    [SubClassCode.BASEBALL]: "99505 - Baseball",
    [SubClassCode.BOBSLEIGH]: "99506 - Bobsleigh",
    [SubClassCode.BOWLS]: "99507 - Bowls",
    [SubClassCode.FORBES_CAR_MEDEX]: "99508 - Forbes Car MedEx",
    [SubClassCode.BUNGEE_JUMPING]: "99509 - Bungee Jumping",
    [SubClassCode.FORBES_KART_PA]: "99510 - Forbes Kart PA",
    [SubClassCode.CART]: "99511 - CART",
    [SubClassCode.CRICKET]: "99512 - Cricket",
    [SubClassCode.FORBES_KART_MEDEX]: "99513 - Forbes Kart MedEx",
    [SubClassCode.CYCLING]: "99514 - Cycling",
    [SubClassCode.FORBES_MOTORSPORT_MEDEX]: "99515 - Forbes Motorsport MedEx",
    [SubClassCode.DIVING_SUB_AQUA]: "99516 - Diving - Sub Aqua",
    [SubClassCode.DRAG_RACING]: "99517 - Drag Racing",
    [SubClassCode.FORBES_MOTORSPORT_PA]: "99518 - Forbes Motorsport PA",
    [SubClassCode.FISHING]: "99519 - Fishing",
    [SubClassCode.FOOTBALL_AMERICAN]: "99520 - Football - American",
    [SubClassCode.FOOTBALL_OZZIE_RULES]: "99521 - Football - Ozzie Rules",
    [SubClassCode.FOOTBALL_SOCCER]: "99522 - Football - Soccer",
    [SubClassCode.FORMULA_1]: "99523 - Formula 1",
    [SubClassCode.FORMULA_2]: "99524 - Formula 2",
    [SubClassCode.FORMULA_3]: "99525 - Formula 3",
    [SubClassCode.GO_KARTING]: "99526 - Go Karting",
    [SubClassCode.GOLF]: "99527 - Golf",
    [SubClassCode.GYMNASTICS]: "99528 - Gymnastics",
    [SubClassCode.HOCKEY]: "99529 - Hockey",
    [SubClassCode.BASKETBALL]: "99530 - Basketball",
    [SubClassCode.HUNTING]: "99531 - Hunting",
    [SubClassCode.HURLING]: "99532 - Hurling",
    [SubClassCode.ICE_HOCKEY]: "99533 - Ice Hockey",
    [SubClassCode.ICE_SKATING]: "99534 - Ice Skating",
    [SubClassCode.INDY_CARS]: "99535 - INDY cars",
    [SubClassCode.JOCKEYS]: "99536 - Jockeys",
    [SubClassCode.KARATE]: "99537 - Karate",
    [SubClassCode.LAND_SPEED_RECORD]: "99538 - Land Speed Record",
    [SubClassCode.LAND_YACHTS]: "99539 - Land Yachts",
    [SubClassCode.MARTIAL_ARTS]: "99540 - Martial Arts",
    [SubClassCode.MOTORCYCLING]: "99541 - Motorcycling",
    [SubClassCode.MOUNTAINEERING]: "99542 - Mountaineering",
    [SubClassCode.POLO]: "99543 - Polo",
    [SubClassCode.POTHOLING]: "99544 - Potholing",
    [SubClassCode.POWERBOAT_RACING]: "99545 - Powerboat Racing",
    [SubClassCode.RALLYING]: "99546 - Rallying",
    [SubClassCode.ROCK_CLIMBING]: "99547 - Rock Climbing",
    [SubClassCode.RUGBY]: "99548 - Rugby",
    [SubClassCode.SKIING]: "99549 - Skiing",
    [SubClassCode.SNOOKER]: "99550 - Snooker",
    [SubClassCode.SPEEDWAY]: "99551 - Speedway",
    [SubClassCode.SQUASH]: "99552 - Squash",
    [SubClassCode.SPORTS_CONSORTIUM_BEAZLEY]:
      "99553 - Sports Consortium Beazley",
    [SubClassCode.SWIMMING]: "99554 - Swimming",
    [SubClassCode.TENNIS]: "99555 - Tennis",
    [SubClassCode.WATER_SKIING]: "99556 - Water Skiing",
    [SubClassCode.WIND_SURFING]: "99557 - Wind Surfing",
    [SubClassCode.YACHTING]: "99558 - Yaching",
    [SubClassCode.AERIAL_PHOTOGRAPHY]: "99559 - Aerial Photography",
    [SubClassCode.AEROBATIC_FLYING]: "99560 - Aerobatic Flying",
    [SubClassCode.AGRICULTURAL_AIRCRAFT]: "99561 - Agricultural Aircraft",
    [SubClassCode.AIR_RESCUE]: "99562 - Air Rescue",
    [SubClassCode.AIRSHIP]: "99563 - Airship",
    [SubClassCode.ASTRONAUT_EXCL_EVA]: "99564 - Astronaut excl. EVA",
    [SubClassCode.ASTRONAUT_INCL_EVA]: "99565 - Astronaut incl. EVA",
    [SubClassCode.BALLOONING]: "99566 - Ballooning",
    [SubClassCode.BANNER_TOWING]: "99567 - Banner Towing",
    [SubClassCode.COMMERCIAL_AIRCRAFT]: "99568 - Commercial Aircraft",
    [SubClassCode.CORPORATE_AIRCRAFT]: "99569 - Corporate Aircraft",
    [SubClassCode.FISH_SPOTTERS]: "99570 - Fish Spotters",
    [SubClassCode.GLIDING]: "99571 - Gliding",
    [SubClassCode.HANG_GLIDING]: "99572 - Hang Gliding",
    [SubClassCode.HELICOPTERS]: "99573 - Helicopters",
    [SubClassCode.LOSS_OF_LICENCE]: "99574 - Loss of Licence",
    [SubClassCode.PARACHUTING]: "99575 - Parachuting",
    [SubClassCode.PARAPENDIO]: "99576 - Parapendio",
    [SubClassCode.PARASCENDING]: "99577 - Parascending",
    [SubClassCode.PIPELINE_INSPECTION]: "99578 - Pipeline Inspection",
    [SubClassCode.PRIVATE_CLUB_FLYING]: "99579 - Private Club Flying",
    [SubClassCode.STUNT_FLYING]: "99580 - Stunt Flying",
    [SubClassCode.ULTRALIGHT]: "99581 - Ultralight",
    [SubClassCode.MANUAL]: "99582 - Manual",
    [SubClassCode.NON_MANUAL]: "99583 - Non-manual",
    [SubClassCode.TWENTY_FOUR_HOUR_99584]: "99584 - 24 Hour",
    [SubClassCode.ON_BOARD]: "99585 - On board",
    [SubClassCode.OFF_SHIP]: "99586 - Off ship",
    [SubClassCode.VACATION]: "99587 - Vacation",
    [SubClassCode.BUSINESS]: "99588 - Business",
    [SubClassCode.OTHER_99589]: "99589 - Other",
    [SubClassCode.MANAGED_CARE]: "99590 - Managed Care",
    [SubClassCode.EXPATRIATE]: "99591 - Expatriate",
    [SubClassCode.SELF_FUNDED]: "99592 - Self Funded",
    [SubClassCode.SCHOOLS_ABSENCE]: "99593 - Schools Absence",
    [SubClassCode.ARMED_FORCES]: "99594 - Armed Forces",
    [SubClassCode.CONSTRUCTION_99595]: "99595 - Construction",
    [SubClassCode.JOURNALISTS]: "99596 - Journalists",
    [SubClassCode.DBA_FVWC]: "99597 - DBA / FVWC",
    [SubClassCode.MINE_CLEARERS]: "99598 - Mine Clearers",
    [SubClassCode.PEACE_KEEPERS]: "99599 - Peace Keepers",
    [SubClassCode.DEATH]: "99600 - Death",
    [SubClassCode.AD_CAPS]: "99601 - AD/Caps",
    [SubClassCode.FULL_BENEFITS]: "99602 - Full Benefits",
    [SubClassCode.TWENTY_FOUR_HOUR_99603]: "99603 - 24 Hour",
    [SubClassCode.OCCUPATIONAL_ACCIDENTS]: "99604 - Occupational Accidents",
    [SubClassCode.FLIGHT_RISKS_ONLY]: "99605 - Flight Risks Only",
    [SubClassCode.NASCAR]: "99606 - NASCAR",
    [SubClassCode.MOTORSPORTS]: "99607 - MOTORSPORTS",
    [SubClassCode.STAFF_SCHEME]: "99608 - Staff Scheme",
    [SubClassCode.INCOME_PROTECTION]: "99609 - Income Protection",
    [SubClassCode.OUT_OF_DOMICLE]: "99610 - Out of Domicle",
    [SubClassCode.KIDNAP_RANSOM]: "99611 - Kidnap & Ransom",
    [SubClassCode.PENSION_PROTECTION]: "99612 - Pension Protection",
    [SubClassCode.PRODUCT_RECALL]: "99613 - Product Recall",
    [SubClassCode.FINANCIAL_SERVICES]: "99711 - FINANCIAL SERVICES",
    [SubClassCode.GOVERNMENT_AND_PUBLIC_SERVICES]:
      "99713 - GOVERNMENT AND PUBLIC SERVICES",
    [SubClassCode.GENERAL_HOUSEHOLD_HSB_HOMEOWNER_PRODUCTS]:
      "99991 - GENERAL HOUSEHOLD HSB HOMEOWNER PRODUCTS",
    [SubClassCode.DUMMY_CODE]: "99999 - DUMMY CODE",
  }
