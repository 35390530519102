export interface ChecklistItem {
  endorsementType: string
  toPU: boolean
  toTPU: boolean
}

export const checklist: ChecklistItem[] = [
  { endorsementType: "Loss payee (addition)", toPU: true, toTPU: false },
  { endorsementType: "Name or address change", toPU: true, toTPU: false },
  { endorsementType: "Syndicate change", toPU: true, toTPU: false },
  {
    endorsementType: "Premium payment terms or conditions change",
    toPU: true,
    toTPU: false,
  },
  { endorsementType: "Claims agreement parties", toPU: true, toTPU: false },
  { endorsementType: "Subjectivities satisfied", toPU: false, toTPU: false },
  { endorsementType: "Reducing Ki line", toPU: true, toTPU: true },
  { endorsementType: "Increasing Ki line", toPU: true, toTPU: true },
  { endorsementType: "Contract clause change", toPU: false, toTPU: false },
  { endorsementType: "Tax change", toPU: true, toTPU: false },
  { endorsementType: "Tax split change", toPU: true, toTPU: false },
  {
    endorsementType: "Value change (no limit change)",
    toPU: true,
    toTPU: false,
  },
  { endorsementType: "Value change (limit change)", toPU: true, toTPU: true },
  {
    endorsementType: "Extra shows (no premium change)",
    toPU: false,
    toTPU: false,
  },
  {
    endorsementType: "Extra shows (premium change)",
    toPU: true,
    toTPU: true,
  },
  {
    endorsementType: "Period change (extending total policy beyond 18 months)",
    toPU: true,
    toTPU: false,
  },
  { endorsementType: "Retention change", toPU: true, toTPU: false },
  { endorsementType: "BoR change", toPU: true, toTPU: false },
  {
    endorsementType: "Order change (not Ki line size change)",
    toPU: true,
    toTPU: false,
  },
  { endorsementType: "Cyber endorsement change", toPU: true, toTPU: false },
  { endorsementType: "Limit change", toPU: true, toTPU: true },
  { endorsementType: "Sublimit change", toPU: true, toTPU: false },
  { endorsementType: "Surplus lines broker change", toPU: false, toTPU: false },
  { endorsementType: "Deductible change", toPU: true, toTPU: false },
  { endorsementType: "Excess change", toPU: true, toTPU: true },
  { endorsementType: "Adding retrocedant", toPU: true, toTPU: false },
  { endorsementType: "Minimum premium change", toPU: false, toTPU: false },
  { endorsementType: "Vendors change", toPU: false, toTPU: false },
  { endorsementType: "Additional named insured", toPU: false, toTPU: false },
  { endorsementType: "Additional loss payee", toPU: false, toTPU: false },
  { endorsementType: "Additional mortgagees", toPU: false, toTPU: false },
  { endorsementType: "UMR change", toPU: true, toTPU: false },
  { endorsementType: "Brokerage", toPU: true, toTPU: false },
  { endorsementType: "NAICs code change", toPU: false, toTPU: false },
  { endorsementType: "War and Terrorism exclusion", toPU: false, toTPU: false },
  { endorsementType: "Cancellation", toPU: true, toTPU: false },
  { endorsementType: "SDD change", toPU: true, toTPU: false },
  { endorsementType: "Risk code change", toPU: true, toTPU: false },
  { endorsementType: "RI certification", toPU: false, toTPU: false },
  { endorsementType: "Removing LMA or NMA Clause", toPU: false, toTPU: false },
  { endorsementType: "Automatic period extension", toPU: false, toTPU: true },
  {
    endorsementType: "Adding wells or energy related values?",
    toPU: false,
    toTPU: false,
  },
  { endorsementType: "Adding vessels", toPU: true, toTPU: false },
]
