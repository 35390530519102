import { FC } from "react"

import { SuccessResponse } from "@uppy/core"
import { DragDrop } from "@uppy/react"

import { generatePBQAUploadUrl } from "@appia/api"
import useApiClient from "src/contexts/ApiClientContext"

import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import { logPBQAUploadComplete, logPBQAUploadStart } from "src/amplitude"

import { FileUploadArea } from "@appia/ui-components"
import "./UploadArea.css"

import useUppy from "@appia/file-upload"

const FILETYPE_MSG = "Otto only accepts .pdf, .doc and .docx files."

const UploadArea: FC<{
  acceptedDocumentTypes: string[]
  maxMegabytes: number
  progressId: string
  setUploadProgress: (n: number) => void
  setUploadRequest: (r: RD.RemoteData<Error, SuccessResponse>) => void
  uploadRequest: RD.RemoteData<Error, SuccessResponse>
}> = ({
  acceptedDocumentTypes,
  maxMegabytes,
  progressId,
  setUploadProgress,
  setUploadRequest,
  uploadRequest,
}) => {
  const apiClient = useApiClient()

  const uppy = useUppy({
    maxMegabytes,
    acceptedDocumentTypes,
    getUploadParameters: async file => {
      if (typeof file.type === "undefined") {
        throw new Error(`Unrecognised document type. ${FILETYPE_MSG}`)
      }

      const urlRes = await generatePBQAUploadUrl(apiClient, {
        name: file.name,
        mimetype: file.type,
        extension: file.extension.toLowerCase(),
      })

      if (urlRes.status !== 201) {
        throw new Error(`We were unable to accept your upload. ${FILETYPE_MSG}`)
      }

      const { id: documentId, pbqa_id, url, fields } = urlRes.data

      logPBQAUploadStart({ pbqaId: pbqa_id, documentId, mimetype: file.type })

      return { url, fields }
    },
    onStartLoad: () => setUploadRequest(RD.Loading),
    onProgress: setUploadProgress,
    onError: async ({ error }) => {
      Sentry.captureException(error)
      setUploadRequest(RD.Failure(error))
    },
    onSuccess: async (res, file) => {
      if (file) {
        logPBQAUploadComplete({ documentId: file.id })
        setUploadRequest(RD.Success(res))
      }
    },
  })

  return (
    <FileUploadArea
      label={
        <div className="grid gap-2">
          <p className="text-xl font-bold">Drop your file here</p>
          <p className="text-base">Or browse files from your computer</p>
          <p className="text-base">{FILETYPE_MSG}</p>
        </div>
      }
      input={
        <DragDrop
          uppy={uppy}
          className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-0"
          aria-describedby={
            RD.isLoading(uploadRequest) ? progressId : undefined
          }
        />
      }
    />
  )
}

export default UploadArea
