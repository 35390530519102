import { SectionFormField } from "./utils/types"

export const calculateTotalAllocatedPremium = (
  sections: Record<string, SectionFormField>,
): { total: number; totalArray: number[] } => {
  let totalAllocatedPremium = 0
  const totalAllocatedPremiumArray: number[] = []

  for (const section of Object.values(sections)) {
    const premiumValue = section.premiumAllocationValueAndCurrency.raw.amount
    if (premiumValue) {
      totalAllocatedPremiumArray.push(premiumValue)
    }
    totalAllocatedPremium += premiumValue || 0
  }

  return {
    total: totalAllocatedPremium,
    totalArray: totalAllocatedPremiumArray,
  }
}
