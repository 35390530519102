import { FC, useState } from "react"

import { getKiHeadersheetData } from "@appia/api"
import * as Sentry from "@sentry/react"
import * as RD from "@appia/remote-data"

import { useGetPBQAOverview } from "src/swr"
import useGetPBQASyndicates from "ReviewPBQA/useGetPBQASyndicates"
import useApiClient from "src/contexts/ApiClientContext"
import usePBQASurvey from "../../PBQASurveyContext"

import {
  Button,
  DownloadIcon,
  DuplicateIcon,
  Link,
  ModalDescription,
  Toast,
} from "@appia/ui-components"

import ErrorMessage from "src/components/ErrorMessage"
import PolicyReferencesTable from "src/components/PolicyReferencesTable"
import ModalTemplate from "src/components/ModalTemplate"
import ToastViewport from "src/components/ToastViewport"

import { logButtonClick, logPBQAExport } from "src/amplitude"

const KI_SYNDICATE_CODE = 1618

const SuccessModal: FC<{
  isOpen: boolean
  onClose: () => void
  showCopyHeadersheetButton: boolean
  pageName: string
}> = ({ isOpen, onClose, showCopyHeadersheetButton, pageName }) => {
  const apiClient = useApiClient()

  const {
    pbqaId,
    activeSurvey: { id: surveyId },
  } = usePBQASurvey()

  const { request: overviewRequest } = useGetPBQAOverview(pbqaId)

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<string>("")
  const toastState = Toast.useToastState()

  const selectedSyndicatesReq = useGetPBQASyndicates(pbqaId)
  const selectedSyndicates = RD.isSuccess(selectedSyndicatesReq)
    ? selectedSyndicatesReq.data
    : []
  const hasKiOnSlip = selectedSyndicates.some(
    syndicate => syndicate.code === KI_SYNDICATE_CODE,
  )

  return RD.match(
    overviewRequest,

    null,
    null,

    overviewRequestData => (
      <>
        <ModalTemplate
          className="lg:w-full lg:max-w-[min(50rem,calc(100%-4rem))]"
          isOpen={isOpen}
          onClose={onClose}
          title="Confirmed"
          content={
            <ModalDescription as="div">
              <p>
                All questions have been saved and are ready to be exported or
                copied as headersheet data.
              </p>

              <div className="mt-4 overflow-auto">
                <PolicyReferencesTable
                  policyReferences={overviewRequestData.policyReferences}
                />
              </div>
            </ModalDescription>
          }
          actions={[
            <Link
              data-cy="export-pbqa"
              key="export"
              download
              href={`${window.HOST}/api/pbqa/${pbqaId}/surveys/${surveyId}/export`}
              icon={{ position: "right", icon: <DownloadIcon /> }}
              label="Export"
              style="outlined"
              target="_blank"
              theme="night"
              onClick={() => {
                logPBQAExport({ pbqaId, surveyId })

                logButtonClick({
                  buttonName: "Export PBQA",
                  containerName: "Success modal",
                  pageName,
                  linkHref: `${window.HOST}/api/pbqa/${pbqaId}/surveys/${surveyId}/export`,
                })
              }}
            />,

            showCopyHeadersheetButton ? (
              <Button
                disabled={hasKiOnSlip ? false : true}
                key="copy"
                label="Copy headersheet data"
                icon={{ position: "right", icon: <DuplicateIcon /> }}
                style="outlined"
                theme="night"
                onClick={async () => {
                  logButtonClick({
                    buttonName: "Copy headersheet to clipboard",
                    containerName: "Success modal",
                    pageName,
                  })

                  try {
                    const { data: text } = await getKiHeadersheetData(
                      apiClient,
                      pbqaId,
                      surveyId,
                    )

                    navigator.clipboard.writeText(text)

                    setToastType("success")
                    setToastMessage("Successfully copied")
                    toastState.triggerToast()
                  } catch (e) {
                    if (e instanceof Error) {
                      Sentry.captureException(e)

                      setToastType("error")
                      setToastMessage("Failed to copy headersheet data")
                      toastState.triggerToast()
                    }
                  }
                }}
              />
            ) : null,

            <Link
              key="summary"
              href={`/pbqa/view/${pbqaId}`}
              label="Go to summary"
              style="outlined"
              theme="night"
              onClick={() => {
                logButtonClick({
                  buttonName: "Go to summary",
                  linkHref: `/pbqa/view/${pbqaId}`,
                  containerName: "Success modal",
                  pageName,
                })
              }}
            />,

            <Link
              key="dashboard"
              href="/pbqa"
              label="Go to dashboard"
              style="filled"
              theme="pop"
              onClick={() => {
                logButtonClick({
                  buttonName: "Go to dashboard",
                  linkHref: "/pbqa",
                  containerName: "Success modal",
                  pageName,
                })
              }}
            />,
          ]}
        />

        <Toast.Toast
          type={toastType}
          message={toastMessage}
          open={toastState.open}
          onOpenChange={toastState.onOpenChange}
        />

        <ToastViewport />
      </>
    ),

    error => (
      <ErrorMessage
        message="Sorry, there was an error retrieving the policy references"
        error={error}
      />
    ),
  )
}

export default SuccessModal
