import { NavLink } from "react-router-dom"

import { ArrowLeftIcon, Link } from "@appia/ui-components"
import { ReactElement } from "react"

interface SectionNavProps<T extends string> {
  sections: { path: T; title: string }[]
}

const ContractSectionNav = <T extends string>({
  sections,
}: SectionNavProps<T>): ReactElement => {
  return (
    <div className="w-full border-b border-otto-grey-300 bg-white shadow">
      <nav
        className="mx-auto grid w-full max-w-8xl grid-cols-[auto,auto] items-center justify-between gap-4 pr-4 sm:pr-6 sm:pl-2 md:grid-cols-[1fr,auto,1fr] lg:pr-8 lg:pl-4"
        aria-label="Contract sections"
      >
        <Link
          href="/contract"
          icon={{ position: "left", icon: <ArrowLeftIcon /> }}
          label="Contract processing pipeline"
          style="text"
          theme="night"
          size="small"
          className="my-1"
          aria-label="go back to contract processing pipeline screen"
        />

        <div className="mx-auto flex max-w-full items-stretch gap-4 self-stretch overflow-x-auto py-1">
          {sections.map(section => (
            <NavLink
              key={section.path}
              relative="path"
              to={`../${section.path}`}
              className={({ isActive }) =>
                `otto-focus-inset relative flex grow items-center gap-2 whitespace-nowrap rounded-md px-2 text-base font-bold hover:bg-otto-grey-900 hover:bg-opacity-10 ${
                  isActive
                    ? "after:absolute after:bottom-[-4px] after:left-0 after:w-full after:border-b-2 after:border-otto-pop-700"
                    : "after:content-none"
                }`
              }
            >
              {section.title}
            </NavLink>
          ))}
        </div>
      </nav>
    </div>
  )
}

export default ContractSectionNav
