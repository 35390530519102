export const MajorClassCode = {
  CARGO_PERSONAL_ACCIDENT: "G",
  ARUS_LIABILITY: "B",
  CASUALTY: "C",
  PERSONAL_ACCIDENT_NT_PROPERTY_INTL_P: "P",
  CONTINGENCY_NORTON_RE: "N",
  UK: "U",
  DO_NON_US: "D",
  FI: "F",
  FI_US: "K",
  DO_MARINE_OMX: "O",
  ENEGERY_PD_EUR: "E",
  MARINE_MEDICAL_EXPENSES: "M",
  MARINE_HULL: "H",
  WAR: "W",
  PERSONAL_LINES_AUSTRALIAN_RI: "J",
  MISC_NETT_ACCOUNT: "X",
  PI_US: "Z",
  EL_PL_NON_MARINE_RETRO: "R",
  SPACE_SMALL_NA_LIABILITY: "S",
  PERSONAL_ACCIDENT_NT_PROPERTY_INTL_I: "I",
} as const

// Local and CI formatters are at odds with each other, hence disabling for next line.
// prettier-ignore
export type MajorClassCode = typeof MajorClassCode[keyof typeof MajorClassCode]

export const MAJOR_CLASS_CODE_DESCRIPTION_MAPPING: Record<
  MajorClassCode,
  string
> = {
  [MajorClassCode.CARGO_PERSONAL_ACCIDENT]: "G - Cargo/Personal Accident",
  [MajorClassCode.ARUS_LIABILITY]: "B - ARUS/Liability",
  [MajorClassCode.CASUALTY]: "C - CASUALTY",
  [MajorClassCode.PERSONAL_ACCIDENT_NT_PROPERTY_INTL_P]:
    "P - PERSONAL ACCIDENT NT/PROPERTY INTERNATIONAL",
  [MajorClassCode.CONTINGENCY_NORTON_RE]: "N - CONTINGENCY - NORTON RE",
  [MajorClassCode.UK]: "U - UK",
  [MajorClassCode.DO_NON_US]: "D - D&O NON US",
  [MajorClassCode.FI]: "F - F.I.",
  [MajorClassCode.FI_US]: "K - F.I. US",
  [MajorClassCode.DO_MARINE_OMX]: "O - D&O/Marine OMX",
  [MajorClassCode.ENEGERY_PD_EUR]: "E - Energy P.D./Europe",
  [MajorClassCode.MARINE_MEDICAL_EXPENSES]: "M - Marine/Medical Expenses",
  [MajorClassCode.MARINE_HULL]: "H - MARINE HULL",
  [MajorClassCode.WAR]: "W - WAR",
  [MajorClassCode.PERSONAL_LINES_AUSTRALIAN_RI]:
    "J - Personal Lines/Australian RI",
  [MajorClassCode.MISC_NETT_ACCOUNT]: "X - Misc/Nett Account",
  [MajorClassCode.PI_US]: "Z - P.I. US",
  [MajorClassCode.EL_PL_NON_MARINE_RETRO]: "R - EL/PL / Non-Marine Retro",
  [MajorClassCode.SPACE_SMALL_NA_LIABILITY]:
    "S - Space/Small North American Liability",
  [MajorClassCode.PERSONAL_ACCIDENT_NT_PROPERTY_INTL_I]:
    "I - PERSONAL ACCIDENT NT/PROPERTY INTERNATIONAL",
}
