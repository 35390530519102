import { ApiClient, KiQuote, PBQASurvey, searchKiQuotes } from "@appia/api"
import {
  PBQAReviewState,
  countMatchingAnswersAmongComparisonQuestions,
} from "ReviewPBQA/state"

export const orderQuotes = (
  state: PBQAReviewState,
  activeSurvey: PBQASurvey,
  quotes: KiQuote[],
): KiQuote[] =>
  [...quotes].sort((a, b) => {
    const [aX, aY] = countMatchingAnswersAmongComparisonQuestions(
      state,
      a,
      activeSurvey,
    )
    const aPercentage = aX / aY

    const [bX, bY] = countMatchingAnswersAmongComparisonQuestions(
      state,
      b,
      activeSurvey,
    )
    const bPercentage = bX / bY

    if (aPercentage > bPercentage) return -1
    else if (aPercentage < bPercentage) return 1
    else if (a.quoteLineId > b.quoteLineId) return -1
    else if (a.quoteLineId < b.quoteLineId) return 1
    else return 0
  })

export const getQuoteForAssuredName = async (
  apiClient: ApiClient,
  pbqaId: string,
  state: PBQAReviewState,
  activeSurvey: PBQASurvey | null,
): Promise<KiQuote | null> => {
  if (!activeSurvey) {
    return null
  }

  const assuredNameQuestion = activeSurvey.questions.find(
    q => q.type === "insured",
  )
  if (!assuredNameQuestion) {
    return null
  }

  const assuredName = activeSurvey.answers.find(
    a => a.questionId === assuredNameQuestion.id,
  )?.answer
  if (typeof assuredName !== "string") {
    return null
  }

  const { data: quotes } = await searchKiQuotes(apiClient, pbqaId, assuredName)

  if (quotes.length > 0) {
    return orderQuotes(state, activeSurvey, quotes)[0]
  } else {
    return null
  }
}
